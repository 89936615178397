import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import UserIcon from "@mui/icons-material/Person";
import CopyContentIcon from "@mui/icons-material/ContentCopy";
import CustomFieldsView from "../custom-fields/custom-fields-view";
import UserGroups from "./user-groups";
import { useAuth } from "../../../services/auth";
import { useAlert } from "../../../services/alert";
import { copy } from "../../../helpers/copy";
import { colors } from "../../../helpers/colors";
export default function UserDetails({ type, user }) {
    const { t } = useTranslation();
    const auth = useAuth();
    const alert = useAlert();
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    marginBottom: 2,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(UserIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("users.user") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("users.user-details") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                        padding: 4,
                                    } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                        display: "flex",
                                                        alignItems: "center",
                                                    } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                                paddingRight: 2,
                                                            } }, { children: user.avatar_url ? (_jsx(Avatar, { style: {
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    color: "#fff",
                                                                    backgroundColor: "#fff",
                                                                }, src: user.avatar_url, imgProps: {
                                                                    style: {
                                                                        objectFit: "contain",
                                                                    },
                                                                } })) : (_jsx(Avatar, Object.assign({ style: {
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    color: "#fff",
                                                                    backgroundColor: colors.secondary.dark,
                                                                } }, { children: user.first_name ? ((user.first_name[0].toUpperCase() +
                                                                    (user.last_name
                                                                        ? user.last_name[0].toUpperCase()
                                                                        : "")).replace(/\s/g, "")) : (_jsx(UserIcon, {})) }))) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h6" }, { children: user.first_name +
                                                                        (user.last_name
                                                                            ? " " + user.last_name
                                                                            : "") })), user.roles && (_jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: user.roles[0].name === "admin"
                                                                        ? t("users.roles.admin")
                                                                        : user.roles[0].name === "manager"
                                                                            ? t("users.roles.manager")
                                                                            : user.roles[0].name === "super_admin"
                                                                                ? t("users.roles.super-admin")
                                                                                : user.roles[0].name === "user"
                                                                                    ? t("users.roles.user")
                                                                                    : t("users.roles.agent") })))] })] })) })), user.email && (_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsx(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: t("users.user-attributes.email") })) })), _jsx(Box, Object.assign({ sx: {
                                                            paddingY: 0.5,
                                                        } }, { children: _jsx(Chip, { variant: "outlined", label: user.email, onDelete: () => {
                                                                copy(user.email);
                                                                alert.show(t("email-copied"), "info");
                                                            }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                                                // @ts-ignore
                                                                title: t("copy-email") }, { children: _jsx(CopyContentIcon, {}) })) }) }))] }))), user.username && (_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsx(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: t("users.user-attributes.username") })) })), _jsx(Box, Object.assign({ sx: {
                                                            paddingY: 0.5,
                                                        } }, { children: _jsx(Chip, { variant: "outlined", label: user.username, onDelete: () => {
                                                                copy(user.username);
                                                                alert.show(t("username-copied"), "info");
                                                            }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                                                // @ts-ignore
                                                                title: t("copy-username") }, { children: _jsx(CopyContentIcon, {}) })) }) }))] }))), type === "central" && (_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsx(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: t("users.user-attributes.active") })) })), _jsx(Chip, { label: user.is_enabled ? t("yes") : t("no"), size: "small", variant: "filled", color: user.is_enabled ? "success" : "error" })] }))), type === "central" && (_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsx(Box, Object.assign({ sx: {
                                                            paddingBottom: 1,
                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: "SSO" })) })), _jsx(Chip, { label: user.is_sso ? t("yes") : t("no"), size: "small", variant: "outlined", color: user.is_sso ? "success" : "warning" })] }))), (type === "account" || type === "central") && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Box, Object.assign({ sx: {
                                                        paddingBottom: 1,
                                                    } }, { children: (type === "account" && auth.user
                                                        ? auth.user["2fa"]
                                                        : user["2fa"]) ? (_jsx(Alert, Object.assign({ severity: "success" }, { children: t("2fa.enabled") }))) : (_jsx(Alert, Object.assign({ severity: "warning" }, { children: t("2fa.disabled") }))) })) })))] })) })) }) }))] })) })), user.custom_fields && user.custom_fields.length > 0 && (_jsx(CustomFieldsView, { status: "view", type: "tenant_users", customFields: user.custom_fields })), user.groups && user.groups.length > 0 && _jsx(UserGroups, { groups: user.groups })] }));
}
