import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment, memo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Controls from "../blocks/controls";
import ContactForm from "../contacts/contact-form";
import TicketInformationForm from "./ticket-information-form";
import TicketDetailsForm from "./ticket-details-form";
import TicketActivityForm from "./ticket-activity-form";
import TicketActivityHistory from "./ticket-activity-history";
import Nope from "../blocks/nope";
import { useApiTenant } from "../../../api/api-tenant";
import { useAuth } from "../../../services/auth";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
import analytics from "../../../helpers/analytics";
function TicketEditModal({ showEditTicketModal, setShowEditTicketModal, editTicketModalId, setEditTicketModalId, updatedTicket, setUpdatedTicket, }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const api = useApiTenant();
    const auth = useAuth();
    const tenancy = useTenancy();
    const roles = useRoles();
    const [ticket, setTicket] = useState(null);
    const [tab, setTab] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const getTicket = () => {
        if (!editTicketModalId)
            return;
        api.GET_ticket(editTicketModalId)
            .then(response => {
            const ticket = response.data.ticket;
            setTicket(ticket);
            if (!updatedTicket) {
                if (process.env.APP_ID === "mcd" && !roles.isAdmin) {
                    if (ticket.campaign.has_steps) {
                        setTab("information");
                    }
                    else {
                        setTab("detail");
                    }
                }
                else if (ticket.campaign.has_contact_fields) {
                    setTab("contact");
                }
                else if (ticket.campaign.has_steps) {
                    setTab("information");
                }
                else {
                    setTab("detail");
                }
            }
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
    };
    const onEditTicketModalClose = () => {
        setShowEditTicketModal(false);
        setEditTicketModalId(null);
    };
    useEffect(() => {
        if (!editTicketModalId)
            return;
        setIsLoading(true);
        getTicket();
        return () => api.cancel();
    }, [editTicketModalId]);
    useEffect(() => {
        if (!editTicketModalId)
            return;
        analytics.sendModalView("/ticket/" + editTicketModalId + "/" + tab + "/edit", auth.user);
    }, [tab]);
    useEffect(() => {
        if (!updatedTicket)
            return;
        getTicket();
    }, [updatedTicket]);
    return (_jsx(Dialog, Object.assign({ disableEnforceFocus: true, fullWidth: true, maxWidth: "md", open: showEditTicketModal, onClose: onEditTicketModalClose, PaperProps: {
            sx: {
                height: "100%",
                bgcolor: "background.default",
            },
        } }, { children: isLoading ? (_jsx(Box, Object.assign({ sx: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
            } }, { children: _jsx(CircularProgress, { color: "inherit" }) }))) : ticket ? (_jsxs(Fragment, { children: [_jsx(DialogTitle, Object.assign({ sx: {
                        bgcolor: "background.paper",
                    } }, { children: _jsxs(Box, Object.assign({ sx: {
                            display: "flex",
                            flexDirection: "column",
                        } }, { children: [_jsx(Box, Object.assign({ sx: {
                                    display: "flex",
                                } }, { children: (process.env.APP_ID !== "mcd" && roles.isAgent) ||
                                    (process.env.APP_ID === "mcd" && !roles.isAdmin) ? (_jsx(Typography, Object.assign({ variant: "h6" }, { children: t("tickets.ticket") + " #" + editTicketModalId }))) : (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/tickets/" + editTicketModalId + "/contact"), onClick: event => {
                                        event.preventDefault();
                                        navigate(tenancy.link("/tickets/" + editTicketModalId + "/contact"));
                                    }, underline: "hover" }, { children: _jsx(Typography, Object.assign({ variant: "h6" }, { children: t("tickets.ticket") + " #" + editTicketModalId })) }))) })), _jsxs(Typography, Object.assign({ variant: "caption" }, { children: [`${t("updated-at")} ${new Date(Date.parse(ticket.updated_at)).toLocaleDateString("en-GB") +
                                        " " +
                                        new Date(Date.parse(ticket.updated_at)).toLocaleTimeString("hr-HR", {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        })}`, ticket.updated_by && (_jsx("span", { children: ` ${t("by")} ${ticket.updated_by.first_name +
                                            (ticket.updated_by.last_name
                                                ? " " + ticket.updated_by.last_name
                                                : "")}` })), ` / ${t("created-at")} ${new Date(Date.parse(ticket.created_at)).toLocaleDateString("en-GB") +
                                        " " +
                                        new Date(Date.parse(ticket.created_at)).toLocaleTimeString("hr-HR", {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        })}`, ticket.created_by && (_jsx("span", { children: ` ${t("by")} ${ticket.created_by.first_name +
                                            (ticket.created_by.last_name
                                                ? " " + ticket.created_by.last_name
                                                : "")}` }))] }))] })) })), _jsxs(Box, Object.assign({ sx: {
                        paddingX: 3,
                        paddingY: 2,
                    } }, { children: [_jsx(Controls, { tabs: process.env.APP_ID === "mcd" && !roles.isAdmin
                                ? [
                                    ...(ticket.campaign.has_steps
                                        ? [
                                            {
                                                label: t("tickets.ticket-steps.information"),
                                                value: "information",
                                            },
                                        ]
                                        : []),
                                    {
                                        label: t("tickets.ticket-steps.details"),
                                        value: "detail",
                                    },
                                ]
                                : [
                                    ...(ticket.campaign.has_contact_fields
                                        ? [
                                            {
                                                label: t("tickets.ticket-steps.contact"),
                                                value: "contact",
                                            },
                                        ]
                                        : []),
                                    ...(ticket.campaign.has_steps
                                        ? [
                                            {
                                                label: t("tickets.ticket-steps.information"),
                                                value: "information",
                                            },
                                        ]
                                        : []),
                                    {
                                        label: t("tickets.ticket-steps.details"),
                                        value: "detail",
                                    },
                                    ...(ticket.campaign.has_activity_fields
                                        ? [
                                            {
                                                label: t("tickets.ticket-steps.activity"),
                                                value: "activity",
                                            },
                                        ]
                                        : []),
                                ], tab: tab, setTab: setTab }), tab === "contact" && (_jsx(ContactForm, { kind: "ticket", status: "edit", contact: ticket.contact, modal: true, campaign: ticket.campaign, setUpdatedContact: setUpdatedTicket, ticketDraft: ticket.is_draft, newContact: ticket.contact_is_new })), tab === "information" && (_jsx(TicketInformationForm, { ticket: ticket, modal: true, setUpdatedInformation: setUpdatedTicket })), tab === "detail" && (_jsx(TicketDetailsForm, { ticket: ticket, modal: true, setUpdatedDetails: setUpdatedTicket })), tab === "activity" && (_jsxs(Fragment, { children: [_jsx(TicketActivityForm, { status: "new", ticket: ticket, modal: true, setUpdatedActivity: setUpdatedTicket }), ticket.ticket_activities && ticket.ticket_activities.length > 0 && (_jsx(Box, Object.assign({ sx: {
                                        marginTop: 4,
                                    } }, { children: _jsx(TicketActivityHistory, { ticket: ticket, modal: true }) })))] }))] }))] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("tickets.not-found") })) })));
}
export default memo(TicketEditModal);
