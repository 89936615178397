import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAuth } from "../../../services/auth";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
import { useAlert } from "../../../services/alert";
import { prettifyText } from "../../../helpers/clean";
import analytics from "../../../helpers/analytics";
import { useApiTenant } from "../../../api/api-tenant";
import { exportTicketTabs, ticketDetailAttributes } from "../../../enums/ticket";
import { ContactDetailAttributes } from "../../../enums/contact";
export default function TicketExport({ openExportOptions, setOpenExportOptions, campaign, filterCreatedStartDate, filterCreatedEndDate, filterCreatedStartDateCustom, filterCreatedEndDateCustom, filterCreatedStartTimeCustom, filterCreatedEndTimeCustom, isExporting, setIsExporting, getFieldAndChoiceFilterValues, search, searchBy, filterCampaigns, filterContacts, filterStatuses, filterPriorities, filterAssignees, filterGroups, filterCreatedBy, filterUpdatedBy, filterDrafts, filterUnassigned, filterUngrouped, timeFrame, filterUpdatedStartDate, filterUpdatedEndDate, filterUpdatedStartTime, filterUpdatedEndTime, filterStartStartDate, filterStartStartTime, filterStartEndDate, filterStartEndTime, filterDueStartDate, filterDueEndDate, filterDueStartTime, filterDueEndTime, filterAudiences, filterContactIsNew, filterInboundChannels, filterOutboundChannels, filterInboundSocialChannels, filterOutboundSocialChannels, filterForwardedCalls, setShowFilters, tableQuery, setIsModify, }) {
    const { t } = useTranslation();
    const api = useApiTenant();
    const roles = useRoles();
    const auth = useAuth();
    const tenancy = useTenancy();
    const alert = useAlert();
    const [tab, setTab] = useState(exportTicketTabs.FilterBy);
    const [contactDetails] = useState(process.env.APP_ID === "mcd"
        ? [
            ContactDetailAttributes.FirstName,
            ContactDetailAttributes.LastName,
            ContactDetailAttributes.Emails,
        ]
        : [
            ContactDetailAttributes.Id,
            ContactDetailAttributes.FirstName,
            ContactDetailAttributes.LastName,
            ContactDetailAttributes.Company,
            ContactDetailAttributes.Type,
            ContactDetailAttributes.Phones,
            ContactDetailAttributes.Emails,
            ContactDetailAttributes.Address,
            ContactDetailAttributes.OIB,
            ContactDetailAttributes.CreatedAt,
            ContactDetailAttributes.UpdatedAt,
        ]);
    const [ticketDetails] = useState(process.env.APP_ID === "mcd"
        ? [
            ticketDetailAttributes.Id,
            ticketDetailAttributes.Status,
            ticketDetailAttributes.Priority,
            ticketDetailAttributes.Assignee,
            ticketDetailAttributes.Queues,
            ticketDetailAttributes.StartDateTime,
            ticketDetailAttributes.DueDateTime,
            ticketDetailAttributes.Notes,
            ticketDetailAttributes.IsDraft,
            ticketDetailAttributes.CreatedAt,
            ticketDetailAttributes.UpdatedAt,
            ticketDetailAttributes.CreatedBy,
            ticketDetailAttributes.UpdatedBy,
        ]
        : roles.isAdmin
            ? [
                ticketDetailAttributes.Id,
                ticketDetailAttributes.Status,
                ticketDetailAttributes.Priority,
                ticketDetailAttributes.Assignee,
                ticketDetailAttributes.Queues,
                ticketDetailAttributes.StartDateTime,
                ticketDetailAttributes.DueDateTime,
                ticketDetailAttributes.Estimate,
                ticketDetailAttributes.Progress,
                ticketDetailAttributes.ForwardedCall,
                ticketDetailAttributes.Notes,
                ticketDetailAttributes.TotalWorkingTime,
                ticketDetailAttributes.IsDraft,
                ticketDetailAttributes.ContactIsNew,
                ticketDetailAttributes.CreatedAt,
                ticketDetailAttributes.UpdatedAt,
                ticketDetailAttributes.CreatedBy,
                ticketDetailAttributes.UpdatedBy,
            ]
            : [
                ticketDetailAttributes.Id,
                ticketDetailAttributes.Status,
                ticketDetailAttributes.Priority,
                ticketDetailAttributes.Assignee,
                ticketDetailAttributes.Queues,
                ticketDetailAttributes.StartDateTime,
                ticketDetailAttributes.DueDateTime,
                ticketDetailAttributes.Estimate,
                ticketDetailAttributes.Progress,
                ticketDetailAttributes.ForwardedCall,
                ticketDetailAttributes.Notes,
                ticketDetailAttributes.IsDraft,
                ticketDetailAttributes.ContactIsNew,
                ticketDetailAttributes.CreatedAt,
                ticketDetailAttributes.UpdatedAt,
                ticketDetailAttributes.CreatedBy,
                ticketDetailAttributes.UpdatedBy,
            ]);
    const [contactFields, setContactFields] = useState([]);
    const [isLoadingCustomFields, setIsLoadingCustomFields] = useState(false);
    const [isLoadingInformationFields, setIsLoadingInformationFields] = useState(false);
    const [isLoadingChoiceFields, setIsLoadingChoiceFields] = useState(false);
    const [choiceFields, setChoiceFields] = useState([]);
    const [ticketInformationFields, setTicketInformationFields] = useState([]);
    const [contactDetailsFilter, setContactDetailsFilter] = useState([]);
    const [contactFieldsFilter, setContactFieldsFilter] = useState([]);
    const [ticketDetailsFilter, setTicketDetailsFilter] = useState([
        "id",
        "status",
        "priority",
    ]);
    const [choiceFieldsFilter, setChoiceFieldsFilter] = useState([]);
    const [informationFieldsFilter, setInformationFieldsFilter] = useState([]);
    useEffect(() => {
        if (openExportOptions && campaign) {
            setIsLoadingCustomFields(true);
            setIsLoadingInformationFields(true);
            setIsLoadingChoiceFields(true);
            api.GET_customFields("contacts", 10000, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, campaign ? campaign.id : undefined, campaign ? 1 : 0)
                .then(response => {
                setContactFields(response.data.custom_fields.sort((a, b) => a.field_name.localeCompare(b.field_name)));
                setIsLoadingCustomFields(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoadingCustomFields(false);
                }
            });
            api.GET_campaignExportChoiceOptions(campaign.id)
                .then(response => {
                setChoiceFields(response.data.campaign_steps.sort((a, b) => a.name.localeCompare(b.name)));
                setIsLoadingChoiceFields(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoadingCustomFields(false);
                }
            });
            api.GET_campaignExportFieldOptions(campaign.id)
                .then(response => {
                setTicketInformationFields(response.data.custom_fields.sort((a, b) => a.field_name.localeCompare(b.field_name)));
                setIsLoadingInformationFields(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoadingCustomFields(false);
                }
            });
        }
    }, [openExportOptions]);
    const exportData = query => {
        setIsExporting(true);
        const { fieldValues, fieldIds, choiceValues, choiceIds } = getFieldAndChoiceFilterValues();
        api.EXPORT_tickets(query.orderBy ? query.orderBy.field : null, query.orderDirection ? query.orderDirection : null, search !== "" ? search : undefined, search !== "" ? searchBy : undefined, filterCampaigns.length > 0 ? filterCampaigns.map(campaign => campaign.id) : undefined, filterContacts.length > 0 ? filterContacts.map(contact => contact.id) : undefined, filterStatuses.length > 0 ? filterStatuses.map(status => status.id) : undefined, filterPriorities.length > 0 ? filterPriorities.map(priority => priority.id) : undefined, filterAssignees.length > 0 ? filterAssignees.map(user => user.id) : undefined, filterGroups.length > 0
            ? filterGroups.map(group => group.id)
            : !roles.isAgent
                ? ["-1"]
                : undefined, filterCreatedBy.length > 0 ? filterCreatedBy.map(user => user.id) : undefined, filterUpdatedBy.length > 0 ? filterUpdatedBy.map(user => user.id) : undefined, filterDrafts, filterUnassigned, filterUngrouped, filterCreatedStartDate !== "" || filterCreatedStartDateCustom !== ""
            ? timeFrame === "custom"
                ? filterCreatedStartTimeCustom !== ""
                    ? format(new Date(filterCreatedStartDateCustom), "yyyy-MM-dd") +
                        " " +
                        format(new Date(filterCreatedStartTimeCustom), "HH:mm:ss")
                    : format(new Date(filterCreatedStartDateCustom), "yyyy-MM-dd") + " 00:00:00"
                : filterCreatedStartDate
            : undefined, filterCreatedEndDate !== "" || filterCreatedEndDateCustom !== ""
            ? timeFrame === "custom"
                ? filterCreatedEndTimeCustom !== ""
                    ? format(new Date(filterCreatedEndDateCustom), "yyyy-MM-dd") +
                        " " +
                        format(new Date(filterCreatedEndTimeCustom), "HH:mm:ss")
                    : format(new Date(filterCreatedEndDateCustom), "yyyy-MM-dd") + " 23:59:59"
                : filterCreatedEndDate
            : undefined, filterUpdatedStartDate !== ""
            ? filterUpdatedStartTime !== ""
                ? format(new Date(filterUpdatedStartDate), "yyyy-MM-dd") +
                    " " +
                    format(new Date(filterUpdatedStartTime), "HH:mm:ss")
                : format(new Date(filterUpdatedStartDate), "yyyy-MM-dd") + " 00:00:00"
            : undefined, filterUpdatedEndDate !== ""
            ? filterUpdatedEndTime !== ""
                ? format(new Date(filterUpdatedEndDate), "yyyy-MM-dd") +
                    " " +
                    format(new Date(filterUpdatedEndTime), "HH:mm:ss")
                : format(new Date(filterUpdatedEndDate), "yyyy-MM-dd") + " 23:59:59"
            : undefined, filterStartStartDate !== ""
            ? filterStartStartTime !== ""
                ? format(new Date(filterStartStartDate), "yyyy-MM-dd") +
                    " " +
                    format(new Date(filterStartStartTime), "HH:mm:ss")
                : format(new Date(filterStartStartDate), "yyyy-MM-dd") + " 00:00:00"
            : undefined, filterStartEndDate !== ""
            ? filterStartEndTime !== ""
                ? format(new Date(filterStartEndDate), "yyyy-MM-dd") +
                    " " +
                    format(new Date(filterStartEndTime), "HH:mm:ss")
                : format(new Date(filterStartEndDate), "yyyy-MM-dd") + " 23:59:59"
            : undefined, filterDueStartDate !== ""
            ? filterDueStartTime !== ""
                ? format(new Date(filterDueStartDate), "yyyy-MM-dd") +
                    " " +
                    format(new Date(filterDueStartTime), "HH:mm:ss")
                : format(new Date(filterDueStartDate), "yyyy-MM-dd") + " 00:00:00"
            : undefined, filterDueEndDate !== ""
            ? filterDueEndTime !== ""
                ? format(new Date(filterDueEndDate), "yyyy-MM-dd") +
                    " " +
                    format(new Date(filterDueEndTime), "HH:mm:ss")
                : format(new Date(filterDueEndDate), "yyyy-MM-dd") + " 23:59:59"
            : undefined, filterAudiences.length > 0 ? filterAudiences.map(audience => audience.id) : undefined, fieldIds !== "" ? fieldIds : undefined, fieldValues.length > 0 ? fieldValues : undefined, choiceIds !== "" ? choiceIds : undefined, choiceValues.length > 0 ? choiceValues : undefined, contactDetailsFilter.length > 0 ? contactDetailsFilter : "", contactFieldsFilter.length > 0
            ? contactFieldsFilter.map(field => field.id.toString())
            : "", ticketDetailsFilter.length > 0 ? ticketDetailsFilter : "", choiceFieldsFilter.length > 0 ? choiceFieldsFilter.map(field => field.id) : "", informationFieldsFilter.length > 0
            ? informationFieldsFilter.map(field => field.id.toString())
            : "", filterContactIsNew, filterInboundChannels.length > 0 ? filterInboundChannels : undefined, filterOutboundChannels.length > 0 ? filterOutboundChannels : undefined, filterInboundSocialChannels.length > 0 ? filterInboundSocialChannels : undefined, filterOutboundSocialChannels.length > 0 ? filterOutboundSocialChannels : undefined, filterForwardedCalls)
            .then(response => {
            var _a;
            setIsExporting(false);
            saveAs(new Blob([response.data], {
                type: "text/vnd.ms-excel;charset=utf-8",
            }), "Tickets - " +
                (campaign === null || campaign === void 0 ? void 0 : campaign.name) +
                " - " +
                ((_a = tenancy.client) === null || _a === void 0 ? void 0 : _a.name) +
                " @ " +
                process.env.APP_NAME +
                " - " +
                new Date().toLocaleDateString("hr-HR") +
                ".xlsx");
            alert.show(t("tickets.tickets-table.tickets-exported"), "success");
        })
            .catch(error => {
            setIsExporting(false);
            console.error(error);
            alert.show(t("tickets.tickets-table.exporting-error"), "error");
        });
    };
    return (_jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: openExportOptions, onClose: () => {
            setOpenExportOptions(false);
        } }, { children: [_jsx(DialogTitle, { children: _jsxs(Tabs, Object.assign({ indicatorColor: "secondary", textColor: "inherit", value: tab, onChange: (event, value) => setTab(value), variant: "fullWidth" }, { children: [_jsx(Tab, { value: exportTicketTabs.FilterBy, label: t("filters.filter-by") }), _jsx(Tab, { value: exportTicketTabs.ExportOptions, label: t("tickets.tickets-table.export-options") })] })) }), _jsx(DialogContent, { children: tab === exportTicketTabs.ExportOptions ? (_jsxs(Grid, Object.assign({ container: true, spacing: 2, direction: "column", style: { marginTop: 0.5, paddingTop: 1 } }, { children: [process.env.APP_ID !== "mcd" ||
                            (process.env.APP_ID === "mcd" && roles.isAdmin && (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Autocomplete, { options: contactDetails, multiple: true, getOptionLabel: field => field === "oib"
                                                ? "OIB"
                                                : field === "id"
                                                    ? "ID"
                                                    : field === "emails"
                                                        ? t("contacts.contact-attributes.emails")
                                                        : field === "phones"
                                                            ? t("contacts.contact-attributes.phones")
                                                            : field === "first_name"
                                                                ? t("contacts.contact-attributes.first-name")
                                                                : field === "last_name"
                                                                    ? t("contacts.contact-attributes.last-name")
                                                                    : field === "type"
                                                                        ? t("contacts.contact-attributes.type.type")
                                                                        : field === "company"
                                                                            ? t("contacts.contact-attributes.company")
                                                                            : field === "address"
                                                                                ? t("contacts.contact-attributes.address")
                                                                                : field === "created_at"
                                                                                    ? t("created-at")
                                                                                    : t("updated-at"), isOptionEqualToValue: (option, value) => option === value, value: contactDetailsFilter, onChange: (event, value) => {
                                                setContactDetailsFilter(value);
                                            }, renderTags: (tagValue, getTagProps) => tagValue.map((option, index) => (_jsx(Chip, Object.assign({ label: option === "oib"
                                                    ? "OIB"
                                                    : option === "id"
                                                        ? "ID"
                                                        : option === "emails"
                                                            ? t("contacts.contact-attributes.emails")
                                                            : option === "phones"
                                                                ? t("contacts.contact-attributes.phones")
                                                                : option === "first_name"
                                                                    ? t("contacts.contact-attributes.first-name")
                                                                    : option === "last_name"
                                                                        ? t("contacts.contact-attributes.last-name")
                                                                        : option === "type"
                                                                            ? t("contacts.contact-attributes.type.type")
                                                                            : option === "company"
                                                                                ? t("contacts.contact-attributes.company")
                                                                                : option === "address"
                                                                                    ? t("contacts.contact-attributes.address")
                                                                                    : option === "created_at"
                                                                                        ? t("created-at")
                                                                                        : t("updated-at") }, getTagProps({ index }))))), renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("contacts.contact-details"), fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsx(Fragment, { children: params.InputProps.endAdornment })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Autocomplete, { options: contactFields.sort((a, b) => a.field_name.localeCompare(b.field_name)), multiple: true, getOptionLabel: field => prettifyText(field.field_name), isOptionEqualToValue: (option, value) => option.id === value.id, value: contactFieldsFilter, onChange: (event, value) => {
                                                setContactFieldsFilter(value);
                                            }, loading: isLoadingCustomFields, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("fields.contact-fields.contact-fields"), fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isLoadingCustomFields ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Divider, {}) }))] }))), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Autocomplete, { options: ticketDetails, multiple: true, getOptionLabel: field => field === "id"
                                    ? "ID"
                                    : field === "start_date_time"
                                        ? t("tickets.ticket-attributes.start-date")
                                        : field === "due_date_time"
                                            ? t("tickets.ticket-attributes.due-date")
                                            : field === "estimate"
                                                ? t("tickets.ticket-attributes.estimated-time")
                                                : field === "progress"
                                                    ? t("tickets.ticket-attributes.progress") +
                                                        " (% " +
                                                        t("tickets.tickets-table.done") +
                                                        ")"
                                                    : field === "total_working_time"
                                                        ? t("tickets.ticket-activities.ticket-activity-history.total-worked-time")
                                                        : field === "contact_is_new"
                                                            ? t("tickets.tickets-table.is-new-contact")
                                                            : field === "status"
                                                                ? t("tickets.ticket-attributes.status")
                                                                : field === "priority"
                                                                    ? t("tickets.ticket-attributes.priority")
                                                                    : field === "assignees"
                                                                        ? t("tickets.ticket-attributes.assignee")
                                                                        : field === "queues"
                                                                            ? t("tickets.ticket-attributes.queues")
                                                                            : field === "forwarded_call"
                                                                                ? t("tickets.ticket-attributes.forwarded-call")
                                                                                : field === "notes"
                                                                                    ? t("tickets.ticket-attributes.notes")
                                                                                    : field === "is_draft"
                                                                                        ? t("tickets.ticket-attributes.is-draft")
                                                                                        : field === "created_by"
                                                                                            ? t("tickets.ticket-attributes.created-by")
                                                                                            : field === "updated_by"
                                                                                                ? t("tickets.ticket-attributes.updated-by")
                                                                                                : field === "updated_at"
                                                                                                    ? t("updated-at")
                                                                                                    : t("created-at"), isOptionEqualToValue: (option, value) => option === value, value: ticketDetailsFilter, onChange: (event, value) => {
                                    setTicketDetailsFilter(value);
                                }, renderTags: (tagValue, getTagProps) => tagValue.map((option, index) => (_jsx(Chip, Object.assign({ label: option === "id"
                                        ? "ID"
                                        : option === "start_date_time"
                                            ? t("tickets.ticket-attributes.start-date")
                                            : option === "due_date_time"
                                                ? t("tickets.ticket-attributes.due-date")
                                                : option === "estimate"
                                                    ? t("tickets.ticket-attributes.estimated-time")
                                                    : option === "progress"
                                                        ? t("tickets.ticket-attributes.estimated-time") +
                                                            " (% " +
                                                            t("tickets.tickets-table.done") +
                                                            ")"
                                                        : option === "total_working_time"
                                                            ? t("tickets.ticket-activities.ticket-activity-history.total-worked-time")
                                                            : option === "contact_is_new"
                                                                ? t("tickets.tickets-table.is-new-contact")
                                                                : option === "status"
                                                                    ? t("tickets.ticket-attributes.status")
                                                                    : option === "priority"
                                                                        ? t("tickets.ticket-attributes.priority")
                                                                        : option === "assignees"
                                                                            ? t("tickets.ticket-attributes.assignee")
                                                                            : option === "queues"
                                                                                ? t("tickets.ticket-attributes.queues")
                                                                                : option === "forwarded_call"
                                                                                    ? t("tickets.ticket-attributes.forwarded-call")
                                                                                    : option === "notes"
                                                                                        ? t("tickets.ticket-attributes.notes")
                                                                                        : option === "is_draft"
                                                                                            ? t("tickets.ticket-attributes.is-draft")
                                                                                            : option === "created_by"
                                                                                                ? t("tickets.ticket-attributes.created-by")
                                                                                                : option === "updated_by"
                                                                                                    ? t("tickets.ticket-attributes.updated-by")
                                                                                                    : option === "updated_at"
                                                                                                        ? t("updated-at")
                                                                                                        : t("created-at") }, getTagProps({ index }))))), renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("tickets.tickets-table.ticket-details"), fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsx(Fragment, { children: params.InputProps.endAdornment })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Autocomplete, { options: choiceFields.sort((a, b) => a.name.localeCompare(b.name)), multiple: true, getOptionLabel: field => prettifyText(field.name), isOptionEqualToValue: (option, value) => option.name === value.name, value: choiceFieldsFilter, onChange: (event, value) => {
                                    setChoiceFieldsFilter(value);
                                }, loading: isLoadingChoiceFields, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("tickets.tickets-table.ticket-choice-fields"), fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isLoadingChoiceFields ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Autocomplete, { options: ticketInformationFields.sort((a, b) => a.field_name.localeCompare(b.field_name)), multiple: true, getOptionLabel: field => prettifyText(field.field_name), isOptionEqualToValue: (option, value) => option.id === value.id, value: informationFieldsFilter, onChange: (event, value) => {
                                    setInformationFieldsFilter(value);
                                }, loading: isLoadingInformationFields, renderOption: (props, option) => {
                                    return (_createElement("li", Object.assign({}, props, { key: option.id }), option.field_name));
                                }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("tickets.tickets-table.ticket-information-fields"), fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isLoadingInformationFields ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) }))] }))) : (_jsx(Box, Object.assign({ sx: {
                        marginTop: 1,
                        marginBottom: 2,
                    } }, { children: _jsx(Alert, Object.assign({ severity: "warning" }, { children: _jsxs(Box, { children: [(filterCreatedStartDate && filterCreatedEndDate) ||
                                    (filterCreatedStartDateCustom &&
                                        filterCreatedStartDateCustom !== "" &&
                                        filterCreatedEndDateCustom &&
                                        filterCreatedEndDateCustom !== "") ? (_jsxs(Typography, { children: [t("tickets.tickets-table.all-tickets-match"), _jsx("strong", { children: t("filters.your-chosen-filters") }), t("tickets.tickets-table.created-between"), _jsx("strong", { children: timeFrame === "custom"
                                                ? new Date(Date.parse(filterCreatedStartDateCustom)).toLocaleDateString("en-GB") +
                                                    " " +
                                                    new Date(Date.parse(filterCreatedStartTimeCustom
                                                        ? filterCreatedStartTimeCustom
                                                        : filterCreatedStartDateCustom
                                                            ? format(new Date(filterCreatedStartDateCustom), "yyyy-MM-dd") + " 00:00:00"
                                                            : format(new Date(), "yyyy-MM-dd"))).toLocaleTimeString("hr-HR", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    })
                                                : filterCreatedStartDate !== "" &&
                                                    new Date(Date.parse(filterCreatedStartDate)).toLocaleDateString("en-GB") +
                                                        " " +
                                                        new Date(Date.parse(format(new Date(filterCreatedStartDate), "yyyy-MM-dd") + " 00:00:00")).toLocaleTimeString("hr-HR", {
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                        }) }), t("tickets.tickets-table.and"), _jsx("strong", { children: timeFrame === "custom"
                                                ? new Date(Date.parse(filterCreatedEndDateCustom)).toLocaleDateString("en-GB") +
                                                    " " +
                                                    new Date(Date.parse(filterCreatedEndTimeCustom
                                                        ? filterCreatedEndTimeCustom
                                                        : filterCreatedEndDateCustom
                                                            ? format(new Date(filterCreatedEndDateCustom), "yyyy-MM-dd") + " 23:59:59"
                                                            : format(new Date(), "yyyy-MM-dd"))).toLocaleTimeString("hr-HR", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    })
                                                : filterCreatedEndDate !== "" &&
                                                    new Date(Date.parse(filterCreatedEndDate)).toLocaleDateString("en-GB") +
                                                        " " +
                                                        new Date(Date.parse(format(new Date(filterCreatedEndDate), "yyyy-MM-dd") + " 23:59:59")).toLocaleTimeString("hr-HR", {
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                        }) }), t("tickets.tickets-table.will-be-exported")] })) : filterCreatedStartDateCustom && !filterCreatedEndDateCustom ? (_jsxs(Typography, { children: [t("tickets.tickets-table.all-tickets-match"), _jsx("strong", { children: t("filters.your-chosen-filters") }), t("tickets.tickets-table.created-after"), _jsx("strong", { children: new Date(Date.parse(filterCreatedStartDateCustom)).toLocaleDateString("en-GB") +
                                                " " +
                                                new Date(Date.parse(filterCreatedStartTimeCustom
                                                    ? filterCreatedStartTimeCustom
                                                    : format(new Date(filterCreatedStartDateCustom), "yyyy-MM-dd") + " 00:00:00")).toLocaleTimeString("hr-HR", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                }) }), t("tickets.tickets-table.will-be-exported")] })) : !filterCreatedStartDateCustom && filterCreatedEndDateCustom ? (_jsxs(Typography, { children: [t("tickets.tickets-table.all-tickets-match"), _jsx("strong", { children: t("filters.your-chosen-filters") }), t("tickets.tickets-table.created-before"), " ", _jsx("strong", { children: new Date(Date.parse(filterCreatedEndDateCustom)).toLocaleDateString("en-GB") +
                                                " " +
                                                new Date(Date.parse(filterCreatedEndTimeCustom
                                                    ? filterCreatedEndTimeCustom
                                                    : format(new Date(filterCreatedEndDateCustom), "yyyy-MM-dd") + " 00:00:00")).toLocaleTimeString("hr-HR", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                }) }), t("tickets.tickets-table.will-be-exported")] })) : (_jsxs(Typography, { children: [t("tickets.tickets-table.all-tickets-match"), _jsx("strong", { children: t("filters.your-chosen-filters") }), t("tickets.tickets-table.will-be-exported")] })), _jsx(Box, Object.assign({ sx: { paddingTop: 2, marginLeft: -1 } }, { children: _jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                            setShowFilters(true);
                                            setIsModify(true);
                                        } }, { children: t("tickets.tickets-table.modify-filters") })) }))] }) })) }))) }), _jsx(DialogActions, { children: _jsxs(Box, Object.assign({ sx: { paddingRight: 2 } }, { children: [_jsx(LoadingButton, Object.assign({ color: "inherit", onClick: () => {
                                setOpenExportOptions(false);
                            } }, { children: t("close") })), _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "primary", loading: isExporting, onClick: () => {
                                exportData(tableQuery);
                                analytics.sendEvent({
                                    category: "Export",
                                    action: "Export tickets",
                                }, auth.user);
                            }, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("tickets.tickets-table.export-tickets") }))] })) })] })));
}
