import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import Controls from "../blocks/controls";
import ContactDetails from "../contacts/contact-details";
import ContactHistory from "../contacts/contact-history";
import Nope from "../blocks/nope";
import TicketActivityHistory from "./ticket-activity-history";
import TicketDetails from "./ticket-details";
import TicketDelete from "./ticket-delete";
import TicketInformation from "./ticket-information";
import InformationHistory from "./information-history";
import { useApiTenant } from "../../../api/api-tenant";
import { useAuth } from "../../../services/auth";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
import { useAlert } from "../../../services/alert";
import { usePrefs } from "../../../services/prefs";
import analytics from "../../../helpers/analytics";
import { differenceInMinutes } from "date-fns";
export default function TicketViewModal({ showTicketModal, setShowTicketModal, ticketModalId, setDeletedTicket, }) {
    var _a;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const api = useApiTenant();
    const tenancy = useTenancy();
    const auth = useAuth();
    const roles = useRoles();
    const alert = useAlert();
    const prefs = usePrefs();
    const isMobile = useMediaQuery("(max-width: 767px)");
    const [ticket, setTicket] = useState(null);
    const [tab, setTab] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isCloneLoading, setIsCloneLoading] = useState(false);
    const [clone, setClone] = useState(false);
    const cloneTicket = () => {
        setIsCloneLoading(true);
        api.CLONE_ticket(ticketModalId.toString())
            .then(response => {
            setClone(false);
            setIsCloneLoading(false);
            alert.show(t("tickets.tickets-table.ticket-cloned"), "info");
            navigate(tenancy.link("/tickets/" + response.data.ticket.id + "/information"));
        })
            .catch(error => {
            setClone(false);
            setIsCloneLoading(false);
            if (!api.isCancel(error)) {
                console.error(error);
                alert.show(t("tickets.tickets-table.ticket-clone-error"), "error");
            }
        });
    };
    useEffect(() => {
        if (!isCloneLoading)
            return;
        prefs.setTicketStartTime(new Date().toString());
    }, [isCloneLoading]);
    useEffect(() => {
        if (!clone)
            return;
        cloneTicket();
        return () => api.cancel();
    }, [clone]);
    useEffect(() => {
        if (!ticketModalId) {
            setTicket(null);
            return;
        }
        setIsLoading(true);
        api.GET_ticket(ticketModalId)
            .then(response => {
            const ticket = response.data.ticket;
            setTicket(ticket);
            if (process.env.APP_ID === "mcd" && !roles.isAdmin) {
                if (ticket.campaign.has_steps) {
                    setTab("information");
                }
                else {
                    setTab("detail");
                }
            }
            else if (ticket.campaign.has_contact_fields) {
                setTab("contact");
            }
            else if (ticket.campaign.has_steps) {
                setTab("information");
            }
            else {
                setTab("detail");
            }
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, [ticketModalId]);
    useEffect(() => {
        if (!ticketModalId)
            return;
        analytics.sendModalView("/ticket/" + ticketModalId + "/" + tab, auth.user);
    }, [tab]);
    return (_jsx(Dialog, Object.assign({ fullWidth: true, maxWidth: "md", open: showTicketModal, onClose: () => {
            setShowTicketModal(false);
        }, PaperProps: {
            sx: {
                height: "100%",
                bgcolor: "background.default",
            },
        } }, { children: isLoading ? (_jsx(Box, Object.assign({ sx: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
            } }, { children: _jsx(CircularProgress, { color: "inherit" }) }))) : ticket ? (_jsxs(Fragment, { children: [_jsx(DialogTitle, Object.assign({ sx: {
                        bgcolor: "background.paper",
                    } }, { children: _jsxs(Box, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            flexDirection: isMobile ? "column" : "row",
                        } }, { children: [_jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    flexDirection: "column",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            display: "flex",
                                        } }, { children: (process.env.APP_ID !== "mcd" && roles.isAgent) ||
                                            (process.env.APP_ID === "mcd" && !roles.isAdmin) ? (_jsx(Typography, Object.assign({ variant: "h6" }, { children: t("tickets.ticket") + " #" + ticketModalId }))) : (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/tickets/" + ticketModalId), onClick: event => {
                                                event.preventDefault();
                                                navigate(tenancy.link("/tickets/" + ticketModalId));
                                            }, underline: "hover" }, { children: _jsx(Typography, Object.assign({ variant: "h6" }, { children: t("tickets.ticket") + " #" + ticketModalId })) }))) })), _jsxs(Typography, Object.assign({ variant: "caption" }, { children: [`${t("updated-at")} ${new Date(Date.parse(ticket.updated_at)).toLocaleDateString("en-GB") +
                                                " " +
                                                new Date(Date.parse(ticket.updated_at)).toLocaleTimeString("hr-HR", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                })}`, ticket.updated_by && (_jsx("span", { children: ` ${t("by")} ${ticket.updated_by.first_name +
                                                    (ticket.updated_by.last_name
                                                        ? " " + ticket.updated_by.last_name
                                                        : "")}` })), ` / ${t("created-at")} ${new Date(Date.parse(ticket.created_at)).toLocaleDateString("en-GB") +
                                                " " +
                                                new Date(Date.parse(ticket.created_at)).toLocaleTimeString("hr-HR", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                })}`, ticket.created_by && (_jsx("span", { children: ` ${t("by")} ${ticket.created_by.first_name +
                                                    (ticket.created_by.last_name
                                                        ? " " + ticket.created_by.last_name
                                                        : "")}` }))] }))] })), _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: isMobile ? 2 : 0,
                                } }, { children: [(process.env.APP_ID !== "mcd" ||
                                        (process.env.APP_ID === "mcd" && roles.isAdmin)) &&
                                        ticket.campaign.has_steps &&
                                        !ticket.is_draft && (_jsx(Box, { children: process.env.APP_ID === "mcd" &&
                                            roles.isAgent ? null : (_jsx(LoadingButton, Object.assign({ size: "small", loading: isCloneLoading, loadingIndicator: _jsx(CircularProgress, { color: "primary", size: 18 }), onClick: () => setClone(true) }, { children: t("tickets.duplicate-ticket") }))) })), ((process.env.APP_ID !== "mcd" && !roles.isAgent) ||
                                        (process.env.APP_ID === "mcd" && roles.isAdmin) ||
                                        (roles.isAgent &&
                                            ticket.created_by &&
                                            ticket.created_by.id === ((_a = roles.userTenant) === null || _a === void 0 ? void 0 : _a.id) &&
                                            differenceInMinutes(new Date(), new Date(ticket.created_at)) <= 15)) && (_jsx(Box, Object.assign({ sx: { paddingLeft: 2 } }, { children: _jsx(TicketDelete, { ticket: ticket, setDeletedTicket: setDeletedTicket, setShowTicketModal: setShowTicketModal }) })))] }))] })) })), _jsxs(Box, Object.assign({ sx: {
                        paddingX: 3,
                        paddingY: 2,
                    } }, { children: [ticket.is_draft ? (_jsx(Box, Object.assign({ sx: {
                                marginBottom: 2,
                            } }, { children: _jsxs(Alert, Object.assign({ severity: "warning" }, { children: [t("tickets.this-ticket-is-a"), " ", _jsx("strong", { children: t("tickets.draft").toLowerCase() }), "!"] })) }))) : undefined, _jsx(Controls, { tabs: process.env.APP_ID === "mcd" && !roles.isAdmin
                                ? [
                                    ...(ticket.campaign.has_steps
                                        ? [
                                            {
                                                label: t("tickets.ticket-steps.information"),
                                                value: "information",
                                            },
                                        ]
                                        : []),
                                    {
                                        label: t("tickets.ticket-steps.details"),
                                        value: "detail",
                                    },
                                ]
                                : [
                                    ...(ticket.campaign.has_contact_fields
                                        ? [
                                            {
                                                label: t("tickets.ticket-steps.contact"),
                                                value: "contact",
                                            },
                                        ]
                                        : []),
                                    ...(ticket.campaign.has_steps
                                        ? [
                                            {
                                                label: t("tickets.ticket-steps.information"),
                                                value: "information",
                                            },
                                        ]
                                        : []),
                                    {
                                        label: t("tickets.ticket-steps.details"),
                                        value: "detail",
                                    },
                                    ...(ticket.campaign.has_activity_fields
                                        ? [
                                            {
                                                label: t("tickets.ticket-steps.activity"),
                                                value: "activity",
                                            },
                                        ]
                                        : []),
                                ], tab: tab, setTab: setTab }), tab === "contact" && (_jsxs(Fragment, { children: [_jsx(ContactDetails, { type: "ticket", contact: ticket.contact, modal: true, campaign: ticket.campaign }), (roles.isAdmin || roles.isManager) &&
                                    ticket.contact.history &&
                                    ticket.contact.history.length > 0 && (_jsx(ContactHistory, { modal: true, status: "view", contact: ticket.contact }))] })), tab === "information" && (_jsxs(Fragment, { children: [_jsx(TicketInformation, { status: "view", ticket: ticket, modal: true }), ((process.env.APP_ID !== "mcd" && !roles.isAgent) ||
                                    (process.env.APP_ID === "mcd" && roles.isAdmin)) &&
                                    ticket.information_history &&
                                    ticket.information_history.length > 0 && (_jsx(InformationHistory, { modal: true, status: "view", ticket: ticket }))] })), tab === "detail" && _jsx(TicketDetails, { ticket: ticket, modal: true }), tab === "activity" && (_jsx(TicketActivityHistory, { ticket: ticket, modal: true }))] }))] })) : (_jsx(Nope, { title: t("errors.404"), caption: t("tickets.not-found") })) })));
}
