import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PrevIcon from "@mui/icons-material/NavigateBefore";
import NextIcon from "@mui/icons-material/NavigateNext";
import InformationHistory from "./information-history";
import StepGroup from "./information/step-group";
import StepContent from "./information/step-content";
import StepChoice from "./information/step-choice";
import StepInformation from "./information/step-information";
import { useApiTenant } from "../../../api/api-tenant";
import { useApiPublic } from "../../../api/api-public";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
import { useAlert } from "../../../services/alert";
import { useAuth } from "../../../services/auth";
import { prettifyText } from "../../../helpers/clean";
import analytics from "../../../helpers/analytics";
export default function TicketInformationForm({ ticket, campaign, modal, setUpdatedInformation, isDemo, historyToDelete, setHistoryToDelete, setStep, setContact, contact, setSaveActivity, setIsInfSaving, isInfSaving, setTicket, }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const roles = useRoles();
    const alert = useAlert();
    const auth = useAuth();
    const apiTenant = useApiTenant();
    const apiPublic = useApiPublic();
    const [steps, setSteps] = useState([]);
    const [information, setInformation] = useState(ticket ? [...ticket.information] : []);
    const [chosenSteps, setChosenSteps] = useState(ticket ? [...ticket.chosen_steps] : []);
    const [groups, setGroups] = useState(ticket ? [...ticket.groups] : []);
    const [groupStepId, setGroupStepId] = useState(undefined);
    const [knowledgeId, setKnowledgeId] = useState(undefined);
    const [knowledge, setKnowledge] = useState(null);
    const [autofillData, setAutofillData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState(null);
    let directChildren = new Array();
    let children = null;
    const createTicketInformation = () => {
        setIsError(false);
        let inf = new Array();
        information.map(i => {
            i.file = undefined;
            inf.push(i);
        });
        let prepareChosenSteps = JSON.parse(JSON.stringify(chosenSteps));
        prepareChosenSteps = prepareChosenSteps.map(step => {
            delete step.chosen_step.campaign_steps;
            delete step.chosen_step.custom_fields;
            delete step.chosen_step.knowledge;
            delete step.parent_step.campaign_steps;
            delete step.parent_step.custom_fields;
            delete step.parent_step.knowledge;
            return step;
        });
        if (!auth.isPublic) {
            setIsSaving(true);
            apiTenant
                .CREATE_ticketInformation(ticket.id, inf, prepareChosenSteps, groups.length > 0 ? groups.map(group => parseInt(group.id)) : undefined, groupStepId ? parseInt(groupStepId) : undefined)
                .then(response => {
                setIsSaving(false);
                if (modal && setUpdatedInformation) {
                    setInformation([...response.data.ticket.information]);
                    setChosenSteps([...response.data.ticket.chosen_steps]);
                    setUpdatedInformation(true);
                }
                else if (ticket.is_draft) {
                    alert.show(t("tickets.ticket-information.ticket-information-form.information-saved"), "info");
                    navigate(tenancy.link("/tickets/" + ticket.id + "/details"));
                }
                else {
                    alert.show(t("tickets.ticket-information.ticket-information-form.information-updated"), "success");
                    navigate(tenancy.link("/tickets/" + ticket.id));
                }
            })
                .catch(error => {
                setIsSaving(false);
                setIsError(true);
                if (error.response && error.response.data && error.response.data.errors) {
                    setErrors(error.response.data.errors);
                    console.error(error.response);
                }
                alert.show(t("tickets.ticket-information.ticket-information-form.saving-error"), "error");
            });
        }
        else {
            if (setIsInfSaving)
                setIsInfSaving(true);
            apiPublic
                .CREATE_surveyTicketInformation(campaign === null || campaign === void 0 ? void 0 : campaign.slug, inf, prepareChosenSteps, groups.length > 0 ? groups.map(group => parseInt(group.id)) : undefined, groupStepId ? parseInt(groupStepId) : undefined, process.env.APP_ENV === "local" ? "1" : tenancy.client.id, contact ? contact.uuid : undefined, ticket ? ticket.id : undefined)
                .then(response => {
                if (!ticket && setTicket) {
                    setTicket(response.data.ticket);
                }
                if (setSaveActivity) {
                    setSaveActivity(true);
                }
            })
                .catch(error => {
                if (setIsInfSaving)
                    setIsInfSaving(false);
                setIsError(true);
                if (error.response && error.response.data && error.response.data.errors) {
                    setErrors(error.response.data.errors);
                    console.error(error.response);
                }
                if (error.response && error.response.status && error.response.status === 409) {
                    alert.show(error.response.data.message, "error");
                }
                else {
                    alert.show(t("tickets.survey.error"), "error");
                }
            });
        }
    };
    useEffect(() => {
        if (!knowledgeId)
            return;
        apiTenant
            .GET_knowledge(knowledgeId)
            .then(response => {
            setKnowledge(response.data.knowledge);
        })
            .catch(error => {
            if (!apiTenant.isCancel(error))
                console.error(error);
        });
        analytics.sendModalView("/knowledge/" + knowledgeId, auth.user);
    }, [knowledgeId]);
    useEffect(() => {
        if (!auth.isPublic) {
            apiTenant
                .GET_campaignSteps(ticket ? ticket.campaign_id : campaign.id)
                .then(response => {
                setSteps(response.data.campaign_steps);
                if ((ticket === null || ticket === void 0 ? void 0 : ticket.information) &&
                    !ticket.contact_is_new &&
                    (ticket === null || ticket === void 0 ? void 0 : ticket.information.length) === 0) {
                    getAutofillData();
                }
                else {
                    setIsLoading(false);
                }
            })
                .catch(error => {
                if (!apiTenant.isCancel(error)) {
                    console.error(error);
                    setIsLoading(false);
                }
            });
        }
        else {
            apiPublic
                .GET_campaignStepsBySlug(process.env.APP_ENV === "local" ? "1" : tenancy.client.id, campaign.slug)
                .then(response => {
                setSteps(response.data.campaign_steps);
                setIsLoading(false);
            })
                .catch(error => {
                if (!apiTenant.isCancel(error)) {
                    console.error(error);
                    setIsLoading(false);
                }
            });
        }
    }, []);
    const enableSave = () => {
        setSaveEnabled(true);
    };
    const disableSave = () => {
        setSaveEnabled(false);
    };
    const getAutofillData = () => {
        var _a;
        const campaignId = ticket ? ticket.campaign_id : campaign === null || campaign === void 0 ? void 0 : campaign.id;
        const contactId = ticket ? (_a = ticket.contact) === null || _a === void 0 ? void 0 : _a.id : contact === null || contact === void 0 ? void 0 : contact.id;
        if (!campaignId || !contactId)
            return;
        apiTenant
            .GET_autofillData(campaignId, contactId)
            .then(response => {
            setAutofillData(response.data.steps);
            setIsLoading(false);
        })
            .catch(error => {
            if (!apiTenant.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
    };
    let flatStepList = new Array();
    const flattenList = (steps, level) => {
        return steps.map(step => {
            if (flatStepList) {
                if (!flatStepList.find(el => el.id === step.id)) {
                    flatStepList.push(step);
                }
            }
            if (step.campaign_steps && step.campaign_steps.length > 0) {
                step.campaign_steps.map(step1 => flatStepList.push(step1));
                flattenList(step.campaign_steps, level + 1);
            }
        });
    };
    flattenList(steps, 0);
    const findDirectChildren = (chosenStep, steps, isSelect) => {
        let directChildren = new Array();
        if (isSelect) {
            directChildren.push(steps === null || steps === void 0 ? void 0 : steps.find(step => step.id === (chosenStep === null || chosenStep === void 0 ? void 0 : chosenStep.id)));
        }
        else {
            if ((chosenStep === null || chosenStep === void 0 ? void 0 : chosenStep.type) !== "choice" || ((chosenStep === null || chosenStep === void 0 ? void 0 : chosenStep.type) === "choice" && !isSelect)) {
                steps === null || steps === void 0 ? void 0 : steps.map(step => {
                    if ((chosenStep === null || chosenStep === void 0 ? void 0 : chosenStep.id) === step.parent_campaign_step_id) {
                        directChildren.push(step);
                    }
                });
            }
        }
        return directChildren;
    };
    const display = (steps) => {
        return (steps &&
            steps.map((step, index) => {
                var _a;
                let directChildrenNames = new Array();
                const dc = findDirectChildren(step, flatStepList, false);
                if (dc.length === 0) {
                    if (!saveEnabled) {
                        enableSave();
                    }
                }
                if (step.type === "group" &&
                    step.groups &&
                    step.groups.length > 0 &&
                    groups &&
                    groups.length === 0) {
                    setGroups(step.groups ? step.groups : []);
                }
                if (step.type === "choice" && chosenSteps.find(e => e.parent_step.id === step.id)) {
                    directChildren = findDirectChildren((_a = chosenSteps.find(e => e.parent_step.id === step.id)) === null || _a === void 0 ? void 0 : _a.chosen_step, flatStepList, true);
                    children = directChildren;
                }
                if (step.type !== "choice" && step.type !== "group") {
                    directChildren = findDirectChildren(step, flatStepList, false);
                    // @ts-ignore
                    if (directChildren === [] && !saveEnabled) {
                        enableSave();
                    }
                    children = directChildren;
                }
                if (step.type === "group" ||
                    (step.type === "choice" && !chosenSteps.find(e => e.parent_step.id === step.id))) {
                    if (step.type !== "choice" && !saveEnabled) {
                        enableSave();
                    }
                    if (!groupStepId && step.type === "group") {
                        setGroupStepId(step.id);
                        if (step.groups)
                            setGroups(step.groups);
                    }
                    children = null;
                }
                if (step.type === "choice") {
                    directChildrenNames = findDirectChildren(step, flatStepList, false);
                }
                return (_jsxs(Fragment, { children: [((step.type === "content" && step.content !== null) ||
                            (step.type === "form" &&
                                step.custom_fields &&
                                step.custom_fields.length > 0) ||
                            (step.type === "group" && step.groups && step.groups.length > 0) ||
                            (step.type === "choice" && directChildrenNames.length > 0)) && (_jsx(Box, Object.assign({ sx: {
                                display: "relative",
                                paddingBottom: 4,
                            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [!modal && !auth.isPublic && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4, lg: isDemo ? 2 : 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                position: "sticky",
                                                top: "100px",
                                            } }, { children: [_jsxs(Box, Object.assign({ sx: { paddingBottom: 1 } }, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: prettifyText(step.name) })), step.caption && step.caption !== "" && (_jsx(Input, { size: "medium", fullWidth: true, multiline: true, value: step.caption, disableUnderline: true, inputProps: {
                                                                readOnly: true,
                                                            } }))] })), step.knowledge &&
                                                    step.knowledge.length > 0 &&
                                                    step.knowledge.map(oneKnowledge => (_jsx(Box, { children: _jsx(Button, Object.assign({ sx: {
                                                                textAlign: "start",
                                                            }, onClick: () => {
                                                                setKnowledge(null);
                                                                setKnowledgeId(oneKnowledge.id);
                                                            } }, { children: prettifyText(oneKnowledge.title) })) }, oneKnowledge.id)))] })) }))), step.type === "content" && step.content !== null && (_jsx(StepContent, { step: step, modal: modal, isDemo: isDemo })), step.type === "choice" && (_jsx(StepChoice, { step: step, modal: modal || auth.isPublic, isDemo: isDemo, ticket: ticket, directChildrenNames: directChildrenNames, chosenSteps: chosenSteps, information: information, setInformation: setInformation, setChosenSteps: setChosenSteps, disableSave: disableSave, setGroupStepId: setGroupStepId, setGroups: setGroups, flatStepList: flatStepList, setErrors: setErrors, setIsError: setIsError })), step.type === "group" && (_jsx(StepGroup, { step: step, modal: modal || auth.isPublic, isDemo: isDemo })), step.type === "form" && (_jsx(StepInformation, { step: step, modal: modal || auth.isPublic, isDemo: isDemo, information: information, setInformation: setInformation, ticket: ticket, errors: errors, isError: isError, setIsError: setIsError, flatStepList: flatStepList, isSaving: isSaving, setIsSaving: setIsSaving, autofillData: autofillData }))] })) }))), _jsx(Box, { children: display(children) })] }, index));
            }));
    };
    return (_jsx(Fragment, { children: isLoading ? (_jsx(Fragment, { children: campaign || modal || auth.isPublic ? (_jsx(Box, Object.assign({ sx: {
                    display: "flex",
                    justifyContent: "center",
                    paddingY: 4,
                    marginY: auth.isPublic ? 10 : 0,
                } }, { children: _jsx(CircularProgress, { color: "inherit", size: 30 }) }))) : (_jsx(Backdrop, Object.assign({ invisible: true, open: true }, { children: _jsx(CircularProgress, { color: "inherit" }) }))) })) : (_jsxs(Fragment, { children: [display(steps), (ticket || auth.isPublic) && (_jsx(Box, Object.assign({ sx: {
                        marginBottom: 2,
                    } }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 4 }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    justifyContent: auth.isPublic ? "center" : "flex-end",
                                    textAlign: auth.isPublic ? "center" : "start",
                                    paddingTop: auth.isPublic ? 4 : 0,
                                } }, { children: [((ticket && ticket.is_draft && !modal && !auth.isPublic) ||
                                        (auth.isPublic &&
                                            campaign &&
                                            ((campaign.field_options &&
                                                campaign.field_options.length > 0) ||
                                                (campaign.custom_field_options &&
                                                    campaign.custom_field_options.length > 0) ||
                                                campaign.instructions))) && (_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(Button, Object.assign({ color: auth.isPublic ? "primary" : "inherit", variant: auth.isPublic ? "outlined" : "text", startIcon: !auth.isPublic ? _jsx(PrevIcon, {}) : undefined, onClick: () => {
                                                if (auth.isPublic && setStep) {
                                                    if ((campaign === null || campaign === void 0 ? void 0 : campaign.field_options.find(a => a.visible &&
                                                        a.table === "contacts")) ||
                                                        (campaign === null || campaign === void 0 ? void 0 : campaign.custom_field_options.find(a => a.visible))) {
                                                        setStep("contact");
                                                        if (setContact) {
                                                            setContact(contact);
                                                        }
                                                    }
                                                    else {
                                                        setStep("welcome");
                                                    }
                                                }
                                                else {
                                                    navigate(tenancy.link("/tickets/" +
                                                        ticket.id +
                                                        "/contact"));
                                                }
                                            } }, { children: auth.isPublic
                                                ? t("tickets.survey.back")
                                                : t("form-component.back") })) }))), _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "secondary", loading: isSaving || isInfSaving, disabled: !saveEnabled, onClick: () => createTicketInformation(), endIcon: ticket && ticket.is_draft && !auth.isPublic ? (_jsx(NextIcon, {})) : undefined, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: auth.isPublic
                                            ? t("tickets.survey.send")
                                            : ticket && ticket.is_draft
                                                ? t("form-component.next")
                                                : t("form-component.update") }))] })) })) })) }))), !modal &&
                    !isDemo &&
                    ((process.env.APP_ID !== "mcd" && !roles.isAgent) ||
                        (process.env.APP_ID === "mcd" && roles.isAdmin)) &&
                    ticket &&
                    ticket.information_history &&
                    ticket.information_history.length > 0 && (_jsx(InformationHistory, { status: "edit", ticket: ticket, historyToDelete: historyToDelete, setHistoryToDelete: setHistoryToDelete })), _jsx(Dialog, Object.assign({ fullWidth: true, maxWidth: "lg", open: !!knowledgeId, onClose: () => setKnowledgeId(undefined) }, { children: _jsx(DialogContent, { children: _jsx(Box, Object.assign({ sx: {
                                paddingBottom: 2,
                            } }, { children: knowledge ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: prettifyText(knowledge.title) })), knowledge.caption && (_jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: prettifyText(knowledge.caption) }))), _jsx(Typography, { dangerouslySetInnerHTML: {
                                            __html: knowledge.content,
                                        } })] })) : (_jsx(Box, Object.assign({ sx: {
                                    display: "flex",
                                    justifyContent: "center",
                                    marginY: 2,
                                } }, { children: _jsx(CircularProgress, { color: "inherit", size: 30 }) }))) })) }) }))] })) }));
}
