import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import { usePrefs } from "../../../services/prefs";
import { useAuth } from "../../../services/auth";
import { useTenancy } from "../../../services/tenancy";
import { colors } from "../../../helpers/colors";
import { GlobalStyles } from "@mui/material";
const logoDark = require("../../../assets/" + process.env.APP_ID + "/logo-dark.svg");
const useStyles = makeStyles(() => createStyles({
    copy: {
        color: "#fff",
    },
}));
export default function SsoLogIn() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const prefs = usePrefs();
    const auth = useAuth();
    const tenancy = useTenancy();
    const query = new URLSearchParams(useLocation().search);
    useEffect(() => {
        var _a;
        if (auth.user || process.env.APP_ID !== "mcd" || !query) {
            navigate("/");
        }
        else {
            auth.ssoLogin(query.get("code"), query.get("session_state"), (_a = tenancy.client) === null || _a === void 0 ? void 0 : _a.id);
        }
    }, [auth.user]);
    return (_jsxs(Box, Object.assign({ sx: {
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
        } }, { children: [_jsx(GlobalStyles, { styles: {
                    body: {
                        backgroundColor: process.env.APP_ID === "mcd"
                            ? colors.primary.light
                            : colors.primary.dark,
                    },
                } }), _jsx(Box, Object.assign({ sx: {
                    position: "absolute",
                    top: 15,
                    right: 10,
                    backgroundColor: "#fff",
                } }, { children: _jsxs(ButtonGroup, Object.assign({ size: "small", variant: "text", color: "inherit" }, { children: [_jsx(Button, Object.assign({ disabled: prefs.lang === "en", onClick: () => {
                                i18n.changeLanguage("en").then(() => {
                                    prefs.setLang("en");
                                });
                            } }, { children: "EN" })), _jsx(Button, Object.assign({ disabled: prefs.lang === "hr", onClick: () => {
                                i18n.changeLanguage("hr").then(() => {
                                    prefs.setLang("hr");
                                });
                            } }, { children: "HR" }))] })) })), _jsxs(Container, Object.assign({ maxWidth: "xs" }, { children: [_jsx(Box, Object.assign({ sx: {
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: 4,
                            paddingBottom: 2,
                        } }, { children: _jsx("img", { src: logoDark, style: {
                                maxHeight: "120px",
                                maxWidth: "220px",
                                objectFit: "contain",
                            }, alt: "Logo" }) })), _jsx(Box, Object.assign({ sx: {
                            paddingY: 4,
                        } }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: 4,
                                    minHeight: "150px",
                                } }, { children: auth.isError ? (_jsx(Typography, { children: t("pages.auth.login.sso-error") })) : (_jsx(CircularProgress, { color: "inherit" })) })) }) })), _jsx(Box, Object.assign({ sx: {
                            paddingTop: 2,
                            paddingBottom: 4,
                            textAlign: "center",
                        } }, { children: _jsxs(Typography, Object.assign({ className: classes.copy }, { children: [_jsx(Link, Object.assign({ href: process.env.APP_LINK, color: "inherit", underline: "hover" }, { children: process.env.APP_NAME })), _jsxs(Box, Object.assign({ component: "span", sx: {
                                        paddingLeft: 1,
                                    } }, { children: ["\u00A9 ", new Date().getFullYear()] }))] })) }))] }))] })));
}
