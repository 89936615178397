import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import TicketsMetricsGeneral from "./metrics/ticket-metrics-general";
import TicketsMetricsTimeContactCall from "./metrics/ticket-metrics-time-contact-call";
import TicketsMetricsUser from "./metrics/ticket-metrics-user";
import TicketsMetricsChannel from "./metrics/ticket-metrics-channel";
import TicketsMetricsSummary from "./metrics/ticket-metrics-summary";
export default function TicketsMetrics({ type, group_id, contact_id, user_id, campaign, globalRange, setGlobalRange, globalStartDate, globalStartTime, globalEndDate, globalEndTime, isChosenGlobalCustomRange, setIsChosenGlobalCustomRange, }) {
    return (_jsx(Box, Object.assign({ sx: {
            marginBottom: 4,
        } }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 4 }, { children: type === "campaign" || type === "campaign_group" ? (_jsxs(Fragment, { children: [_jsx(TicketsMetricsGeneral, { type: type, group_id: group_id, contact_id: contact_id, user_id: user_id, campaign: campaign, globalRange: globalRange, setGlobalRange: setGlobalRange, globalStartDate: globalStartDate, globalStartTime: globalStartTime, globalEndDate: globalEndDate, globalEndTime: globalEndTime, isChosenGlobalCustomRange: isChosenGlobalCustomRange, setIsChosenGlobalCustomRange: setIsChosenGlobalCustomRange }), process.env.APP_ID !== "mcd" && (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Box, Object.assign({ sx: { paddingTop: 1 } }, { children: _jsx(Divider, {}) })) })), _jsx(TicketsMetricsTimeContactCall, { type: type, group_id: group_id, campaign: campaign, globalRange: globalRange, setGlobalRange: setGlobalRange, globalStartDate: globalStartDate, globalStartTime: globalStartTime, globalEndDate: globalEndDate, globalEndTime: globalEndTime, isChosenGlobalCustomRange: isChosenGlobalCustomRange }), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Box, Object.assign({ sx: { paddingTop: 1 } }, { children: _jsx(Divider, {}) })) })), _jsx(TicketsMetricsUser, { type: type, group_id: group_id, campaign: campaign, globalRange: globalRange, setGlobalRange: setGlobalRange, globalStartDate: globalStartDate, globalStartTime: globalStartTime, globalEndDate: globalEndDate, globalEndTime: globalEndTime, isChosenGlobalCustomRange: isChosenGlobalCustomRange })] })), (!campaign ||
                        (campaign &&
                            campaign.field_options.find(a => a.data === "incoming_channel" && a.visible)) ||
                        (campaign &&
                            campaign.field_options.find(a => a.data === "outgoing_channel" && a.visible))) && (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Box, Object.assign({ sx: { paddingTop: 1 } }, { children: _jsx(Divider, {}) })) })), _jsx(TicketsMetricsChannel, { type: type, group_id: group_id, campaign: campaign, globalRange: globalRange, setGlobalRange: setGlobalRange, globalStartDate: globalStartDate, globalStartTime: globalStartTime, globalEndDate: globalEndDate, globalEndTime: globalEndTime, isChosenGlobalCustomRange: isChosenGlobalCustomRange })] }))] })) : (_jsx(Fragment, { children: _jsx(TicketsMetricsSummary, { type: type, group_id: group_id, contact_id: contact_id, user_id: user_id, campaign: campaign, globalRange: globalRange, setGlobalRange: setGlobalRange, globalStartDate: globalStartDate, globalStartTime: globalStartTime, globalEndDate: globalEndDate, globalEndTime: globalEndTime, isChosenGlobalCustomRange: isChosenGlobalCustomRange }) })) })) })));
}
