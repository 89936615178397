import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Intro from "../elements/blocks/intro";
import Controls from "../elements/blocks/controls";
import DashboardMetrics from "../elements/dashboard/dashboard-metrics";
import TicketsTable from "../elements/tickets/tickets-table";
import TicketCampaignSelect from "../elements/tickets/ticket-campaign-select";
import McdMetrics from "../elements/dashboard/mcd-metrics";
import KnowledgeCards from "../elements/knowledge/knowledge-cards";
import PaginationNumbers from "../elements/blocks/pagination";
import KnowledgeLoading from "../elements/knowledge/knowledge-loading";
import { useAuth } from "../../services/auth";
import { useRoles } from "../../services/roles";
import { useTenancy } from "../../services/tenancy";
import { useApiTenant } from "../../api/api-tenant";
export default function Dashboard() {
    var _a, _b, _c;
    const { t } = useTranslation();
    const auth = useAuth();
    const roles = useRoles();
    const tenancy = useTenancy();
    const api = useApiTenant();
    const [newTicket, setNewTicket] = useState(false);
    const [tab, setTab] = useState(roles.isAdmin || roles.isManager || process.env.APP_ID === "mcd" ? "tickets" : "assigned");
    const [displayOptions, setDisplayOptions] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isKnowledgeLoading, setIsKnowledgeLoading] = useState(true);
    const [knowledge, setKnowledge] = useState([]);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const personalizedMessage = () => {
        let hour = new Date().getHours();
        let message = "";
        if (hour > 5 && hour <= 11)
            message += t("dashboard.good-morning");
        else if (hour > 11 && hour <= 17)
            message += t("dashboard.good-afternoon");
        else if (hour > 17 && hour <= 22)
            message += t("dashboard.good-evening");
        else
            message += t("dashboard.good-night");
        if (auth.user)
            message += ", " + auth.user.first_name;
        return message;
    };
    useEffect(() => {
        setIsKnowledgeLoading(true);
        api.GET_knowledgeAll(3, page, "updated_at", "desc", undefined, undefined, 1)
            .then(response => {
            setKnowledge(response.data.knowledge);
            setPage(response.data.meta.current_page);
            setPages(response.data.meta.last_page);
            setIsKnowledgeLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsKnowledgeLoading(false);
            }
        });
    }, [page]);
    useEffect(() => {
        api.GET_displayOptionsForDashboard()
            .then(response => {
            setDisplayOptions(roles.isAgent
                ? response.data.agent
                : roles.isManager
                    ? response.data.manager
                    : response.data.admin);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Intro, { name: personalizedMessage(), breadcrumbs: [
                    {
                        name: t("dashboard.dashboard"),
                        link: "/dashboard",
                    },
                ], button: process.env.APP_ID === "mcd" &&
                    !roles.isAdmin &&
                    ((_a = tenancy.client) === null || _a === void 0 ? void 0 : _a.domain) !== "naljepnice"
                    ? undefined
                    : {
                        label: ((_b = tenancy.client) === null || _b === void 0 ? void 0 : _b.domain) === "naljepnice"
                            ? t("tickets.new-order")
                            : t("tickets.new-ticket"),
                        action: () => setNewTicket(true),
                        color: "secondary",
                        type: "new",
                    }, loading: isLoading }), knowledge.length > 0 && (_jsxs(Box, Object.assign({ sx: {
                    marginBottom: pages > 1 ? 0 : 4,
                } }, { children: [isKnowledgeLoading ? (_jsx(KnowledgeLoading, {})) : (_jsx(KnowledgeCards, { knowledge: knowledge, isDashboard: true })), pages > 1 && _jsx(PaginationNumbers, { pages: pages, page: page, setPage: setPage })] }))), process.env.APP_ID === "mcd" && ((_c = tenancy.client) === null || _c === void 0 ? void 0 : _c.domain) === "mcd" && _jsx(McdMetrics, {}), process.env.APP_ID !== "mcd" && (roles.isManager || roles.isAdmin) && (_jsx(DashboardMetrics, {})), isLoading ? (_jsx(Fragment, {})) : (_jsxs(Fragment, { children: [_jsx(Controls, { tabs: roles.isAdmin || roles.isManager
                            ? process.env.APP_ID === "mcd"
                                ? [
                                    {
                                        label: t("dashboard.all-tickets"),
                                        value: "tickets",
                                    },
                                    {
                                        label: t("dashboard.assigned-to-me"),
                                        value: "assigned",
                                    },
                                ]
                                : [
                                    {
                                        label: t("dashboard.all-tickets"),
                                        value: "tickets",
                                    },
                                    {
                                        label: t("dashboard.assigned-to-me"),
                                        value: "assigned",
                                    },
                                    {
                                        label: t("dashboard.my-drafts"),
                                        value: "drafts",
                                    },
                                    {
                                        label: t("dashboard.my-schedule"),
                                        value: "scheduled",
                                    },
                                    {
                                        label: t("dashboard.my-tickets"),
                                        value: "user",
                                    },
                                ]
                            : process.env.APP_ID === "mcd"
                                ? []
                                : [
                                    {
                                        label: t("dashboard.assigned-to-me"),
                                        value: "assigned",
                                    },
                                    {
                                        label: t("dashboard.my-drafts"),
                                        value: "drafts",
                                    },
                                    {
                                        label: t("dashboard.my-schedule"),
                                        value: "scheduled",
                                    },
                                    {
                                        label: t("dashboard.my-tickets"),
                                        value: "user",
                                    },
                                ], tab: tab, setTab: setTab }), tab === "tickets" && (_jsx(TicketsTable, { type: "tickets", displayOptions: displayOptions })), roles.userTenant && (_jsxs(Fragment, { children: [tab === "assigned" && (_jsx(TicketsTable, { type: "assigned", user: roles.userTenant, displayOptions: displayOptions })), tab === "drafts" && (_jsx(TicketsTable, { type: "drafts", user: roles.userTenant, displayOptions: displayOptions })), tab === "scheduled" && (_jsx(TicketsTable, { type: "scheduled", user: roles.userTenant, displayOptions: displayOptions })), tab === "user" && (_jsx(TicketsTable, { type: "user", user: roles.userTenant, displayOptions: displayOptions }))] }))] })), _jsx(TicketCampaignSelect, { newTicket: newTicket, setNewTicket: setNewTicket })] }));
}
