import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { differenceInSeconds, set, compareAsc } from "date-fns";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Hidden from "@mui/material/Hidden";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import LoadingButton from "@mui/lab/LoadingButton";
import DetailsIcon from "@mui/icons-material/Assignment";
import PrevIcon from "@mui/icons-material/NavigateBefore";
import NextIcon from "@mui/icons-material/NavigateNext";
import AccountIcon from "@mui/icons-material/AccountCircle";
import InputDatetime from "../inputs/input-datetime";
import InputSlider from "../inputs/input-slider";
import InputTextArea from "../inputs/input-textarea";
import { useTenancy } from "../../../services/tenancy";
import { useAuth } from "../../../services/auth";
import { usePrefs } from "../../../services/prefs";
import { useAlert } from "../../../services/alert";
import { useApiTenant } from "../../../api/api-tenant";
import { useRoles } from "../../../services/roles";
import { prettifyText } from "../../../helpers/clean";
import { flatGroups, flatGroupsWithLevels, groupInGroups, relatedChildGroups, relatedParentGroups, sortGroupsWithLevels, } from "../../../helpers/groups";
export default function CampaignTicket({ ticket, modal, setUpdatedDetails }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const alert = useAlert();
    const api = useApiTenant();
    const roles = useRoles();
    const auth = useAuth();
    const prefs = usePrefs();
    const [campaign] = useState((_a = ticket === null || ticket === void 0 ? void 0 : ticket.campaign) !== null && _a !== void 0 ? _a : null);
    const [groups] = useState((_b = ticket === null || ticket === void 0 ? void 0 : ticket.groups) !== null && _b !== void 0 ? _b : []);
    const [priority, setPriority] = useState(((_c = ticket === null || ticket === void 0 ? void 0 : ticket.ticket_detail) === null || _c === void 0 ? void 0 : _c.priority)
        ? (_d = ticket === null || ticket === void 0 ? void 0 : ticket.ticket_detail) === null || _d === void 0 ? void 0 : _d.priority
        : campaign.ticket_priorities.find(e => e.level < 75 && e.level > 25)
            ? campaign.ticket_priorities.find(e => e.level < 75 && e.level > 25)
            : campaign.ticket_priorities[0]);
    const [status, setStatus] = useState(((_e = ticket === null || ticket === void 0 ? void 0 : ticket.ticket_detail) === null || _e === void 0 ? void 0 : _e.status)
        ? (_f = ticket === null || ticket === void 0 ? void 0 : ticket.ticket_detail) === null || _f === void 0 ? void 0 : _f.status
        : campaign.ticket_statuses.find(e => e.type === "opened")
            ? campaign.ticket_statuses.find(e => e.type === "opened")
            : campaign.ticket_statuses[0]);
    const [startDate, setStartDate] = useState((_h = (_g = ticket === null || ticket === void 0 ? void 0 : ticket.ticket_detail) === null || _g === void 0 ? void 0 : _g.start_date_time) !== null && _h !== void 0 ? _h : null);
    const [dueDate, setDueDate] = useState((_k = (_j = ticket === null || ticket === void 0 ? void 0 : ticket.ticket_detail) === null || _j === void 0 ? void 0 : _j.due_date_time) !== null && _k !== void 0 ? _k : null);
    const [estimatedTime, setEstimatedTime] = useState(ticket.ticket_detail && ticket.ticket_detail.estimate
        ? () => {
            var _a;
            let date = new Date(1970, 0, 1);
            date.setSeconds((_a = ticket === null || ticket === void 0 ? void 0 : ticket.ticket_detail) === null || _a === void 0 ? void 0 : _a.estimate);
            return date;
        }
        : null);
    const [progress, setProgress] = useState((_m = (_l = ticket === null || ticket === void 0 ? void 0 : ticket.ticket_detail) === null || _l === void 0 ? void 0 : _l.progress) !== null && _m !== void 0 ? _m : 0);
    const [notes, setNotes] = useState((_p = (_o = ticket === null || ticket === void 0 ? void 0 : ticket.ticket_detail) === null || _o === void 0 ? void 0 : _o.notes) !== null && _p !== void 0 ? _p : "");
    const [forwardedCall, setForwardedCall] = useState((_r = (_q = ticket === null || ticket === void 0 ? void 0 : ticket.ticket_detail) === null || _q === void 0 ? void 0 : _q.forwarded_call) !== null && _r !== void 0 ? _r : false);
    const [usersWithGroups, setUsersWithGroups] = useState([]);
    const [assignedUsersWithGroups, setAssignedUsersWithGroups] = useState([]);
    const [isUsersLoading, setIsUsersLoading] = useState(false);
    const [detailsGroups, setDetailsGroups] = useState([]);
    const [sortedDetailsGroups, setSortedDetailsGroups] = useState([]);
    const [relatedDetailsGroups, setRelatedDetailsGroups] = useState([]);
    const [selectedDetailsGroups, setSelectedDetailsGroups] = useState((_t = (_s = ticket === null || ticket === void 0 ? void 0 : ticket.ticket_detail) === null || _s === void 0 ? void 0 : _s.groups) !== null && _t !== void 0 ? _t : []);
    const [isSaving, setIsSaving] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState(null);
    const createTicketDetail = () => {
        setIsSaving(true);
        setIsError(false);
        let newStartDate = "";
        let newDueDate = "";
        if (startDate) {
            let formatStartDate = new Date(startDate);
            let year = formatStartDate.getFullYear();
            let month = formatStartDate.getMonth() + 1;
            let day = formatStartDate.getDate();
            let hours = formatStartDate.getHours();
            let minutes = formatStartDate.getMinutes();
            newStartDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":00";
        }
        if (dueDate) {
            let formatDueDate = new Date(dueDate);
            let year = formatDueDate.getFullYear();
            let month = formatDueDate.getMonth() + 1;
            let day = formatDueDate.getDate();
            let hours = formatDueDate.getHours();
            let minutes = formatDueDate.getMinutes();
            newDueDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":00";
        }
        api.CREATE_ticketDetail(ticket.id, priority.id, status.id, assignedUsersWithGroups.map(group => group.user.id), estimatedTime && compareAsc(estimatedTime, new Date(1970, 0, 1)) !== 0
            ? differenceInSeconds(estimatedTime, set(new Date(1970, 0, 1), {
                hours: 0,
                minutes: 0,
                seconds: 0,
                milliseconds: 0,
            }))
            : null, newStartDate !== "" ? newStartDate : null, newDueDate !== "" ? newDueDate : null, progress, notes, forwardedCall, selectedDetailsGroups && selectedDetailsGroups.length > 0
            ? selectedDetailsGroups.map(group => group.id)
            : [])
            .then(() => {
            if (modal && setUpdatedDetails) {
                setIsSaving(false);
                setUpdatedDetails(true);
            }
            else if (ticket.is_draft) {
                if (ticket.campaign.has_activity_fields &&
                    (process.env.APP_ID !== "mcd" ||
                        (process.env.APP_ID === "mcd" && roles.isAdmin))) {
                    alert.show(t("tickets.ticket-details.ticket-details-form.details-saved"), "info");
                    navigate(tenancy.link("/tickets/" + ticket.id + "/activity"));
                }
                else {
                    let workedTimeInSeconds;
                    if (prefs.ticketStartTime) {
                        workedTimeInSeconds = differenceInSeconds(new Date(), new Date(prefs.ticketStartTime));
                    }
                    api.CREATE_ticketActivity(ticket.id, false, workedTimeInSeconds).then(() => {
                        if (roles.isAgent && process.env.APP_ID !== "mcd") {
                            prefs.setTicketStartTime(null);
                            prefs.setTicketPhone(null);
                            if (campaign.type === "contract")
                                navigate(tenancy.link("/campaigns/" + campaign.id));
                            else
                                navigate(tenancy.link("/campaigns/" + campaign.id + "/new-ticket"));
                        }
                        else {
                            prefs.setTicketStartTime(null);
                            prefs.setTicketPhone(null);
                            alert.show(t("tickets.ticket-activities.ticket-activity-form.ticket-created"), "success");
                            navigate(tenancy.link("/campaigns/" + campaign.id));
                        }
                    });
                }
            }
            else {
                alert.show(t("tickets.ticket-details.ticket-details-form.details-updated"), "success");
                navigate(tenancy.link("/tickets/" + ticket.id));
            }
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            if (error.response && error.response.data && error.response.data.errors) {
                setErrors(error.response.data.errors);
                console.error(error.response);
            }
            alert.show(t("tickets.ticket-details.ticket-details-form.creating-details-error"), "error");
        });
    };
    useEffect(() => {
        var _a, _b;
        setIsUsersLoading(true);
        let campaignGroups;
        if (campaign.details_groups) {
            campaignGroups = flatGroups(campaign.details_groups, 0, []);
            setDetailsGroups(campaignGroups);
            let groupsLevels = flatGroupsWithLevels(campaign.details_groups, 0, []);
            campaignGroups = sortGroupsWithLevels(groupsLevels, 0, [], 1);
            setSortedDetailsGroups(campaignGroups);
        }
        if ((groups && groups.length > 0) ||
            (campaign.details_groups && campaign.details_groups.length > 0)) {
            api.GET_groupUsers(undefined, 10000, 1, undefined, undefined, undefined, [
                groups.map(group => Number(group.id)),
                (_b = (_a = campaign.details_groups) === null || _a === void 0 ? void 0 : _a.map(group => Number(group.id))) !== null && _b !== void 0 ? _b : [],
            ].flat(), 1)
                .then(response => {
                var _a;
                const usersWithGroups = [];
                response.data.tenant_users
                    .sort((a, b) => a.first_name.localeCompare(b.first_name))
                    .map((user) => {
                    var _a;
                    const diff = [];
                    (_a = user.parent_groups) === null || _a === void 0 ? void 0 : _a.map(group => {
                        const groups = flatGroups([group], 0, []);
                        diff.push({ group: group, parentGroups: groups });
                    });
                    usersWithGroups.push({
                        user: user,
                        diffGroups: diff,
                    });
                });
                if ((_a = ticket === null || ticket === void 0 ? void 0 : ticket.ticket_detail) === null || _a === void 0 ? void 0 : _a.assignees) {
                    const newAssignedUsersWithGroups = usersWithGroups.filter(userGroups => {
                        var _a;
                        return (_a = ticket === null || ticket === void 0 ? void 0 : ticket.ticket_detail) === null || _a === void 0 ? void 0 : _a.assignees.some(assignee => userGroups.user.id === assignee.id);
                    });
                    if (newAssignedUsersWithGroups) {
                        setAssignedUsersWithGroups(newAssignedUsersWithGroups);
                    }
                }
                if (ticket.campaign.type === "contract") {
                    const newAssignedUsersWithGroups = usersWithGroups.find(userGroups => { var _a; return userGroups.user.id === ((_a = roles.userTenant) === null || _a === void 0 ? void 0 : _a.id); });
                    setAssignedUsersWithGroups(newAssignedUsersWithGroups ? [newAssignedUsersWithGroups] : []);
                }
                setUsersWithGroups(usersWithGroups);
                setIsUsersLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error))
                    console.error(error);
            });
        }
        else {
            api.GET_users(10000, 1, "first_name", undefined, undefined, undefined, undefined, undefined, 1)
                .then(response => {
                var _a;
                const usersWithGroups = [];
                response.data.tenant_users.map((user) => {
                    var _a;
                    const diff = [];
                    (_a = user.parent_groups) === null || _a === void 0 ? void 0 : _a.map(group => {
                        const groups = flatGroups([group], 0, []);
                        diff.push({ group: group, parentGroups: groups });
                    });
                    usersWithGroups.push({
                        user: user,
                        diffGroups: diff,
                    });
                });
                if ((_a = ticket === null || ticket === void 0 ? void 0 : ticket.ticket_detail) === null || _a === void 0 ? void 0 : _a.assignees) {
                    const newAssignedUsersWithGroups = usersWithGroups.filter(userGroups => {
                        var _a;
                        return (_a = ticket === null || ticket === void 0 ? void 0 : ticket.ticket_detail) === null || _a === void 0 ? void 0 : _a.assignees.some(assignee => userGroups.user.id === assignee.id);
                    });
                    if (newAssignedUsersWithGroups) {
                        setAssignedUsersWithGroups(newAssignedUsersWithGroups);
                    }
                }
                if (ticket.campaign.type === "contract") {
                    const newAssignedUsersWithGroups = usersWithGroups.find(userGroups => userGroups.user.id === auth.user.id);
                    setAssignedUsersWithGroups(newAssignedUsersWithGroups ? [newAssignedUsersWithGroups] : []);
                }
                setUsersWithGroups(usersWithGroups);
                setIsUsersLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error))
                    console.error(error);
            });
        }
        return () => {
            api.cancel();
        };
    }, []);
    return (_jsx(Fragment, { children: _jsxs(Box, Object.assign({ sx: {
                marginBottom: 2,
            } }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [!modal && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    position: "sticky",
                                    top: "100px",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(DetailsIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("tickets.ticket-details.details") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("tickets.ticket-details.ticket-status") }))] })] })) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, md: modal ? 12 : 8 }, { children: _jsx(Paper, Object.assign({ component: "form" }, { children: _jsx(Box, Object.assign({ sx: {
                                        padding: 4,
                                    } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsxs(FormControl, Object.assign({ required: true, fullWidth: true }, { children: [_jsx(InputLabel, { children: t("tickets.ticket-attributes.status") }), _jsx(Select, Object.assign({ label: t("tickets.ticket-attributes.status") + " *", value: status.id, onChange: event => {
                                                                setStatus(campaign === null || campaign === void 0 ? void 0 : campaign.ticket_statuses.find(e => e.id === event.target.value));
                                                            } }, { children: campaign === null || campaign === void 0 ? void 0 : campaign.ticket_statuses.sort((a, b) => a.name
                                                                .toString()
                                                                .localeCompare(b.name.toString())).sort((a, b) => a.type
                                                                .toString()
                                                                .localeCompare(b.type.toString())).map(ticketStatus => {
                                                                return (_jsx(MenuItem, Object.assign({ value: ticketStatus.id }, { children: prettifyText(ticketStatus.name) }), ticketStatus.id));
                                                            }) }))] })) })), ((_u = campaign === null || campaign === void 0 ? void 0 : campaign.ticket_priorities) === null || _u === void 0 ? void 0 : _u.length) > 1 && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsxs(FormControl, Object.assign({ required: true, fullWidth: true }, { children: [_jsx(InputLabel, { children: t("tickets.ticket-attributes.priority") }), _jsx(Select, Object.assign({ label: t("tickets.ticket-attributes.priority") +
                                                                " *", value: priority.id, onChange: event => {
                                                                setPriority(campaign === null || campaign === void 0 ? void 0 : campaign.ticket_priorities.find(e => e.id === event.target.value));
                                                            }, disabled: process.env.APP_ID === "mcd" &&
                                                                !roles.isAdmin }, { children: campaign === null || campaign === void 0 ? void 0 : campaign.ticket_priorities.sort((a, b) => a.name
                                                                .toString()
                                                                .localeCompare(b.name.toString())).sort((a, b) => a.level - b.level).map(ticketPriority => {
                                                                return (_jsx(MenuItem, Object.assign({ value: ticketPriority.id }, { children: prettifyText(ticketPriority.name) }), ticketPriority.id));
                                                            }) }))] })) }))), (campaign === null || campaign === void 0 ? void 0 : campaign.details_groups) &&
                                                (campaign === null || campaign === void 0 ? void 0 : campaign.details_groups.length) > 0 && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(Autocomplete, { multiple: true, options: sortedDetailsGroups.map(sort => sort.group), getOptionLabel: group => group.name, isOptionEqualToValue: (option, value) => option.id === value.id, getOptionDisabled: option => !!relatedDetailsGroups.find(group => group.id === option.id), value: selectedDetailsGroups, disableCloseOnSelect: true, onChange: (event, value) => {
                                                            let disabledGroups = [];
                                                            value.map(group => {
                                                                disabledGroups = relatedChildGroups(group, disabledGroups);
                                                                disabledGroups =
                                                                    relatedParentGroups(group, detailsGroups, disabledGroups);
                                                            });
                                                            setRelatedDetailsGroups(disabledGroups);
                                                            setSelectedDetailsGroups(value);
                                                        }, onOpen: () => {
                                                            let disabledGroups = [];
                                                            selectedDetailsGroups &&
                                                                selectedDetailsGroups.map(group => {
                                                                    let chosen = groupInGroups(group, detailsGroups);
                                                                    if (chosen) {
                                                                        disabledGroups =
                                                                            relatedChildGroups(chosen, disabledGroups);
                                                                        disabledGroups =
                                                                            relatedParentGroups(chosen, detailsGroups, disabledGroups);
                                                                    }
                                                                });
                                                            setRelatedDetailsGroups(disabledGroups);
                                                        }, renderInput: params => {
                                                            var _a;
                                                            return (_jsx(TextField, Object.assign({}, params, { label: t(((_a = tenancy.client) === null || _a === void 0 ? void 0 : _a.domain) ===
                                                                    "naljepnice"
                                                                    ? "groups.restaurant-groups"
                                                                    : "groups.groups") })));
                                                        } }) })) }))), (!campaign.field_options ||
                                                campaign.field_options.length === 0 ||
                                                (campaign.field_options.find(o => o.data === "assignee_ids" &&
                                                    o.table === "ticket_details") &&
                                                    campaign.field_options.find(o => o.data === "assignee_ids" &&
                                                        o.table === "ticket_details").visible)) && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(Autocomplete, { multiple: true, options: usersWithGroups, getOptionLabel: userGroups => {
                                                        var _a;
                                                        return (userGroups.user.first_name
                                                            ? userGroups.user.first_name + " "
                                                            : "") + ((_a = userGroups.user.last_name) !== null && _a !== void 0 ? _a : "");
                                                    }, isOptionEqualToValue: (option, value) => option &&
                                                        option.user.global_id === value.user.global_id, value: assignedUsersWithGroups, onChange: (event, value) => {
                                                        setAssignedUsersWithGroups(value);
                                                        if (isError &&
                                                            (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("assignee_ids")))
                                                            setIsError(false);
                                                    }, loading: isUsersLoading, renderOption: (props, userGroups) => {
                                                        var _a, _b;
                                                        return (_jsx("li", Object.assign({}, props, { children: _jsxs(Box, Object.assign({ sx: {
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                } }, { children: [_jsxs(Box, Object.assign({ sx: {
                                                                            display: "flex",
                                                                        } }, { children: [_jsx(AccountIcon, { style: {
                                                                                    color: userGroups.user.id ===
                                                                                        ((_a = roles.userTenant) === null || _a === void 0 ? void 0 : _a.id)
                                                                                        ? "#4caf50"
                                                                                        : userGroups.user
                                                                                            .email &&
                                                                                            (userGroups.user.email.includes("modernekomunikacije.hr") ||
                                                                                                userGroups.user.email.includes("significo.hr"))
                                                                                            ? "#254092"
                                                                                            : "#bebebe",
                                                                                    marginRight: 4,
                                                                                    marginTop: 2,
                                                                                }, fontSize: "small" }), _jsx(Typography, Object.assign({ variant: "button" }, { children: (userGroups.user.first_name
                                                                                    ? userGroups.user
                                                                                        .first_name + " "
                                                                                    : "") +
                                                                                    (userGroups.user.last_name
                                                                                        ? userGroups.user
                                                                                            .last_name + " "
                                                                                        : "") }))] })), _jsx(Box, Object.assign({ sx: {
                                                                            display: "flex",
                                                                            whiteSpace: "pre-wrap",
                                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary" }, { children: usersWithGroups.find(u => u.user.id ===
                                                                                userGroups.user.id)
                                                                                ? (_b = usersWithGroups
                                                                                    .find(u => u.user.id ===
                                                                                    userGroups
                                                                                        .user.id)) === null || _b === void 0 ? void 0 : _b.diffGroups.map(group => group.parentGroups
                                                                                    .map(a => a.name)
                                                                                    .reverse()
                                                                                    .join(" → ")
                                                                                    .replace(/^/, "• ")).reverse().join("\r\n")
                                                                                : "" })) }))] }), userGroups.user.id) })));
                                                    }, renderInput: params => (_jsx(TextField, Object.assign({ required: !campaign.field_options ||
                                                            campaign.field_options.length === 0
                                                            ? false
                                                            : campaign.field_options.find(o => o.data ===
                                                                "assignee_ids" &&
                                                                o.table ===
                                                                    "ticket_details").required }, params, { label: t("tickets.ticket-attributes.assignee"), fullWidth: true, helperText: isError &&
                                                            (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("assignee_ids")) &&
                                                            errors["assignee_ids"][0], InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isUsersLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }), error: isError &&
                                                            (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("assignee_ids")) }))) }) }))), (!campaign.field_options ||
                                                campaign.field_options.length === 0 ||
                                                (campaign.field_options.find(o => o.data === "forwarded_call" &&
                                                    o.table === "ticket_details") &&
                                                    campaign.field_options.find(o => o.data === "forwarded_call" &&
                                                        o.table === "ticket_details").visible)) && (_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsx(Typography, { children: t("tickets.ticket-attributes.forwarded-call") }), _jsxs(Grid, Object.assign({ component: "label", container: true, alignItems: "center", spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: t("no") })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Switch, { color: "secondary", disabled: !ticket.is_draft && roles.isAgent, required: false, checked: forwardedCall ? forwardedCall : false, onChange: () => setForwardedCall(!forwardedCall) }) })), _jsx(Grid, Object.assign({ item: true }, { children: t("yes") }))] }))] }))), (!campaign.field_options ||
                                                campaign.field_options.length === 0 ||
                                                (campaign.field_options.find(o => o.data === "start_date_time" &&
                                                    o.table === "ticket_details") &&
                                                    campaign.field_options.find(o => o.data === "start_date_time" &&
                                                        o.table === "ticket_details").visible) ||
                                                (campaign.field_options.find(o => o.data === "due_date_time" &&
                                                    o.table === "ticket_details") &&
                                                    campaign.field_options.find(o => o.data === "due_date_time" &&
                                                        o.table === "ticket_details").visible) ||
                                                (campaign.field_options.find(o => o.data === "estimate" &&
                                                    o.table === "ticket_details") &&
                                                    campaign.field_options.find(o => o.data === "estimate" &&
                                                        o.table === "ticket_details").visible) ||
                                                (campaign.field_options.find(o => o.data === "progress" &&
                                                    o.table === "ticket_details") &&
                                                    campaign.field_options.find(o => o.data === "progress" &&
                                                        o.table === "ticket_details").visible)) && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) }))), (!campaign.field_options ||
                                                campaign.field_options.length === 0 ||
                                                (campaign.field_options.find(o => o.data === "start_date_time" &&
                                                    o.table === "ticket_details") &&
                                                    campaign.field_options.find(o => o.data === "start_date_time" &&
                                                        o.table === "ticket_details").visible)) && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputDatetime, { required: !campaign.field_options ||
                                                        campaign.field_options.length === 0
                                                        ? false
                                                        : campaign.field_options.find(o => o.data === "start_date_time" &&
                                                            o.table === "ticket_details").required, type: "date_time", label: t("tickets.ticket-attributes.start-date"), value: startDate, setValue: setStartDate, disabled: process.env.APP_ID === "mcd" && !roles.isAdmin }) }))), (!campaign.field_options ||
                                                campaign.field_options.length === 0 ||
                                                (campaign.field_options.find(o => o.data === "due_date_time" &&
                                                    o.table === "ticket_details") &&
                                                    campaign.field_options.find(o => o.data === "due_date_time" &&
                                                        o.table === "ticket_details").visible)) && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputDatetime, { type: "date_time", label: t("tickets.ticket-attributes.due-date"), required: !campaign.field_options ||
                                                        campaign.field_options.length === 0
                                                        ? false
                                                        : campaign.field_options.find(o => o.data === "due_date_time" &&
                                                            o.table === "ticket_details").required, value: dueDate, setValue: setDueDate, disabled: process.env.APP_ID === "mcd" && !roles.isAdmin }) }))), (!campaign.field_options ||
                                                campaign.field_options.length === 0 ||
                                                (campaign.field_options.find(o => o.data === "estimate" &&
                                                    o.table === "ticket_details") &&
                                                    campaign.field_options.find(o => o.data === "estimate" &&
                                                        o.table === "ticket_details").visible)) && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(LocalizationProvider
                                                // @ts-ignore
                                                , Object.assign({ 
                                                    // @ts-ignore
                                                    dateAdapter: AdapterDateFns }, { children: _jsx(TimePicker, { ampm: false, openTo: "hours", views: ["hours", "minutes", "seconds"], inputFormat: "HH:mm:ss", mask: "__:__:__", label: t("tickets.ticket-attributes.estimated-time"), value: estimatedTime
                                                            ? estimatedTime
                                                            : new Date(1970, 0, 1), onChange: newValue => {
                                                            if (newValue) {
                                                                setEstimatedTime(set(new Date(1970, 0, 1), {
                                                                    hours: newValue.getHours(),
                                                                    minutes: newValue.getMinutes(),
                                                                    seconds: newValue.getSeconds(),
                                                                }));
                                                            }
                                                            else
                                                                setEstimatedTime(new Date(1970, 0, 1));
                                                        }, renderInput: params => (_jsx(TextField, Object.assign({ required: !campaign.field_options ||
                                                                campaign.field_options.length === 0
                                                                ? false
                                                                : campaign.field_options.find(o => o.data ===
                                                                    "estimate" &&
                                                                    o.table ===
                                                                        "ticket_details").required }, params, { fullWidth: true }))) }) })) }))), (!campaign.field_options ||
                                                campaign.field_options.length === 0 ||
                                                (campaign.field_options.find(o => o.data === "progress" &&
                                                    o.table === "ticket_details") &&
                                                    campaign.field_options.find(o => o.data === "progress" &&
                                                        o.table === "ticket_details").visible)) && (_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsx(Typography, Object.assign({ variant: "body2", gutterBottom: true }, { children: t("tickets.ticket-attributes.progress") +
                                                            " (% " +
                                                            t("tickets.ticket-details.ticket-details-form.done") +
                                                            ")" +
                                                            (!campaign.field_options ||
                                                                campaign.field_options.length === 0
                                                                ? ""
                                                                : campaign.field_options.find(o => o.data === "progress" &&
                                                                    o.table === "ticket_details").required
                                                                    ? " *"
                                                                    : "") })), _jsx(InputSlider, { required: !campaign.field_options ||
                                                            campaign.field_options.length === 0
                                                            ? false
                                                            : campaign.field_options.find(o => o.data === "progress" &&
                                                                o.table === "ticket_details").required, value: progress, setValue: setProgress, min: 0, max: 100, step: 1 })] }))), (!campaign.field_options ||
                                                campaign.field_options.length === 0 ||
                                                (campaign.field_options.find(o => o.data === "notes" && o.table === "ticket_details") &&
                                                    campaign.field_options.find(o => o.data === "notes" &&
                                                        o.table === "ticket_details").visible)) && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) }))), (!campaign.field_options ||
                                                campaign.field_options.length === 0 ||
                                                (campaign.field_options.find(o => o.data === "notes" && o.table === "ticket_details") &&
                                                    campaign.field_options.find(o => o.data === "notes" &&
                                                        o.table === "ticket_details").visible)) && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputTextArea, { label: t("tickets.ticket-attributes.notes"), required: !campaign.field_options ||
                                                        campaign.field_options.length === 0
                                                        ? false
                                                        : campaign.field_options.find(o => o.data === "notes" &&
                                                            o.table === "ticket_details").required, value: notes, setValue: setNotes, rows: 3, autoRows: true }) })))] })) })) })) }))] })), _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Hidden, Object.assign({ mdDown: true }, { children: _jsx(Grid, { item: true, xs: 12, md: 4 }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    marginTop: 4,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                } }, { children: [ticket.is_draft && (_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(Button, Object.assign({ color: "inherit", startIcon: _jsx(PrevIcon, {}), onClick: () => {
                                                if (ticket.campaign.has_steps) {
                                                    navigate(tenancy.link("/tickets/" + ticket.id + "/information"));
                                                }
                                                else {
                                                    navigate(tenancy.link("/tickets/" + ticket.id + "/contact"));
                                                }
                                            } }, { children: t("form-component.back") })) }))), _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "secondary", loading: isSaving, disabled: !priority ||
                                            !status ||
                                            (campaign.field_options &&
                                                campaign.field_options.length > 0 &&
                                                campaign.field_options.find(o => o.data === "assignee_ids" &&
                                                    o.table === "ticket_details").required &&
                                                (!assignedUsersWithGroups ||
                                                    assignedUsersWithGroups.length === 0)) ||
                                            (campaign.field_options &&
                                                campaign.field_options.length > 0 &&
                                                campaign.field_options.find(o => o.data === "start_date_time" &&
                                                    o.table === "ticket_details").required &&
                                                startDate === null) ||
                                            (campaign.field_options &&
                                                campaign.field_options.length > 0 &&
                                                campaign.field_options.find(o => o.data === "due_date_time" &&
                                                    o.table === "ticket_details").required &&
                                                dueDate === null) ||
                                            (campaign.field_options &&
                                                campaign.field_options.length > 0 &&
                                                campaign.field_options.find(o => o.data === "estimate" &&
                                                    o.table === "ticket_details").required &&
                                                estimatedTime === null) ||
                                            (campaign.field_options &&
                                                campaign.field_options.length > 0 &&
                                                campaign.field_options.find(o => o.data === "progress" &&
                                                    o.table === "ticket_details").required &&
                                                progress === 0) ||
                                            (campaign.field_options &&
                                                campaign.field_options.length > 0 &&
                                                campaign.field_options.find(o => o.data === "notes" && o.table === "ticket_details").required &&
                                                notes === ""), onClick: () => createTicketDetail(), endIcon: ticket.is_draft ? _jsx(NextIcon, {}) : undefined, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: ticket.is_draft
                                            ? ticket.campaign.has_activity_fields
                                                ? t("form-component.next")
                                                : t("tickets.ticket-activities.ticket-activity-form.finish")
                                            : t("form-component.update") }))] })) }))] }))] })) }));
}
