import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import CreatedIcon from "@mui/icons-material/History";
import UpdatedIcon from "@mui/icons-material/Update";
import { colors } from "../../../helpers/colors";
export default function KnowledgeDetails({ knowledge }) {
    const { t } = useTranslation();
    return (_jsx(Box, Object.assign({ sx: {
            marginBottom: 2,
        } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: [knowledge.image_url && (_jsx(Box, Object.assign({ sx: {
                                marginBottom: 2,
                            } }, { children: _jsx("img", { src: knowledge.image_url, style: {
                                    maxHeight: "90px",
                                    maxWidth: "200px",
                                    objectFit: "contain",
                                }, alt: knowledge.title }) }))), (knowledge.is_email_template || knowledge.is_visible_on_dashboard) && (_jsxs(Box, Object.assign({ sx: {
                                marginBottom: 2,
                            } }, { children: [knowledge.is_email_template ? (_jsx(Box, Object.assign({ sx: {
                                        marginBottom: 1,
                                    } }, { children: _jsx(Chip, { size: "small", color: "default", label: t("knowledge.knowledge-attributes.email-template") }) }))) : null, knowledge.is_visible_on_dashboard ? (_jsx(Box, Object.assign({ sx: {
                                        marginBottom: 1,
                                    } }, { children: _jsx(Chip, { size: "small", color: "default", label: t("knowledge.knowledge-attributes.visible-on-dashboard") }) }))) : null] }))), _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                alignItems: "center",
                            } }, { children: [_jsx(Box, Object.assign({ sx: {
                                        display: "flex",
                                    } }, { children: _jsx(UpdatedIcon, { fontSize: "small" }) })), _jsx(Box, Object.assign({ sx: {
                                        display: "flex",
                                        paddingLeft: 1,
                                    } }, { children: _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("updated-at") +
                                            " " +
                                            new Date(Date.parse(knowledge.updated_at)).toLocaleDateString("en-GB") })) }))] })), _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                alignItems: "center",
                            } }, { children: [_jsx(Box, Object.assign({ sx: {
                                        display: "flex",
                                    } }, { children: _jsx(CreatedIcon, { fontSize: "small" }) })), _jsx(Box, Object.assign({ sx: {
                                        display: "flex",
                                        paddingLeft: 1,
                                    } }, { children: _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("created-at") +
                                            " " +
                                            new Date(Date.parse(knowledge.created_at)).toLocaleDateString("en-GB") })) }))] }))] })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, { children: _jsxs(Box, Object.assign({ sx: {
                                paddingX: 5,
                                paddingY: 4,
                                overflow: "hidden",
                            } }, { children: [knowledge.is_email_template ? (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ variant: "h6", gutterBottom: true }, { children: knowledge.caption })), _jsx(Divider, { sx: {
                                                marginY: 2,
                                            } })] })) : null, _jsx(Box, { sx: {
                                        a: {
                                            color: colors.primary.dark,
                                        },
                                    }, dangerouslySetInnerHTML: {
                                        __html: knowledge.content,
                                    } })] })) }) }))] })) })));
}
