import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { colors } from "../../../helpers/colors";
import { useTenancy } from "../../../services/tenancy";
export default function KnowledgeCards({ knowledge, isDashboard }) {
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const [isKnowledgeOpen, setIsKnowledgeOpen] = useState(false);
    const [selectedKnowledge, setSelectedKnowledge] = useState(null);
    return (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ container: true, spacing: 4, direction: "row" }, { children: knowledge.map(knowledgeCard => {
                    return (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: isDashboard ? 12 : 6, md: 4, xl: isDashboard ? 4 : 3, style: {
                            display: "flex",
                        } }, { children: _jsx(Card, Object.assign({ style: {
                                display: "inherit",
                                flexDirection: "column",
                                flexGrow: 1,
                                height: "100%",
                            } }, { children: _jsx(CardActionArea, Object.assign({ onClick: () => {
                                    if (isDashboard) {
                                        setSelectedKnowledge(knowledgeCard);
                                        setIsKnowledgeOpen(true);
                                    }
                                    else {
                                        navigate(tenancy.link("/knowledge/" + knowledgeCard.id));
                                    }
                                }, style: {
                                    display: "inherit",
                                    flexDirection: "column",
                                    flexGrow: 1,
                                    alignItems: "inherit",
                                } }, { children: _jsx(CardContent, { children: _jsxs(Box, Object.assign({ sx: {
                                            height: "160px",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                            gap: "8px",
                                        } }, { children: [knowledgeCard.image_url && (_jsx("img", { src: knowledgeCard.image_url, style: {
                                                    maxHeight: "90px",
                                                    maxWidth: "200px",
                                                    objectFit: "contain",
                                                }, alt: knowledgeCard.title })), _jsx(Typography, Object.assign({ variant: "subtitle1", sx: {
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    display: "-webkit-box",
                                                    WebkitLineClamp: "2",
                                                    WebkitBoxOrient: "vertical",
                                                } }, { children: knowledgeCard.title }))] })) }) })) })) }), knowledgeCard.id));
                }) })), _jsx(Dialog, Object.assign({ fullWidth: true, maxWidth: "lg", open: isKnowledgeOpen, onClose: () => {
                    setIsKnowledgeOpen(false);
                    setTimeout(() => setSelectedKnowledge(null), 150);
                } }, { children: _jsx(DialogContent, { children: _jsx(Box, Object.assign({ sx: {
                            paddingBottom: 2,
                            a: {
                                color: colors.primary.dark,
                            },
                        } }, { children: _jsx(Typography, { dangerouslySetInnerHTML: {
                                __html: (selectedKnowledge === null || selectedKnowledge === void 0 ? void 0 : selectedKnowledge.content) || "",
                            } }) })) }) }))] }));
}
