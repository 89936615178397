import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect, forwardRef, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { createStyles, withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { useTreeItem } from "@mui/lab/TreeItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import Skeleton from "@mui/material/Skeleton";
import BusinessIcon from "@mui/icons-material/Business";
import HomeIcon from "@mui/icons-material/Home";
import CampaignIcon from "@mui/icons-material/Campaign";
import TicketIcon from "@mui/icons-material/Chat";
import ContactIcon from "@mui/icons-material/AccountBox";
import KnowledgeIcon from "@mui/icons-material/Description";
import SettingIcon from "@mui/icons-material/Settings";
import AdministrationIcon from "@mui/icons-material/Language";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandNone from "@mui/icons-material/ChevronRight";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
import { usePrefs } from "../../../services/prefs";
import { useApiTenant } from "../../../api/api-tenant";
import { relatedParentGroups, flatGroups } from "../../../helpers/groups";
const CustomTreeItem = forwardRef((props, ref) => {
    const { 
    // @ts-ignore
    classes, 
    // @ts-ignore
    className, 
    // @ts-ignore
    label, 
    // @ts-ignore
    nodeId, 
    // @ts-ignore
    icon: iconProp, 
    // @ts-ignore
    expansionIcon, 
    // @ts-ignore
    displayIcon, } = props;
    const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection, } = useTreeItem(nodeId);
    const icon = iconProp || expansionIcon || displayIcon;
    const handleMouseDown = event => {
        preventSelection(event);
    };
    const handleExpansionClick = event => {
        handleExpansion(event);
    };
    const handleSelectionClick = event => {
        handleSelection(event);
    };
    return (_jsxs("div", Object.assign({ className: clsx(className, classes.root, {
            [classes.expanded]: expanded,
            [classes.selected]: selected,
            [classes.focused]: focused,
            [classes.disabled]: disabled,
        }), onMouseDown: handleMouseDown, 
        // @ts-ignore
        ref: ref }, { children: [_jsx("div", Object.assign({ onClick: handleExpansionClick, className: classes.iconContainer }, { children: icon })), _jsx(Typography, Object.assign({ onClick: handleSelectionClick, className: classes.label, variant: "body2" }, { children: label }))] })));
});
const StyledTreeItem = withStyles((theme) => createStyles({
    group: {
        marginLeft: 15,
        borderLeft: `1px dashed ${theme.palette.action.disabled}`,
    },
    label: {
        paddingTop: 8,
        paddingBottom: 8,
        fontSize: "0.875rem !important",
        lineHeight: "1.43 !important",
        paddingRight: "50px !important",
        wordBreak: "break-word",
    },
    disabled: {
        cursor: "default",
    },
}))((props) => _jsx(TreeItem, Object.assign({ ContentComponent: CustomTreeItem }, props)));
export default function Navigation() {
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const roles = useRoles();
    const prefs = usePrefs();
    const api = useApiTenant();
    const isDesktop = useMediaQuery("(min-width: 1200px)");
    const [isCampaignListOpen, setIsCampaignListOpen] = useState(true);
    const [campaigns, setCampaigns] = useState([]);
    const [openCampaignIds, setOpenCampaignIds] = useState([]);
    const [addedNewOpen, setAddedNewOpen] = useState(false);
    const [closedCampaign, setClosedCampaign] = useState(false);
    const [expandedGroupNodes, setExpandedGroupNodes] = useState([]);
    const [selectedGroupNode, setSelectedGroupNode] = useState({
        groupId: "",
        campaignId: "",
    });
    const [isCampaignsLoading, setIsCampaignsLoading] = useState(true);
    const [campaignId, setCampaignId] = useState(null);
    const campaignIdRef = useRef(campaignId);
    const openCampaignIdsRef = useRef(openCampaignIds);
    const refreshCampaignsTimer = useRef();
    useEffect(() => {
        setIsCampaignsLoading(true);
        if (!tenancy.client) {
            setIsCampaignsLoading(false);
            clearTimeout(refreshCampaignsTimer.current);
            return;
        }
        if (!roles.roleTenant)
            return;
        campaignIdRef.current = campaignId;
        clearTimeout(refreshCampaignsTimer.current);
        setOpenCampaignIds([]);
        openCampaignIdsRef.current = [];
        setExpandedGroupNodes([]);
        setSelectedGroupNode({ groupId: "", campaignId: "" });
        setAddedNewOpen(false);
        getCampaigns();
        return () => api.cancel();
    }, [tenancy.client, roles.roleTenant]);
    useEffect(() => {
        if (!isDesktop &&
            location.pathname.startsWith(tenancy.link("/campaigns/")) &&
            location.pathname.includes("/groups/")) {
            setCampaignId(location.pathname.split("/")[3]);
            if (!openCampaignIds.find(a => a === location.pathname.split("/")[3])) {
                let open = [...openCampaignIds];
                open.push(location.pathname.split("/")[3]);
                setOpenCampaignIds(open);
                openCampaignIdsRef.current = open;
                setAddedNewOpen(true);
            }
        }
    }, []);
    useEffect(() => {
        if (!tenancy.refreshCampaigns)
            return;
        clearTimeout(refreshCampaignsTimer.current);
        getCampaigns();
        if (openCampaignIds.length > 0)
            refreshCampaigns();
        tenancy.setRefreshCampaigns(false);
    }, [tenancy.refreshCampaigns]);
    useEffect(() => {
        if (location.pathname.includes("campaigns/")) {
            let newCampaignId = location.pathname.split("/")[3];
            if (newCampaignId === "new") {
                setCampaignId(null);
                return;
            }
            if (newCampaignId !== campaignId)
                setCampaignId(newCampaignId);
        }
        else {
            setCampaignId(null);
        }
        if (campaignId &&
            location.pathname.startsWith(tenancy.link("/campaigns/" + campaignId + "/groups/")) &&
            location.pathname.split("/")[5]) {
            if (!(expandedGroupNodes.find(a => a.groupId === location.pathname.split("/")[5].toString()) && expandedGroupNodes.find(a => a.campaignId === campaignId))) {
                let relatedParents = [];
                let campaignGroups = campaigns.find(b => b.id == campaignId).groups;
                let group = flatGroups(campaignGroups, 0, []).find(a => a.id.toString() == location.pathname.split("/")[5].toString());
                if (group) {
                    relatedParents = relatedParentGroups(group, flatGroups(campaignGroups, 0, []), relatedParents);
                    relatedParents.push(group);
                    let expanded = [...expandedGroupNodes];
                    if (relatedParents.length > 0) {
                        relatedParents.map(group => {
                            expanded.push({
                                campaignId: campaignId.toString(),
                                groupId: group.id.toString(),
                            });
                        });
                    }
                    setExpandedGroupNodes(expanded);
                }
            }
            if (!openCampaignIds.find(a => a === campaignId.toString())) {
                let open = [...openCampaignIds];
                open.push(campaignId.toString());
                setOpenCampaignIds(open);
                openCampaignIdsRef.current = open;
                setAddedNewOpen(true);
            }
            setSelectedGroupNode({
                groupId: location.pathname.split("/")[5],
                campaignId: location.pathname.split("/")[3],
            });
        }
        if (!location.pathname.startsWith(campaignId
            ? tenancy.link("/campaigns/" + campaignId + "/groups/")
            : tenancy.link("/groups/"))) {
            setSelectedGroupNode({ groupId: "", campaignId: "" });
        }
    }, [location]);
    useEffect(() => {
        if (!isCampaignListOpen || !tenancy.client)
            return;
        getCampaigns();
    }, [isCampaignListOpen]);
    useEffect(() => {
        if (!addedNewOpen && openCampaignIds.length > 0)
            return;
        if (openCampaignIds.length === 0) {
            clearTimeout(refreshCampaignsTimer.current);
            return;
        }
        clearTimeout(refreshCampaignsTimer.current);
        getCampaigns();
        refreshCampaigns();
    }, [addedNewOpen]);
    useEffect(() => {
        if (!closedCampaign || openCampaignIds.length > 0)
            return;
        clearTimeout(refreshCampaignsTimer.current);
        setClosedCampaign(false);
    }, [closedCampaign]);
    const getCampaigns = () => {
        api.GET_campaigns(10000, 1, undefined, undefined, "name", "asc", 1, (process.env.APP_ID !== "mcd" && roles.isAgent) ||
            (process.env.APP_ID === "mcd" && !roles.isAdmin)
            ? 1
            : undefined, openCampaignIdsRef.current.length > 0 ? openCampaignIdsRef.current : undefined, 1)
            .then(response => {
            let campaigns = response.data.campaigns;
            setCampaigns(campaigns);
            if (addedNewOpen)
                setAddedNewOpen(false);
            if (openCampaignIds.length === 0) {
                let expanded = [...expandedGroupNodes];
                campaigns.map(campaign => {
                    if (campaign.groups.length == 1) {
                        if (!expanded.find(g => g.campaignId === campaign.id &&
                            g.groupId === campaign.groups[0].id.toString())) {
                            expanded.push({
                                campaignId: campaign.id.toString(),
                                groupId: campaign.groups[0].id.toString(),
                            });
                        }
                    }
                });
                setExpandedGroupNodes(expanded);
            }
            setIsCampaignsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsCampaignsLoading(false);
            }
        });
    };
    const refreshCampaigns = () => {
        // @ts-ignore
        refreshCampaignsTimer.current = setTimeout(() => {
            getCampaigns();
            refreshCampaigns();
        }, 60 * 1000 * 5);
    };
    const treeItems = (groups) => {
        return groups
            .sort((a, b) => a.name.toString().localeCompare(b.name.toString()))
            .map((group, index) => {
            let children = null;
            if (group.groups && group.groups.length > 0) {
                children = treeItems(group.groups);
            }
            return (_jsxs(Box, Object.assign({ sx: {
                    position: "relative",
                    width: "100%",
                } }, { children: [_jsx(StyledTreeItem, { nodeId: group.id.toString(), label: group.name, children: children }, index), _jsx(Box, Object.assign({ style: {
                            position: "absolute",
                            top: "4px",
                            right: "8px",
                        } }, { children: group.ticket_in_progress_count > 0 && (_jsx(Tooltip, Object.assign({ arrow: true, placement: "right", 
                            // @ts-ignore
                            title: t("header.navigation.tickets-in-progress") }, { children: _jsx(Chip, { label: group.ticket_in_progress_count, size: "small", variant: "outlined", color: "default", sx: {
                                    border: process.env.APP_ID === "mcd"
                                        ? "1px solid rgba(0, 0, 0, 0.3) !important"
                                        : "inherit",
                                } }) }))) }))] }), index));
        });
    };
    const displayCampaignList = type => {
        return campaigns
            .filter(a => a.type === type)
            .map((campaign, key) => {
            return (_jsxs(Fragment, { children: [_jsxs(ListItem, Object.assign({ to: tenancy.link("/campaigns/" + campaign.id), selected: location.pathname === tenancy.link("/campaigns/" + campaign.id) ||
                            location.pathname.startsWith(tenancy.link("/campaigns/" + campaign.id + "/")), component: Link, button: true }, { children: [_jsx(ListItemText, { primary: campaign.name }), campaign.groups && campaign.groups.length > 0 && (_jsx(ListItemSecondaryAction, Object.assign({ style: { right: "2px" } }, { children: _jsx(IconButton, Object.assign({ size: "small", onClick: () => {
                                        if (!openCampaignIds.find(a => a === campaign.id.toString())) {
                                            let open = [...openCampaignIds];
                                            open.push(campaign.id.toString());
                                            setOpenCampaignIds(open);
                                            setAddedNewOpen(true);
                                            setCampaignId(campaign.id);
                                            campaignIdRef.current = campaign.id;
                                            openCampaignIdsRef.current = open;
                                        }
                                        else {
                                            let filterCampaignIds = openCampaignIds.filter(a => a !== campaign.id.toString());
                                            setOpenCampaignIds(filterCampaignIds);
                                            openCampaignIdsRef.current = filterCampaignIds;
                                            if (filterCampaignIds.length === 0)
                                                setClosedCampaign(true);
                                        }
                                    } }, { children: openCampaignIds.find(a => a === campaign.id.toString()) ? (_jsx(ExpandLess, {})) : (_jsx(ExpandMore, {})) })) })))] }), key), _jsx(Collapse, Object.assign({ in: !!openCampaignIds.find(a => a == campaign.id.toString()) &&
                            campaign.groups &&
                            campaign.groups.length > 0 }, { children: _jsx(List, Object.assign({ dense: true, style: { paddingTop: 0 } }, { children: campaign.groups && campaign.groups.length > 0 && (_jsx(Fragment, { children: _jsx(TreeView, Object.assign({ expanded: expandedGroupNodes
                                        .filter(a => a.campaignId === campaign.id.toString())
                                        .map(a => a.groupId), selected: selectedGroupNode.campaignId ===
                                        campaign.id.toString()
                                        ? selectedGroupNode.groupId
                                        : "", onNodeToggle: (event, nodeIds) => {
                                        let expanded = expandedGroupNodes.filter(a => a.campaignId !== campaign.id.toString() ||
                                            (a.campaignId === campaign.id.toString() &&
                                                nodeIds.find(id => id.toString() === a.groupId)));
                                        nodeIds.map(nodeId => {
                                            if (!expanded.find(a => a.campaignId ===
                                                campaign.id.toString() &&
                                                a.groupId === nodeId.toString())) {
                                                expanded.push({
                                                    campaignId: campaign.id.toString(),
                                                    groupId: nodeId.toString(),
                                                });
                                            }
                                        });
                                        setExpandedGroupNodes(expanded);
                                    }, onNodeSelect: (event, nodeId) => {
                                        setSelectedGroupNode({
                                            groupId: nodeId,
                                            campaignId: campaign.id,
                                        });
                                        navigate(campaign
                                            ? tenancy.link("/campaigns/" +
                                                campaign.id +
                                                "/groups/" +
                                                nodeId)
                                            : tenancy.link("/groups/" + nodeId));
                                    }, defaultExpandIcon: _jsx(ExpandMore, {}), defaultCollapseIcon: _jsx(ExpandLess, {}), defaultEndIcon: _jsx(ExpandNone, { color: "disabled" }) }, { children: treeItems(campaign.groups) })) })) })) }))] }, key));
        });
    };
    return (_jsxs(Fragment, { children: [!tenancy.isSubDomain && (_jsxs(Fragment, { children: [_jsx(List, { children: _jsxs(ListItem, Object.assign({ to: "/", selected: location.pathname === "/", component: Link, button: true }, { children: [_jsx(ListItemIcon, { children: _jsx(BusinessIcon, {}) }), _jsx(ListItemText, { children: t("header.navigation.partners") })] })) }), _jsx(Divider, { light: true })] })), tenancy.client && (_jsx(Fragment, { children: _jsxs(List, { children: [_jsxs(ListItem, Object.assign({ to: tenancy.link("/dashboard"), selected: location.pathname === tenancy.link("/dashboard"), component: Link, button: true }, { children: [_jsx(ListItemIcon, { children: _jsx(HomeIcon, {}) }), _jsx(ListItemText, { children: t("dashboard.dashboard") })] })), (process.env.APP_ID !== "mcd" && !roles.isAgent) ||
                            (process.env.APP_ID === "mcd" && roles.isAdmin) ? (_jsxs(ListItem, Object.assign({ to: tenancy.link("/campaigns"), selected: location.pathname.startsWith(tenancy.link("/campaigns")), component: Link, button: true }, { children: [_jsx(ListItemIcon, { children: _jsx(CampaignIcon, {}) }), _jsx(ListItemText, { children: t("campaigns.campaigns") }), _jsx(ListItemSecondaryAction, Object.assign({ style: { right: "2px" } }, { children: _jsx(IconButton, Object.assign({ size: "small", onClick: () => setIsCampaignListOpen(!isCampaignListOpen) }, { children: isCampaignListOpen ? _jsx(ExpandLess, {}) : _jsx(ExpandMore, {}) })) }))] }))) : (_jsxs(ListItem, { children: [_jsx(ListItemIcon, { children: _jsx(CampaignIcon, {}) }), _jsx(ListItemText, { children: t("campaigns.campaigns") })] })), _jsx(Collapse, Object.assign({ in: isCampaignListOpen }, { children: _jsx(List, Object.assign({ dense: true }, { children: !isCampaignsLoading && campaigns.length > 0 ? (_jsxs(Fragment, { children: [campaigns.find(a => a.type === "inbound") && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                                        paddingLeft: 2,
                                                        paddingBottom: 1,
                                                        paddingTop: 0.5,
                                                    } }, { children: _jsx(Typography, Object.assign({ variant: "caption", style: {
                                                            color: prefs.theme === "light"
                                                                ? "rgb(0, 0, 0, 0.54)"
                                                                : "rgb(255, 255, 255, 0.4)",
                                                        } }, { children: t("campaigns.campaign-attributes.types.inbound").toUpperCase() })) })), displayCampaignList("inbound"), campaigns.find(c => c.type !== "inbound") && (_jsx(Box, Object.assign({ sx: { paddingY: 2 } }, { children: _jsx(Divider, {}) })))] })), campaigns.find(a => a.type === "outbound") && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                                        paddingLeft: 2,
                                                        paddingBottom: 1,
                                                        paddingTop: 0.5,
                                                    } }, { children: _jsx(Typography, Object.assign({ variant: "caption", style: {
                                                            color: prefs.theme === "light"
                                                                ? "rgb(0, 0, 0, 0.54)"
                                                                : "rgb(255, 255, 255, 0.4)",
                                                        } }, { children: t("campaigns.campaign-attributes.types.outbound").toUpperCase() })) })), displayCampaignList("outbound"), campaigns.find(c => c.type !== "inbound" &&
                                                    c.type !== "outbound") && (_jsx(Box, Object.assign({ sx: { paddingY: 2 } }, { children: _jsx(Divider, {}) })))] })), campaigns.find(a => a.type === "contract") && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                                        paddingLeft: 2,
                                                        paddingBottom: 1,
                                                        paddingTop: 0.5,
                                                    } }, { children: _jsx(Typography, Object.assign({ variant: "caption", style: {
                                                            color: prefs.theme === "light"
                                                                ? "rgb(0, 0, 0, 0.54)"
                                                                : "rgb(255, 255, 255, 0.4)",
                                                        } }, { children: t("campaigns.campaign-attributes.types.contract").toUpperCase() })) })), displayCampaignList("contract"), campaigns.find(c => c.type !== "inbound" &&
                                                    c.type !== "outbound" &&
                                                    c.type !== "contract") && (_jsx(Box, Object.assign({ sx: { paddingY: 2 } }, { children: _jsx(Divider, {}) })))] })), campaigns.find(a => a.type === "survey") && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                                        paddingLeft: 2,
                                                        paddingBottom: 1,
                                                        paddingTop: 0.5,
                                                    } }, { children: _jsx(Typography, Object.assign({ variant: "caption", style: {
                                                            color: prefs.theme === "light"
                                                                ? "rgb(0, 0, 0, 0.54)"
                                                                : "rgb(255, 255, 255, 0.4)",
                                                        } }, { children: t("campaigns.campaign-attributes.types.survey").toUpperCase() })) })), displayCampaignList("survey"), campaigns.find(c => c.type !== "inbound" &&
                                                    c.type !== "outbound" &&
                                                    c.type !== "contract" &&
                                                    c.type !== "survey") && (_jsx(Box, Object.assign({ sx: { paddingY: 2 } }, { children: _jsx(Divider, {}) })))] }))] })) : isCampaignsLoading ? (_jsx(ListItem, { children: _jsx(ListItemText, { primary: _jsx(Skeleton, { animation: "wave" }) }) })) : (_jsx(ListItem, Object.assign({ disabled: true }, { children: _jsx(ListItemText, { primary: t("header.navigation.no-campaigns") }) }))) })) })), (roles.isAdmin || (process.env.APP_ID !== "mcd" && roles.isManager)) && (_jsxs(ListItem, Object.assign({ to: tenancy.link("/tickets"), selected: location.pathname.startsWith(tenancy.link("/tickets")), component: Link, button: true }, { children: [_jsx(ListItemIcon, { children: _jsx(TicketIcon, {}) }), _jsx(ListItemText, { children: t("tickets.tickets") })] }))), (roles.isAdmin || (process.env.APP_ID !== "mcd" && roles.isManager)) && (_jsxs(ListItem, Object.assign({ to: tenancy.link("/contacts"), selected: location.pathname.startsWith(tenancy.link("/contacts")), component: Link, button: true }, { children: [_jsx(ListItemIcon, { children: _jsx(ContactIcon, {}) }), _jsx(ListItemText, { children: t("contacts.contacts") })] }))), (process.env.APP_ID !== "mcd" ||
                            (process.env.APP_ID === "mcd" && roles.isAdmin)) && (_jsxs(ListItem, Object.assign({ to: tenancy.link("/knowledge"), selected: location.pathname.startsWith(tenancy.link("/knowledge")), component: Link, button: true }, { children: [_jsx(ListItemIcon, { children: _jsx(KnowledgeIcon, {}) }), _jsx(ListItemText, { children: t("knowledge.knowledge") })] }))), (roles.isAdmin || (process.env.APP_ID !== "mcd" && roles.isManager)) && (_jsxs(ListItem, Object.assign({ to: tenancy.link("/settings"), selected: location.pathname.startsWith(tenancy.link("/settings")), component: Link, button: true }, { children: [_jsx(ListItemIcon, { children: _jsx(SettingIcon, {}) }), _jsx(ListItemText, { children: t("settings") })] })))] }) })), !tenancy.isSubDomain && roles.isSuperAdmin && (_jsxs(Fragment, { children: [tenancy.client && _jsx(Divider, { light: true }), _jsx(List, { children: _jsxs(ListItem, Object.assign({ to: "/administration", selected: location.pathname.startsWith("/administration"), component: Link, button: true }, { children: [_jsx(ListItemIcon, { children: _jsx(AdministrationIcon, {}) }), _jsx(ListItemText, { children: t("pages.administration") })] })) })] }))] }));
}
