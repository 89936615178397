import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { differenceInSeconds, getSeconds, getMinutes, getHours, getDay, getMonth, getYear, sub, set, compareAsc, } from "date-fns";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ActivityIcon from "@mui/icons-material/Update";
import Divider from "@mui/material/Divider";
import Hidden from "@mui/material/Hidden";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import PrevIcon from "@mui/icons-material/NavigateBefore";
import NextIcon from "@mui/icons-material/NavigateNext";
import InputTextArea from "../inputs/input-textarea";
import InputText from "../inputs/input-text";
import { useAlert } from "../../../services/alert";
import { usePrefs } from "../../../services/prefs";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
import { useApiTenant } from "../../../api/api-tenant";
import { flatGroups } from "../../../helpers/groups";
import InputEditor from "../inputs/input-editor";
import InputCustomFields from "../inputs/input-custom-fields";
import EmailIcon from "@mui/icons-material/EmailOutlined";
export default function TicketActivityForm({ status, ticket, activityId, setEdit, modal, setUpdatedActivity, }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const roles = useRoles();
    const alert = useAlert();
    const prefs = usePrefs();
    const api = useApiTenant();
    const [campaign] = useState(ticket.campaign);
    const [activityGroups] = useState(ticket.campaign.activity_groups || []);
    const [incoming, setIncoming] = useState(status === "edit" && ticket.ticket_activities.find(e => e.id == activityId)
        ? ((_a = ticket.ticket_activities.find(e => e.id == activityId)) === null || _a === void 0 ? void 0 : _a.incoming_channel)
            ? ((_b = ticket.ticket_activities.find(e => e.id == activityId)) === null || _b === void 0 ? void 0 : _b.incoming_channel.type)
                ? (_c = ticket.ticket_activities.find(e => e.id == activityId)) === null || _c === void 0 ? void 0 : _c.incoming_channel.type
                : ""
            : ""
        : (prefs.ticketPhone && prefs.ticketPhone !== "") || (ticket.lead && ticket.is_draft)
            ? "phone"
            : "");
    const [outgoing, setOutgoing] = useState(status === "edit" && ticket.ticket_activities.find(e => e.id == activityId)
        ? ((_d = ticket.ticket_activities.find(e => e.id == activityId)) === null || _d === void 0 ? void 0 : _d.outgoing_channel)
            ? ((_e = ticket.ticket_activities.find(e => e.id == activityId)) === null || _e === void 0 ? void 0 : _e.outgoing_channel.type)
                ? (_f = ticket.ticket_activities.find(e => e.id == activityId)) === null || _f === void 0 ? void 0 : _f.outgoing_channel.type
                : ""
            : ""
        : "");
    const [incomingPhone, setIncomingPhone] = useState(status === "edit" && ticket.ticket_activities.find(e => e.id == activityId)
        ? ((_g = ticket.ticket_activities.find(e => e.id == activityId)) === null || _g === void 0 ? void 0 : _g.incoming_channel)
            ? ((_h = ticket.ticket_activities.find(e => e.id == activityId)) === null || _h === void 0 ? void 0 : _h.incoming_channel.type)
                ? ((_j = ticket.ticket_activities.find(e => e.id == activityId)) === null || _j === void 0 ? void 0 : _j.incoming_channel.type) === "phone"
                    ? (_k = ticket.ticket_activities.find(e => e.id == activityId)) === null || _k === void 0 ? void 0 : _k.incoming_channel.phone
                    : null
                : null
            : null
        : null);
    const [outgoingPhone, setOutgoingPhone] = useState(status === "edit" && ticket.ticket_activities.find(e => e.id == activityId)
        ? ((_l = ticket.ticket_activities.find(e => e.id == activityId)) === null || _l === void 0 ? void 0 : _l.outgoing_channel)
            ? ((_m = ticket.ticket_activities.find(e => e.id == activityId)) === null || _m === void 0 ? void 0 : _m.outgoing_channel.type)
                ? ((_o = ticket.ticket_activities.find(e => e.id == activityId)) === null || _o === void 0 ? void 0 : _o.outgoing_channel.type) === "phone"
                    ? (_p = ticket.ticket_activities.find(e => e.id == activityId)) === null || _p === void 0 ? void 0 : _p.outgoing_channel.phone
                    : null
                : null
            : null
        : null);
    const [incomingEmail, setIncomingEmail] = useState(status === "edit" && ticket.ticket_activities.find(e => e.id == activityId)
        ? ((_q = ticket.ticket_activities.find(e => e.id == activityId)) === null || _q === void 0 ? void 0 : _q.incoming_channel)
            ? ((_r = ticket.ticket_activities.find(e => e.id == activityId)) === null || _r === void 0 ? void 0 : _r.incoming_channel.type)
                ? ((_s = ticket.ticket_activities.find(e => e.id == activityId)) === null || _s === void 0 ? void 0 : _s.incoming_channel.type) === "email"
                    ? (_t = ticket.ticket_activities.find(e => e.id == activityId)) === null || _t === void 0 ? void 0 : _t.incoming_channel.email
                    : null
                : null
            : null
        : null);
    const [outgoingEmail, setOutgoingEmail] = useState(status === "edit" && ticket.ticket_activities.find(e => e.id == activityId)
        ? ((_u = ticket.ticket_activities.find(e => e.id == activityId)) === null || _u === void 0 ? void 0 : _u.outgoing_channel)
            ? ((_v = ticket.ticket_activities.find(e => e.id == activityId)) === null || _v === void 0 ? void 0 : _v.outgoing_channel.type)
                ? ((_w = ticket.ticket_activities.find(e => e.id == activityId)) === null || _w === void 0 ? void 0 : _w.outgoing_channel.type) === "email"
                    ? (_x = ticket.ticket_activities.find(e => e.id == activityId)) === null || _x === void 0 ? void 0 : _x.outgoing_channel.email
                    : null
                : null
            : null
        : null);
    const [incomingProfile, setIncomingProfile] = useState(status === "edit" && ticket.ticket_activities.find(e => e.id == activityId)
        ? ((_y = ticket.ticket_activities.find(e => e.id == activityId)) === null || _y === void 0 ? void 0 : _y.incoming_channel)
            ? ((_z = ticket.ticket_activities.find(e => e.id == activityId)) === null || _z === void 0 ? void 0 : _z.incoming_channel.type)
                ? ((_0 = ticket.ticket_activities.find(e => e.id == activityId)) === null || _0 === void 0 ? void 0 : _0.incoming_channel.type) === "social"
                    ? (_1 = ticket.ticket_activities.find(e => e.id == activityId)) === null || _1 === void 0 ? void 0 : _1.incoming_channel.social_profile
                    : ""
                : ""
            : ""
        : "");
    const [outgoingProfile, setOutgoingProfile] = useState(status === "edit" && ticket.ticket_activities.find(e => e.id == activityId)
        ? ((_2 = ticket.ticket_activities.find(e => e.id == activityId)) === null || _2 === void 0 ? void 0 : _2.outgoing_channel)
            ? ((_3 = ticket.ticket_activities.find(e => e.id == activityId)) === null || _3 === void 0 ? void 0 : _3.outgoing_channel.type)
                ? ((_4 = ticket.ticket_activities.find(e => e.id == activityId)) === null || _4 === void 0 ? void 0 : _4.outgoing_channel.type) === "social"
                    ? (_5 = ticket.ticket_activities.find(e => e.id == activityId)) === null || _5 === void 0 ? void 0 : _5.outgoing_channel.social_profile
                    : ""
                : ""
            : ""
        : "");
    const [incomingSocial, setIncomingSocial] = useState(status === "edit" && ticket.ticket_activities.find(e => e.id == activityId)
        ? ((_6 = ticket.ticket_activities.find(e => e.id == activityId)) === null || _6 === void 0 ? void 0 : _6.incoming_channel)
            ? ((_7 = ticket.ticket_activities.find(e => e.id == activityId)) === null || _7 === void 0 ? void 0 : _7.incoming_channel.type)
                ? ((_8 = ticket.ticket_activities.find(e => e.id == activityId)) === null || _8 === void 0 ? void 0 : _8.incoming_channel.type) === "social"
                    ? (_9 = ticket.ticket_activities.find(e => e.id == activityId)) === null || _9 === void 0 ? void 0 : _9.incoming_channel.social_channel
                    : ""
                : ""
            : ""
        : "");
    const [outgoingSocial, setOutgoingSocial] = useState(status === "edit" && ticket.ticket_activities.find(e => e.id == activityId)
        ? ((_10 = ticket.ticket_activities.find(e => e.id == activityId)) === null || _10 === void 0 ? void 0 : _10.outgoing_channel)
            ? ((_11 = ticket.ticket_activities.find(e => e.id == activityId)) === null || _11 === void 0 ? void 0 : _11.outgoing_channel.type)
                ? ((_12 = ticket.ticket_activities.find(e => e.id == activityId)) === null || _12 === void 0 ? void 0 : _12.outgoing_channel.type) === "social"
                    ? (_13 = ticket.ticket_activities.find(e => e.id == activityId)) === null || _13 === void 0 ? void 0 : _13.outgoing_channel.social_channel
                    : ""
                : ""
            : ""
        : "");
    const [activityType, setActivityType] = useState(null);
    const [workedTime, setWorkedTime] = useState(status === "edit" && ticket.ticket_activities.find(activity => activity.id == activityId)
        ? () => {
            var _a;
            let date = new Date(1970, 0, 1);
            date.setSeconds((_a = ticket.ticket_activities.find(activity => activity.id == activityId)) === null || _a === void 0 ? void 0 : _a.seconds_active);
            return date;
        }
        : null);
    const [workedMeasure, setWorkedMeasure] = useState(status === "edit" && ticket.ticket_activities.find(e => e.id == activityId)
        ? "manual"
        : prefs.ticketStartTime && ticket.is_draft
            ? "auto"
            : "manual");
    const [notes, setNotes] = useState(status === "edit" && ticket.ticket_activities.find(e => e.id == activityId)
        ? ticket.ticket_activities.find(e => e.id == activityId).notes !== null
            ? ticket.ticket_activities.find(e => e.id == activityId).notes
            : ""
        : "");
    const [customFields, setCustomFields] = useState(status === "edit" && ticket.ticket_activities.find(e => e.id == activityId)
        ? ticket.ticket_activities.find(e => e.id == activityId).custom_fields
        : []);
    const [usersWithGroups, setUsersWithGroups] = useState([]);
    const [recipientUsersWithGroups, setRecipientUsersWithGroups] = useState([]);
    const [isUsersLoading, setIsUsersLoading] = useState(true);
    const [allTemplates, setAllTemplates] = useState([]);
    const [areTemplatesLoading, setAreTemplatesLoading] = useState(true);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [emailSubject, setEmailSubject] = useState("");
    const [emailContent, setEmailContent] = useState("");
    const [disabledIncoming, setDisabledIncoming] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState(null);
    const [isLoadingCustomFields, setIsLoadingCustomFields] = useState(true);
    const [tenancyCustomFields, setTenancyCustomFields] = useState([]);
    const [disabledCustomFields, setDisabledCustomFields] = useState([]);
    let intervalId;
    useEffect(() => {
        if (campaign.activity_custom_field_options &&
            campaign.activity_custom_field_options.find(a => a.visible)) {
            api.GET_customFields("ticket_activities", 10000, 1, "index")
                .then(response => {
                setTenancyCustomFields(response.data.custom_fields);
                setIsLoadingCustomFields(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoadingCustomFields(false);
                }
            });
        }
        else {
            setIsLoadingCustomFields(false);
        }
        return () => api.cancel();
    }, []);
    useEffect(() => {
        if (incoming === "phone") {
            if (prefs.ticketPhone && prefs.ticketPhone !== "") {
                const foundPhone = ticket.contact.phones.find(phone => phone.phone_area.phone_country.phone +
                    phone.phone_area.phone +
                    phone.number ==
                    prefs.ticketPhone);
                if (foundPhone) {
                    setIncomingPhone(foundPhone);
                    setDisabledIncoming(true);
                }
            }
            else if (ticket.lead) {
                const phone = ticket.contact.phones.find(phone => phone.id === ticket.lead.phone_id);
                if (phone) {
                    setIncomingPhone(phone);
                    setDisabledIncoming(true);
                }
            }
            else if (ticket.contact.phones.length == 1) {
                setIncomingPhone(ticket.contact.phones[0]);
            }
        }
        if (incoming === "email") {
            if (ticket.contact.emails.length == 1) {
                setIncomingEmail(ticket.contact.emails[0]);
            }
        }
        if (outgoing === "phone") {
            if (ticket.contact.phones.length == 1) {
                setOutgoingPhone(ticket.contact.phones[0]);
            }
        }
        if (outgoing === "email") {
            if (ticket.contact.emails.length == 1) {
                setOutgoingEmail(ticket.contact.emails[0]);
            }
        }
    }, [incoming, outgoing]);
    useEffect(() => {
        if (workedMeasure !== "auto")
            return;
        intervalId = setInterval(() => {
            setWorkedTime(autoWorkedTime);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [workedTime]);
    useEffect(() => {
        if (status === "edit")
            return;
        if (workedMeasure === "auto")
            setWorkedTime(autoWorkedTime);
        else
            setWorkedTime(new Date(1970, 0, 1));
    }, [workedMeasure]);
    useEffect(() => {
        if (activityGroups && activityGroups.length > 0) {
            api.GET_groupUsers(undefined, 10000, 1, undefined, undefined, undefined, activityGroups.map(group => Number(group.id)), 1)
                .then(response => {
                var _a, _b;
                const usersWithGroups = [];
                response.data.tenant_users.map((user) => {
                    var _a;
                    const diff = [];
                    (_a = user.parent_groups) === null || _a === void 0 ? void 0 : _a.map(group => {
                        const groups = flatGroups([group], 0, []);
                        diff.push({ group: group, parentGroups: groups });
                    });
                    usersWithGroups.push({
                        user: user,
                        diffGroups: diff,
                    });
                });
                usersWithGroups.sort((a, b) => {
                    if (a.user.first_name < b.user.first_name)
                        return -1;
                    if (a.user.first_name > b.user.first_name)
                        return 1;
                    return 0;
                });
                if (ticket.contact.emails.length > 0) {
                    usersWithGroups.unshift({
                        user: {
                            id: ticket.contact.id,
                            global_id: "",
                            central_id: "",
                            first_name: `[${t("contacts.contact")}] ${(_a = ticket.contact.first_name) !== null && _a !== void 0 ? _a : ""}`,
                            last_name: (_b = ticket.contact.last_name) !== null && _b !== void 0 ? _b : "",
                            email: ticket.contact.emails.map(email => email.email).join(", "),
                            username: "",
                            phone: "",
                            password: "",
                            avatar_url: "",
                            lang: "",
                            dark_mode: "",
                            is_enabled: true,
                            is_sso: false,
                            roles: [],
                            custom_fields: [],
                            groups: [],
                            created_at: "",
                            updated_at: "",
                            deleted_at: "",
                        },
                        diffGroups: [],
                    });
                }
                setUsersWithGroups(usersWithGroups);
                setIsUsersLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error))
                    console.error(error);
            });
        }
        else {
            api.GET_users(10000, 1, "first_name", undefined, undefined, undefined, undefined, undefined, 1)
                .then(response => {
                var _a, _b;
                const usersWithGroups = [];
                response.data.tenant_users.map((user) => {
                    var _a;
                    const diff = [];
                    (_a = user.parent_groups) === null || _a === void 0 ? void 0 : _a.map(group => {
                        const groups = flatGroups([group], 0, []);
                        diff.push({ group: group, parentGroups: groups });
                    });
                    usersWithGroups.push({
                        user: user,
                        diffGroups: diff,
                    });
                });
                usersWithGroups.sort((a, b) => {
                    if (a.user.first_name < b.user.first_name)
                        return -1;
                    if (a.user.first_name > b.user.first_name)
                        return 1;
                    return 0;
                });
                if (ticket.contact.emails.length > 0) {
                    usersWithGroups.unshift({
                        user: {
                            id: ticket.contact.id,
                            global_id: "",
                            central_id: "",
                            first_name: `[${t("contacts.contact")}] ${(_a = ticket.contact.first_name) !== null && _a !== void 0 ? _a : ""}`,
                            last_name: (_b = ticket.contact.last_name) !== null && _b !== void 0 ? _b : "",
                            email: ticket.contact.emails.map(email => email.email).join(", "),
                            username: "",
                            phone: "",
                            password: "",
                            avatar_url: "",
                            lang: "",
                            dark_mode: "",
                            is_enabled: true,
                            is_sso: false,
                            roles: [],
                            custom_fields: [],
                            groups: [],
                            created_at: "",
                            updated_at: "",
                            deleted_at: "",
                        },
                        diffGroups: [],
                    });
                }
                setUsersWithGroups(usersWithGroups);
                setIsUsersLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error))
                    console.error(error);
            });
        }
        return () => {
            api.cancel();
        };
    }, []);
    useEffect(() => {
        api.GET_knowledgeAll(10000, undefined, "id", "asc", undefined, 1, 0)
            .then(response => {
            setAllTemplates(response.data.knowledge.sort((a, b) => a.title.localeCompare(b.title)));
        })
            .catch(error => {
            console.error(error);
        })
            .finally(() => {
            setAreTemplatesLoading(false);
        });
    }, []);
    const autoWorkedTime = () => {
        if (!prefs.ticketStartTime)
            return null;
        return sub(new Date(), {
            years: getYear(new Date(prefs.ticketStartTime)),
            months: getMonth(new Date(prefs.ticketStartTime)),
            days: getDay(new Date(prefs.ticketStartTime)),
            hours: getHours(new Date(prefs.ticketStartTime)),
            minutes: getMinutes(new Date(prefs.ticketStartTime)),
            seconds: getSeconds(new Date(prefs.ticketStartTime)),
        });
    };
    const createTicketActivity = () => {
        setIsSaving(true);
        setIsError(false);
        if (modal &&
            setUpdatedActivity &&
            tenancyCustomFields.length > 0 &&
            campaign.activity_custom_field_options &&
            campaign.activity_custom_field_options.length > 0 &&
            campaign.activity_custom_field_options.find(a => a.visible)) {
            setIsLoadingCustomFields(true);
        }
        const incomingChannel = {
            type: incoming,
            contact_email_id: incomingEmail ? incomingEmail.id : undefined,
            phone_id: incomingPhone ? incomingPhone.id : undefined,
            social_channel: incoming === "social" ? incomingSocial : undefined,
            social_profile: incomingProfile ? incomingProfile : undefined,
        };
        const outgoingChannel = {
            type: outgoing,
            contact_email_id: outgoingEmail ? outgoingEmail.id : undefined,
            phone_id: outgoingPhone ? outgoingPhone.id : undefined,
            social_channel: outgoingSocial ? outgoingSocial : undefined,
            social_profile: outgoingProfile ? outgoingProfile : undefined,
        };
        let workedTimeInSeconds = 0;
        if (workedMeasure === "auto" && prefs.ticketStartTime) {
            workedTimeInSeconds = differenceInSeconds(new Date(), new Date(prefs.ticketStartTime));
        }
        else if (workedTime) {
            workedTimeInSeconds = differenceInSeconds(workedTime, set(new Date(1970, 0, 1), {
                hours: 0,
                minutes: 0,
                seconds: 0,
                milliseconds: 0,
            }));
        }
        const isTimeVisible = !campaign.field_options ||
            campaign.field_options.length === 0 ||
            (campaign.field_options.find(o => o.data === "seconds_active" && o.table === "ticket_activities") &&
                campaign.field_options.find(o => o.data === "seconds_active" && o.table === "ticket_activities").visible);
        api.CREATE_ticketActivity(ticket.id, workedMeasure === "manual", isTimeVisible ? workedTimeInSeconds : undefined, activityType ? activityType.id : undefined, incomingChannel.type !== "" ? incomingChannel : undefined, outgoingChannel.type !== "" ? outgoingChannel : undefined, notes.length > 0 ? notes : undefined, recipientUsersWithGroups.length > 0
            ? recipientUsersWithGroups
                .filter(group => group.user.global_id !== "")
                .map(group => group.user.id)
            : undefined, emailSubject, emailContent, customFields === null || customFields === void 0 ? void 0 : customFields.sort((a, b) => a.index - b.index), recipientUsersWithGroups.some(group => group.user.global_id === ""))
            .then(() => {
            setIsSaving(false);
            if (modal && setUpdatedActivity) {
                setUpdatedActivity(true);
                setIncoming("");
                setOutgoing("");
                setIncomingPhone(null);
                setOutgoingPhone(null);
                setIncomingEmail(null);
                setOutgoingEmail(null);
                setIncomingProfile("");
                setOutgoingProfile("");
                setIncomingSocial("");
                setOutgoingSocial("");
                setActivityType(null);
                setWorkedTime(null);
                setWorkedMeasure("manual");
                setNotes("");
                setRecipientUsersWithGroups([]);
                setSelectedTemplate(null);
                setEmailSubject("");
                setEmailContent("");
                setCustomFields([]);
                setIsLoadingCustomFields(false);
            }
            else if (ticket.is_draft) {
                if (roles.isAgent) {
                    alert.show(t("tickets.ticket-activities.ticket-activity-form.ticket-created"), "success");
                    if (campaign.contract_campaign_id) {
                        setOpenDialog(true);
                        if (intervalId)
                            clearInterval(intervalId);
                    }
                    else {
                        prefs.setTicketStartTime(null);
                        prefs.setTicketPhone(null);
                        if (campaign.type === "contract")
                            navigate(tenancy.link("/campaigns/" + campaign.id));
                        else
                            navigate(tenancy.link("/campaigns/" + campaign.id + "/new-ticket"));
                    }
                }
                else {
                    prefs.setTicketStartTime(null);
                    prefs.setTicketPhone(null);
                    alert.show(t("tickets.ticket-activities.ticket-activity-form.ticket-created"), "success");
                    navigate(tenancy.link("/campaigns/" + campaign.id));
                }
            }
            else {
                prefs.setTicketStartTime(null);
                prefs.setTicketPhone(null);
                alert.show(t("tickets.ticket-activities.ticket-activity-form.activity-added"), "success");
                navigate(tenancy.link("/tickets/" + ticket.id));
            }
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            if (error.response && error.response.data && error.response.data.errors) {
                setErrors(error.response.data.errors);
                console.error(error.response);
            }
            alert.show(t("tickets.ticket-activities.ticket-activity-form.creating-activity-error"), "error");
        });
    };
    const updateTicketActivity = () => {
        if (!activityId || !workedTime)
            return;
        setIsSaving(true);
        setIsError(false);
        const incomingChannel = {
            type: incoming,
            contact_email_id: incomingEmail ? incomingEmail.id : undefined,
            phone_id: incomingPhone ? incomingPhone.id : undefined,
            social_channel: incoming === "social" ? incomingSocial : undefined,
            social_profile: incomingProfile ? incomingProfile : undefined,
        };
        const outgoingChannel = {
            type: outgoing,
            contact_email_id: outgoingEmail ? outgoingEmail.id : undefined,
            phone_id: outgoingPhone ? outgoingPhone.id : undefined,
            social_channel: outgoingSocial ? outgoingSocial : undefined,
            social_profile: outgoingProfile ? outgoingProfile : undefined,
        };
        let workedTimeInSeconds = differenceInSeconds(workedTime, set(new Date(1970, 0, 1), {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
        }));
        api.UPDATE_ticketActivity(activityId, ticket.id, activityType ? activityType.id : undefined, workedMeasure === "manual", workedTimeInSeconds, incomingChannel, outgoingChannel, notes)
            .then(() => {
            setIsSaving(false);
            alert.show(t("tickets.ticket-activities.ticket-activity-form.activity-updated"), "success");
            if (setEdit)
                setEdit("");
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            if (error.response && error.response.data && error.response.data.errors) {
                setErrors(error.response.data.errors);
                console.error(error.response);
            }
            alert.show(t("tickets.ticket-activities.ticket-activity-form.updating-activity-error"), "error");
        });
    };
    const onTemplateChange = (event, value) => {
        setSelectedTemplate(value);
        if (value) {
            setEmailSubject(value.caption);
            setEmailContent(value.content);
        }
        else {
            setEmailSubject("");
            setEmailContent("");
        }
    };
    return (_jsxs(Fragment, { children: [(!campaign.field_options ||
                campaign.field_options.length === 0 ||
                campaign.field_options
                    .filter(o => o.table === "ticket_activities")
                    .filter(o => o.data !== "recipient_ids")
                    .some(o => o.visible)) && (_jsx(Box, Object.assign({ sx: {
                    marginBottom: 4,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [status === "new" && !modal && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    position: "sticky",
                                    top: "100px",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(ActivityIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("tickets.ticket-activities.activity") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("tickets.ticket-activities.ticket-activity") }))] })] })) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, md: status === "new" && !modal ? 8 : 12 }, { children: _jsx(Paper, Object.assign({ component: "form" }, { children: _jsx(Box, Object.assign({ sx: {
                                        padding: 4,
                                    } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [(!campaign.field_options ||
                                                campaign.field_options.length === 0 ||
                                                (campaign.field_options.find(o => o.data === "incoming_channel" &&
                                                    o.table === "ticket_activities") &&
                                                    campaign.field_options.find(o => o.data === "incoming_channel" &&
                                                        o.table === "ticket_activities").visible)) && (_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: t("tickets.ticket-activities.ticket-activity-form.incoming-channel") +
                                                                    (!campaign.field_options ||
                                                                        campaign.field_options.length === 0
                                                                        ? ""
                                                                        : campaign.field_options.find(o => o.data ===
                                                                            "incoming_channel" &&
                                                                            o.table ===
                                                                                "ticket_activities").required
                                                                            ? " *"
                                                                            : "") }), _jsxs(Select, Object.assign({ label: t("tickets.ticket-activities.ticket-activity-form.incoming-channel") +
                                                                    (!campaign.field_options ||
                                                                        campaign.field_options.length === 0
                                                                        ? ""
                                                                        : campaign.field_options.find(o => o.data ===
                                                                            "incoming_channel" &&
                                                                            o.table ===
                                                                                "ticket_activities").required
                                                                            ? " *"
                                                                            : ""), required: !campaign.field_options ||
                                                                    campaign.field_options.length === 0
                                                                    ? false
                                                                    : campaign.field_options.find(o => o.data ===
                                                                        "incoming_channel" &&
                                                                        o.table ===
                                                                            "ticket_activities").required, value: incoming, disabled: disabledIncoming, onChange: event => {
                                                                    setIncoming(event.target
                                                                        .value);
                                                                } }, { children: [_jsx(MenuItem, Object.assign({ value: "" }, { children: t("tickets.ticket-activities.ticket-activity-form.channel-types.none") })), _jsx(MenuItem, Object.assign({ value: "phone" }, { children: t("tickets.ticket-activities.ticket-activity-form.channel-types.phone") })), _jsx(MenuItem, Object.assign({ value: "email" }, { children: t("tickets.ticket-activities.ticket-activity-form.channel-types.email") })), _jsx(MenuItem, Object.assign({ value: "social" }, { children: t("tickets.ticket-activities.ticket-activity-form.channel-types.social") })), _jsx(MenuItem, Object.assign({ value: "post" }, { children: t("tickets.ticket-activities.ticket-activity-form.channel-types.post") })), _jsx(MenuItem, Object.assign({ value: "personally" }, { children: t("tickets.ticket-activities.ticket-activity-form.channel-types.personally") }))] }))] })), incoming === "phone" && (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                                                                marginTop: 4,
                                                            } }, { children: _jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(Autocomplete, { options: ticket.contact.phones, getOptionLabel: phone => phone.phone_area
                                                                        .phone_country.phone !==
                                                                        "00"
                                                                        ? phone.phone_area
                                                                            .phone_country
                                                                            .phone +
                                                                            " " +
                                                                            phone.phone_area
                                                                                .phone +
                                                                            " " +
                                                                            phone.number.substring(0, 4) +
                                                                            " " +
                                                                            phone.number.substring(4, phone.number
                                                                                .length)
                                                                        : phone.number, isOptionEqualToValue: (option, value) => option.id === value.id, value: incomingPhone, onChange: (event, value) => {
                                                                        setIncomingPhone(value);
                                                                    }, disabled: disabledIncoming, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("tickets.ticket-activities.ticket-activity-form.incoming-contact-phone"), required: incoming === "phone", fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: params
                                                                                .InputProps
                                                                                .endAdornment }) }))) }) })) })) })), incoming === "email" && (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                                                                marginTop: 4,
                                                            } }, { children: _jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(Autocomplete, { options: ticket.contact.emails, getOptionLabel: email => email.email, isOptionEqualToValue: (option, value) => option.id === value.id, value: incomingEmail, onChange: (event, value) => {
                                                                        setIncomingEmail(value);
                                                                    }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("tickets.ticket-activities.ticket-activity-form.incoming-contact-email"), required: incoming === "email", fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: params
                                                                                .InputProps
                                                                                .endAdornment }) }))) }) })) })) })), incoming === "social" && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                                                    marginTop: 4,
                                                                } }, { children: _jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: t("tickets.ticket-activities.ticket-activity-form.social-channel") + " *" }), _jsxs(Select, Object.assign({ label: t("tickets.ticket-activities.ticket-activity-form.social-channel") + " *", value: incomingSocial, required: incoming === "social", onChange: event => {
                                                                                setIncomingSocial(event.target
                                                                                    .value);
                                                                            } }, { children: [_jsx(MenuItem, Object.assign({ value: "facebook" }, { children: "Facebook" })), _jsx(MenuItem, Object.assign({ value: "instagram" }, { children: "Instagram" })), _jsx(MenuItem, Object.assign({ value: "twitter" }, { children: "Twitter" })), _jsx(MenuItem, Object.assign({ value: "tiktok" }, { children: "TikTok" })), _jsx(MenuItem, Object.assign({ value: "linkedin" }, { children: "LinkedIn" })), _jsx(MenuItem, Object.assign({ value: "whatsapp" }, { children: "WhatsApp" })), _jsx(MenuItem, Object.assign({ value: "viber" }, { children: "Viber" })), _jsx(MenuItem, Object.assign({ value: "telegram" }, { children: "Telegram" })), _jsx(MenuItem, Object.assign({ value: "signal" }, { children: "Signal" })), _jsx(MenuItem, Object.assign({ value: "skype" }, { children: "Skype" })), _jsx(MenuItem, Object.assign({ value: "webchat" }, { children: "Web chat" }))] }))] })) })), _jsx(Box, Object.assign({ sx: {
                                                                    marginTop: 4,
                                                                } }, { children: _jsx(InputText, { label: t("tickets.ticket-activities.ticket-activity-form.social-handle") + " *", value: incomingProfile, required: incoming === "social", setValue: value => setIncomingProfile(value) }) }))] }))] }))), (!campaign.field_options ||
                                                campaign.field_options.length === 0 ||
                                                (campaign.field_options.find(o => o.data === "outgoing_channel" &&
                                                    o.table === "ticket_activities") &&
                                                    campaign.field_options.find(o => o.data === "outgoing_channel" &&
                                                        o.table === "ticket_activities").visible)) && (_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: t("tickets.ticket-activities.ticket-activity-form.outgoing-channel") +
                                                                    (!campaign.field_options ||
                                                                        campaign.field_options.length === 0
                                                                        ? ""
                                                                        : campaign.field_options.find(o => o.data ===
                                                                            "outgoing_channel" &&
                                                                            o.table ===
                                                                                "ticket_activities").required
                                                                            ? " *"
                                                                            : "") }), _jsxs(Select, Object.assign({ label: t("tickets.ticket-activities.ticket-activity-form.outgoing-channel") +
                                                                    (!campaign.field_options ||
                                                                        campaign.field_options.length === 0
                                                                        ? ""
                                                                        : campaign.field_options.find(o => o.data ===
                                                                            "outgoing_channel" &&
                                                                            o.table ===
                                                                                "ticket_activities").required
                                                                            ? " *"
                                                                            : ""), value: outgoing, onChange: event => {
                                                                    setOutgoing(event.target
                                                                        .value);
                                                                }, required: !campaign.field_options ||
                                                                    campaign.field_options.length === 0
                                                                    ? false
                                                                    : campaign.field_options.find(o => o.data ===
                                                                        "outgoing_channel" &&
                                                                        o.table ===
                                                                            "ticket_activities").required }, { children: [_jsx(MenuItem, Object.assign({ value: "" }, { children: t("tickets.ticket-activities.ticket-activity-form.channel-types.none") })), _jsx(MenuItem, Object.assign({ value: "phone" }, { children: t("tickets.ticket-activities.ticket-activity-form.channel-types.phone") })), _jsx(MenuItem, Object.assign({ value: "email" }, { children: t("tickets.ticket-activities.ticket-activity-form.channel-types.email") })), _jsx(MenuItem, Object.assign({ value: "social" }, { children: t("tickets.ticket-activities.ticket-activity-form.channel-types.social") })), _jsx(MenuItem, Object.assign({ value: "post" }, { children: t("tickets.ticket-activities.ticket-activity-form.channel-types.post") })), _jsx(MenuItem, Object.assign({ value: "personally" }, { children: t("tickets.ticket-activities.ticket-activity-form.channel-types.personally") }))] }))] })), outgoing === "phone" && (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                                                                marginTop: 4,
                                                            } }, { children: _jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(Autocomplete, { options: ticket.contact.phones, getOptionLabel: phone => phone.phone_area
                                                                        .phone_country.phone !==
                                                                        "00"
                                                                        ? phone.phone_area
                                                                            .phone_country
                                                                            .phone +
                                                                            " " +
                                                                            phone.phone_area
                                                                                .phone +
                                                                            " " +
                                                                            phone.number.substring(0, 4) +
                                                                            " " +
                                                                            phone.number.substring(4, phone.number
                                                                                .length)
                                                                        : phone.number, isOptionEqualToValue: (option, value) => option.id === value.id, value: outgoingPhone, onChange: (event, value) => {
                                                                        setOutgoingPhone(value);
                                                                    }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("tickets.ticket-activities.ticket-activity-form.outgoing-contact-phone"), required: outgoing === "phone", fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: params
                                                                                .InputProps
                                                                                .endAdornment }) }))) }) })) })) })), outgoing === "email" && (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                                                                marginTop: 4,
                                                            } }, { children: _jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(Autocomplete, { options: ticket.contact.emails, getOptionLabel: email => email.email, isOptionEqualToValue: (option, value) => option.id === value.id, value: outgoingEmail, onChange: (event, value) => {
                                                                        setOutgoingEmail(value);
                                                                    }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("tickets.ticket-activities.ticket-activity-form.outgoing-contact-email"), required: outgoing === "email", fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: params
                                                                                .InputProps
                                                                                .endAdornment }) }))) }) })) })) })), outgoing === "social" && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                                                    marginTop: 4,
                                                                } }, { children: _jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: t("tickets.ticket-activities.ticket-activity-form.social-channel") + " *" }), _jsxs(Select, Object.assign({ label: t("tickets.ticket-activities.ticket-activity-form.social-channel") + " *", value: outgoingSocial, required: outgoing === "social", onChange: event => {
                                                                                setOutgoingSocial(event.target
                                                                                    .value);
                                                                            } }, { children: [_jsx(MenuItem, Object.assign({ value: "facebook" }, { children: "Facebook" })), _jsx(MenuItem, Object.assign({ value: "instagram" }, { children: "Instagram" })), _jsx(MenuItem, Object.assign({ value: "twitter" }, { children: "Twitter" })), _jsx(MenuItem, Object.assign({ value: "linkedin" }, { children: "LinkedIn" })), _jsx(MenuItem, Object.assign({ value: "tiktok" }, { children: "TikTok" })), _jsx(MenuItem, Object.assign({ value: "whatsapp" }, { children: "WhatsApp" })), _jsx(MenuItem, Object.assign({ value: "viber" }, { children: "Viber" })), _jsx(MenuItem, Object.assign({ value: "telegram" }, { children: "Telegram" })), _jsx(MenuItem, Object.assign({ value: "signal" }, { children: "Signal" })), _jsx(MenuItem, Object.assign({ value: "skype" }, { children: "Skype" })), _jsx(MenuItem, Object.assign({ value: "webchat" }, { children: "Web chat" }))] }))] })) })), _jsx(Box, Object.assign({ sx: {
                                                                    marginTop: 4,
                                                                } }, { children: _jsx(InputText, { label: t("tickets.ticket-activities.ticket-activity-form.social-handle") + " *", value: outgoingProfile, required: outgoing === "social", setValue: value => {
                                                                        setOutgoingProfile(value);
                                                                    } }) }))] }))] }))), (!campaign.field_options ||
                                                campaign.field_options.length === 0 ||
                                                (campaign.field_options.find(o => o.data === "incoming_channel" &&
                                                    o.table === "ticket_activities") &&
                                                    campaign.field_options.find(o => o.data === "incoming_channel" &&
                                                        o.table === "ticket_activities").visible) ||
                                                (campaign.field_options.find(o => o.data === "outgoing_channel" &&
                                                    o.table === "ticket_activities") &&
                                                    campaign.field_options.find(o => o.data === "outgoing_channel" &&
                                                        o.table === "ticket_activities").visible)) && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) }))), (!campaign.field_options ||
                                                campaign.field_options.length === 0 ||
                                                (campaign.field_options.find(o => o.data === "seconds_active" &&
                                                    o.table === "ticket_activities") &&
                                                    campaign.field_options.find(o => o.data === "seconds_active" &&
                                                        o.table === "ticket_activities").visible)) && (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: 4, sm: 2 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                                display: "flex",
                                                                justifyContent: "flex-center",
                                                                alignContent: "center",
                                                                flexDirection: "column",
                                                                textAlign: "center",
                                                            } }, { children: [_jsx(Button, Object.assign({ size: "small", type: "button", disabled: status === "edit" ||
                                                                        prefs.ticketStartTime === null, onClick: () => {
                                                                        setWorkedMeasure(workedMeasure === "auto"
                                                                            ? "manual"
                                                                            : "auto");
                                                                    } }, { children: workedMeasure === "auto"
                                                                        ? t("tickets.ticket-activities.ticket-activity-form.auto")
                                                                        : t("tickets.ticket-activities.ticket-activity-form.manual") })), _jsx(Typography, Object.assign({ variant: "body2", color: "textSecondary" }, { children: t("tickets.ticket-activities.ticket-activity-form.tracking") }))] })) })), _jsx(Grid, Object.assign({ item: true, xs: 8, sm: 4 }, { children: _jsx(LocalizationProvider
                                                        // @ts-ignore
                                                        , Object.assign({ 
                                                            // @ts-ignore
                                                            dateAdapter: AdapterDateFns }, { children: _jsx(TimePicker, { ampm: false, disabled: workedMeasure === "auto", openTo: "hours", views: ["hours", "minutes", "seconds"], inputFormat: "HH:mm:ss", mask: "__:__:__", label: t("tickets.ticket-activities.ticket-activity-form.worked-time"), value: workedTime
                                                                    ? workedTime
                                                                    : new Date(1970, 0, 1), onChange: newValue => {
                                                                    if (newValue) {
                                                                        setWorkedTime(set(new Date(1970, 0, 1), {
                                                                            hours: newValue.getHours(),
                                                                            minutes: newValue.getMinutes(),
                                                                            seconds: newValue.getSeconds(),
                                                                        }));
                                                                    }
                                                                    else
                                                                        setWorkedTime(new Date(1970, 0, 1));
                                                                }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { required: !campaign.field_options ||
                                                                        campaign.field_options
                                                                            .length === 0
                                                                        ? false
                                                                        : campaign.field_options.find(o => o.data ===
                                                                            "seconds_active" &&
                                                                            o.table ===
                                                                                "ticket_activities").required, fullWidth: true }))) }) })) }))] })), (!campaign.field_options ||
                                                campaign.field_options.length === 0 ||
                                                (campaign.field_options.find(o => o.data === "ticket_activity_type_id" &&
                                                    o.table === "ticket_activities") &&
                                                    campaign.field_options.find(o => o.data === "ticket_activity_type_id" &&
                                                        o.table === "ticket_activities").visible)) && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(Autocomplete, { options: campaign === null || campaign === void 0 ? void 0 : campaign.ticket_activities, getOptionLabel: activity => activity.name, isOptionEqualToValue: (option, value) => option.id === value.id, value: activityType, onChange: (event, value) => {
                                                            setActivityType(value);
                                                        }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("tickets.ticket-activities.activity"), required: !campaign.field_options ||
                                                                campaign.field_options
                                                                    .length === 0
                                                                ? false
                                                                : campaign.field_options.find(o => o.data ===
                                                                    "ticket_activity_type_id" &&
                                                                    o.table ===
                                                                        "ticket_activities").required }))) }) })) }))), (!campaign.field_options ||
                                                campaign.field_options.length === 0 ||
                                                (campaign.field_options.find(o => o.data === "notes" &&
                                                    o.table === "ticket_activities") &&
                                                    campaign.field_options.find(o => o.data === "notes" &&
                                                        o.table === "ticket_activities").visible)) && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputTextArea, { label: t("tickets.ticket-activities.ticket-activity-form.message"), required: !campaign.field_options ||
                                                        campaign.field_options.length === 0
                                                        ? false
                                                        : campaign.field_options.find(o => o.data === "notes" &&
                                                            o.table ===
                                                                "ticket_activities").required, value: notes, setValue: setNotes, rows: 3, autoRows: true }) }))), status === "edit" && (_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Hidden, Object.assign({ mdDown: true }, { children: _jsx(Grid, { item: true, xs: 12, md: 4 }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsxs(Box, Object.assign({ sx: {
                                                                marginY: 2,
                                                                marginRight: 2,
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                            } }, { children: [_jsx(Box, Object.assign({ sx: { paddingRight: 2 } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "inherit", loading: isSaving, disabled: false, onClick: () => setEdit(""), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("cancel") })) })), _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "secondary", loading: isSaving, disabled: (campaign.field_options &&
                                                                        campaign.field_options.length >
                                                                            0 &&
                                                                        campaign.field_options.find(o => o.data ===
                                                                            "ticket_activity_type_id" &&
                                                                            o.table ===
                                                                                "ticket_activities").required &&
                                                                        activityType === null) ||
                                                                        (campaign.field_options &&
                                                                            campaign.field_options.length >
                                                                                0 &&
                                                                            campaign.field_options.find(o => o.data ===
                                                                                "seconds_active" &&
                                                                                o.table ===
                                                                                    "ticket_activities").required &&
                                                                            (!workedTime ||
                                                                                compareAsc(workedTime, new Date(1970, 0, 1)) === 0)) ||
                                                                        (incoming === "email" &&
                                                                            !incomingEmail) ||
                                                                        (campaign.field_options &&
                                                                            campaign.field_options.length >
                                                                                0 &&
                                                                            campaign.field_options.find(o => o.data ===
                                                                                "incoming_channel" &&
                                                                                o.table ===
                                                                                    "ticket_activities").visible &&
                                                                            incoming === "phone" &&
                                                                            !incomingPhone) ||
                                                                        (incoming === "social" &&
                                                                            (!incomingSocial ||
                                                                                !incomingProfile)) ||
                                                                        (outgoing === "email" &&
                                                                            !outgoingEmail) ||
                                                                        (campaign.field_options &&
                                                                            campaign.field_options.length >
                                                                                0 &&
                                                                            campaign.field_options.find(o => o.data ===
                                                                                "outgoing_channel" &&
                                                                                o.table ===
                                                                                    "ticket_activities").visible &&
                                                                            outgoing === "phone" &&
                                                                            !outgoingPhone) ||
                                                                        (campaign.field_options &&
                                                                            campaign.field_options.length >
                                                                                0 &&
                                                                            campaign.field_options.find(o => o.data ===
                                                                                "outgoing_channel" &&
                                                                                o.table ===
                                                                                    "ticket_activities").visible &&
                                                                            outgoing === "social" &&
                                                                            (!outgoingSocial ||
                                                                                !outgoingProfile)) ||
                                                                        (campaign.field_options &&
                                                                            campaign.field_options.length >
                                                                                0 &&
                                                                            campaign.field_options.find(o => o.data === "notes" &&
                                                                                o.table ===
                                                                                    "ticket_activities").required &&
                                                                            notes === "") ||
                                                                        (campaign.field_options &&
                                                                            campaign.field_options.length >
                                                                                0 &&
                                                                            campaign.field_options.find(o => o.data ===
                                                                                "incoming_channel" &&
                                                                                o.table ===
                                                                                    "ticket_activities").required &&
                                                                            incoming === "") ||
                                                                        (campaign.field_options &&
                                                                            campaign.field_options.length >
                                                                                0 &&
                                                                            campaign.field_options.find(o => o.data ===
                                                                                "outgoing_channel" &&
                                                                                o.table ===
                                                                                    "ticket_activities").required &&
                                                                            outgoing === ""), onClick: () => updateTicketActivity(), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("form-component.update") }))] })) }))] })))] })) })) })) }))] })) }))), isLoadingCustomFields && !isSaving ? (_jsx(Fragment, { children: _jsx(Box, Object.assign({ sx: {
                        display: "flex",
                        justifyContent: "center",
                        marginY: 3,
                        paddingY: 3,
                    } }, { children: _jsx(CircularProgress, { color: "inherit", size: 30 }) })) })) : (_jsx(Fragment, { children: tenancyCustomFields.length > 0 &&
                    campaign.activity_custom_field_options &&
                    campaign.activity_custom_field_options.length > 0 &&
                    campaign.activity_custom_field_options.find(a => a.visible) && (_jsx(InputCustomFields, { type: "ticket_activities", tenancyCustomFields: tenancyCustomFields, customFields: customFields, setCustomFields: setCustomFields, disabled: false, error: "custom_fields.", errors: errors, isError: isError, setIsError: setIsError, modal: modal, campaign: campaign, disabledCustomFields: disabledCustomFields, setDisabledCustomFields: setDisabledCustomFields })) })), (!campaign.field_options ||
                campaign.field_options.length === 0 ||
                (campaign.field_options.find(o => o.data === "recipient_ids" && o.table === "ticket_activities") &&
                    campaign.field_options.find(o => o.data === "recipient_ids" && o.table === "ticket_activities").visible)) && (_jsx(Box, Object.assign({ sx: {
                    marginBottom: 4,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [!modal && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    position: "sticky",
                                    top: "100px",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(EmailIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("fields.activity-fields.email") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("fields.activity-fields.activity-email") }))] })] })) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, md: modal ? 12 : 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                        padding: 4,
                                    } }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(Autocomplete, { multiple: true, options: usersWithGroups, getOptionLabel: userGroups => {
                                                            var _a;
                                                            return (userGroups.user.first_name
                                                                ? userGroups.user.first_name + " "
                                                                : "") +
                                                                ((_a = userGroups.user.last_name) !== null && _a !== void 0 ? _a : "");
                                                        }, isOptionEqualToValue: (option, value) => option && option.user.id === value.user.id, value: recipientUsersWithGroups, onChange: (event, value) => {
                                                            setRecipientUsersWithGroups(value);
                                                            if (isError &&
                                                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("recipient_ids")))
                                                                setIsError(false);
                                                        }, loading: isUsersLoading, renderOption: (props, userGroups) => (_jsx("li", Object.assign({}, props, { children: _jsxs(Box, Object.assign({ sx: {
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                                            display: "flex",
                                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: (userGroups.user.first_name
                                                                                ? userGroups.user
                                                                                    .first_name + " "
                                                                                : "") +
                                                                                (userGroups.user
                                                                                    .last_name
                                                                                    ? userGroups.user
                                                                                        .last_name +
                                                                                        " "
                                                                                    : "") })) })), _jsx(Box, Object.assign({ sx: {
                                                                            display: "flex",
                                                                            whiteSpace: "pre-wrap",
                                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary" }, { children: userGroups.user.email ||
                                                                                userGroups.user
                                                                                    .username })) }))] }), userGroups.user.id) }))), renderInput: params => (_jsx(TextField, Object.assign({ required: !campaign.field_options ||
                                                                campaign.field_options.length === 0
                                                                ? false
                                                                : campaign.field_options.find(o => o.data ===
                                                                    "recipient_ids" &&
                                                                    o.table ===
                                                                        "ticket_activities").required }, params, { label: t("tickets.activity.recipients"), fullWidth: true, helperText: isError &&
                                                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("recipient_ids")) &&
                                                                errors["recipient_ids"][0], InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isUsersLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps
                                                                            .endAdornment] })) }), error: isError &&
                                                                (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("recipient_ids")) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(Autocomplete, { options: allTemplates, getOptionLabel: knowledge => knowledge.title, isOptionEqualToValue: (option, value) => option.id === value.id, value: selectedTemplate, loading: areTemplatesLoading, onChange: onTemplateChange, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("knowledge.template") }))) }) })) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputText, { label: t("tickets.activity.subject"), required: true, value: emailSubject, setValue: value => setEmailSubject(value) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputEditor, { required: true, value: emailContent, setValue: value => setEmailContent(value) }) }))] })) })) })) }) }))] })) }))), status === "new" && (_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Hidden, Object.assign({ mdDown: true }, { children: _jsx(Grid, { item: true, xs: 12, md: 4 }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsxs(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "flex-end",
                            } }, { children: [ticket.is_draft && (_jsx(Box, Object.assign({ sx: {
                                        marginRight: 2,
                                    } }, { children: _jsx(Button, Object.assign({ color: "inherit", startIcon: _jsx(PrevIcon, {}), onClick: () => navigate(tenancy.link("/tickets/" + ticket.id + "/details")) }, { children: t("form-component.back") })) }))), _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "secondary", loading: isSaving, disabled: isLoadingCustomFields ||
                                        (modal &&
                                            activityType === null &&
                                            (!workedTime ||
                                                compareAsc(workedTime, new Date(1970, 0, 1)) === 0) &&
                                            notes === "" &&
                                            incoming === "" &&
                                            outgoing === "" &&
                                            recipientUsersWithGroups.length === 0 &&
                                            emailSubject === "" &&
                                            emailContent === "") ||
                                        (modal &&
                                            recipientUsersWithGroups.length > 0 &&
                                            (emailSubject === "" || emailContent === "")) ||
                                        (modal &&
                                            emailSubject !== "" &&
                                            (recipientUsersWithGroups.length === 0 ||
                                                emailContent === "")) ||
                                        (modal &&
                                            emailContent !== "" &&
                                            (recipientUsersWithGroups.length === 0 ||
                                                emailSubject === "")) ||
                                        (campaign.field_options &&
                                            campaign.field_options.length > 0 &&
                                            campaign.field_options.find(o => o.data === "ticket_activity_type_id" &&
                                                o.table === "ticket_activities").required &&
                                            activityType === null) ||
                                        (campaign.field_options &&
                                            campaign.field_options.length > 0 &&
                                            campaign.field_options.find(o => o.data === "seconds_active" &&
                                                o.table === "ticket_activities").required &&
                                            (!workedTime ||
                                                compareAsc(workedTime, new Date(1970, 0, 1)) === 0)) ||
                                        (incoming === "email" && !incomingEmail) ||
                                        (campaign.field_options &&
                                            campaign.field_options.length > 0 &&
                                            campaign.field_options.find(o => o.data === "incoming_channel" &&
                                                o.table === "ticket_activities").visible &&
                                            incoming === "phone" &&
                                            !incomingPhone) ||
                                        (incoming === "social" &&
                                            (!incomingSocial || !incomingProfile)) ||
                                        (outgoing === "email" && !outgoingEmail) ||
                                        (campaign.field_options &&
                                            campaign.field_options.length > 0 &&
                                            campaign.field_options.find(o => o.data === "outgoing_channel" &&
                                                o.table === "ticket_activities").visible &&
                                            outgoing === "phone" &&
                                            !outgoingPhone) ||
                                        (campaign.field_options &&
                                            campaign.field_options.length > 0 &&
                                            campaign.field_options.find(o => o.data === "outgoing_channel" &&
                                                o.table === "ticket_activities").visible &&
                                            outgoing === "social" &&
                                            (!outgoingSocial || !outgoingProfile)) ||
                                        (campaign.field_options &&
                                            campaign.field_options.length > 0 &&
                                            campaign.field_options.find(o => o.data === "recipient_ids" &&
                                                o.table === "ticket_activities").required &&
                                            recipientUsersWithGroups.length === 0) ||
                                        (campaign.field_options &&
                                            campaign.field_options.length > 0 &&
                                            campaign.field_options.find(o => o.data === "notes" &&
                                                o.table === "ticket_activities").required &&
                                            notes === "") ||
                                        (campaign.field_options &&
                                            campaign.field_options.length > 0 &&
                                            campaign.field_options.find(o => o.data === "incoming_channel" &&
                                                o.table === "ticket_activities").required &&
                                            incoming === "") ||
                                        (campaign.field_options &&
                                            campaign.field_options.length > 0 &&
                                            campaign.field_options.find(o => o.data === "outgoing_channel" &&
                                                o.table === "ticket_activities").required &&
                                            outgoing === ""), onClick: () => createTicketActivity(), endIcon: ticket.is_draft ? _jsx(NextIcon, {}) : undefined, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: recipientUsersWithGroups.length > 0
                                        ? t("tickets.activity.send-message")
                                        : ticket.is_draft
                                            ? t("tickets.ticket-activities.ticket-activity-form.finish")
                                            : t("tickets.ticket-activities.ticket-activity-form.add-activity") }))] })) }))] }))), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: openDialog }, { children: [_jsx(DialogTitle, { children: t("tickets.ticket-activities.ticket-activity-form.contract-can-be-created") }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ onClick: () => {
                                    prefs.setTicketStartTime(null);
                                    prefs.setTicketPhone(null);
                                    navigate(tenancy.link("/campaigns/" +
                                        (campaign === null || campaign === void 0 ? void 0 : campaign.contract_campaign_id) +
                                        "/contacts/" +
                                        ticket.contact.id +
                                        "/new-ticket"));
                                } }, { children: t("tickets.ticket-activities.ticket-activity-form.create-contract") })), _jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    prefs.setTicketStartTime(null);
                                    prefs.setTicketPhone(null);
                                    navigate(tenancy.link("/campaigns/" + (campaign === null || campaign === void 0 ? void 0 : campaign.id) + "/new-ticket"));
                                } }, { children: t("tickets.ticket-activities.ticket-activity-form.create-ticket") }))] })] }))] }));
}
