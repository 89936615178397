import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material//MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Skeleton from "@mui/material/Skeleton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Hidden from "@mui/material/Hidden";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import InputText from "../../inputs/input-text";
import InputEditor from "../../inputs/input-editor";
import InputAutocomplete from "../../inputs/input-autocomplete";
import InputTextArea from "../../inputs/input-textarea";
import CampaignStepDelete from "./campaign-step-delete";
import CustomFieldsTable from "../../custom-fields/custom-fields-table";
import CustomFieldForm from "../../custom-fields/custom-field-form";
import CustomFieldDelete from "../../custom-fields/custom-field-delete";
import { useAlert } from "../../../../services/alert";
import { useRoles } from "../../../../services/roles";
import { useApiTenant } from "../../../../api/api-tenant";
import { flatGroups, sortGroupsWithLevels, relatedChildGroups, relatedParentGroups, groupInGroups, flatGroupsWithLevels, } from "../../../../helpers/groups";
export default function CampaignStepsForm({ status, step, parentStep, campaign, editCustomField, setEditCustomField, setRefreshSteps, }) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { t } = useTranslation();
    const alert = useAlert();
    const roles = useRoles();
    const api = useApiTenant();
    const [name, setName] = useState((_a = step === null || step === void 0 ? void 0 : step.name) !== null && _a !== void 0 ? _a : "");
    const [type, setType] = useState((_b = step === null || step === void 0 ? void 0 : step.type) !== null && _b !== void 0 ? _b : "form");
    const [content, setContent] = useState((_c = step === null || step === void 0 ? void 0 : step.content) !== null && _c !== void 0 ? _c : "");
    const [caption, setCaption] = useState((_d = step === null || step === void 0 ? void 0 : step.caption) !== null && _d !== void 0 ? _d : "");
    const [image, setImage] = useState((_e = step === null || step === void 0 ? void 0 : step.url) !== null && _e !== void 0 ? _e : "");
    const [chosenChoice, setChosenChoice] = useState((_f = step === null || step === void 0 ? void 0 : step.shared_campaign_step_id) !== null && _f !== void 0 ? _f : "");
    const [selectedGroups, setSelectedGroups] = useState((_g = step === null || step === void 0 ? void 0 : step.groups) !== null && _g !== void 0 ? _g : []);
    const [groups, setGroups] = useState([]);
    const [sortedGroups, setSortedGroups] = useState([]);
    const [relatedGroups, setRelatedGroups] = useState([]);
    const [newImage, setNewImage] = useState(undefined);
    const [addNewField, setAddNewField] = useState(false);
    const [customField, setCustomField] = useState(null);
    const [sharedChoices, setSharedChoices] = useState([]);
    const [choices, setChoices] = useState([]);
    const [isTypeChanged, setIsTypeChanged] = useState(false);
    const [allKnowledge, setAllKnowledge] = useState([]);
    const [allSelectedKnowledge, setAllSelectedKnowledge] = useState((_h = step === null || step === void 0 ? void 0 : step.knowledge) !== null && _h !== void 0 ? _h : []);
    const [notEditable, setNotEditable] = useState(!!(step === null || step === void 0 ? void 0 : step.not_editable));
    const [sharedNotEditable, setSharedNotEditable] = useState(false);
    const [filterable, setFilterable] = useState(!!(step === null || step === void 0 ? void 0 : step.filterable));
    const [sharedFilterable, setSharedFilterable] = useState(false);
    const [hasMetrics, setHasMetrics] = useState(!!(step === null || step === void 0 ? void 0 : step.has_metrics));
    const [sharedHasMetrics, setSharedHasMetrics] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState(null);
    const [emailError] = useState(false);
    const createStep = () => {
        setIsSaving(true);
        setIsError(false);
        api.CREATE_campaignStep(campaign.id, name, type, step === null || step === void 0 ? void 0 : step.id, newImage, content ? content : undefined, caption ? caption : undefined, selectedGroups && selectedGroups.length > 0
            ? selectedGroups.map(group => group.id)
            : undefined, chosenChoice !== "" ? chosenChoice : undefined, allSelectedKnowledge && allSelectedKnowledge.length > 0
            ? allSelectedKnowledge.map(knowledge => knowledge.id)
            : undefined, filterable, hasMetrics, notEditable)
            .then(() => {
            setIsSaving(false);
            setRefreshSteps(true);
            alert.show(t("campaigns.campaign-steps.campaign-steps-form.step-created"), "success");
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            alert.show(t("campaigns.campaign-steps.campaign-steps-form.creating-error"), "error");
        });
    };
    const updateStep = () => {
        if (!step)
            return;
        setIsSaving(true);
        setIsError(false);
        api.UPDATE_campaignStep(campaign.id, step.id, name, type, newImage !== undefined ? newImage : undefined, content, caption, selectedGroups && selectedGroups.length > 0
            ? selectedGroups.map(group => group.id)
            : undefined, chosenChoice !== "" ? chosenChoice : undefined, allSelectedKnowledge && allSelectedKnowledge.length > 0
            ? allSelectedKnowledge.map(knowledge => knowledge.id)
            : undefined, filterable, hasMetrics, notEditable)
            .then(() => {
            setIsSaving(false);
            setRefreshSteps(true);
            alert.show(t("campaigns.campaign-steps.campaign-steps-form.step-updated"), "success");
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            if (error.response.status === 422) {
                alert.show(error.response.data.message, "warning");
            }
            else {
                alert.show(t("campaigns.campaign-steps.campaign-steps-form.updating-error"), "error");
            }
        });
    };
    useEffect(() => {
        setChosenChoice("");
        setNotEditable(false);
        setSharedNotEditable(false);
        setFilterable(false);
        setSharedFilterable(false);
        setHasMetrics(false);
        setSharedHasMetrics(false);
        if (type === "choice") {
            api.GET_campaignsChoiceFields()
                .then(response => {
                let choices = [];
                choices.push({
                    value: "",
                    label: t("campaigns.campaign-steps.campaign-steps-form.not-shared"),
                });
                setSharedChoices(response.data.shared_choices);
                if (response.data.shared_choices && response.data.shared_choices.length > 0) {
                    response.data.shared_choices.map(choice => choices.push({
                        value: choice.id.toString(),
                        label: choice.name,
                    }));
                }
                setChoices(choices);
                setIsLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoading(false);
                }
            });
        }
    }, [type]);
    useEffect(() => {
        if (campaign.groups) {
            let campaignGroups = flatGroups(campaign.groups, 0, []);
            setGroups(campaignGroups);
            let groupsLevels = flatGroupsWithLevels(campaign.groups, 0, []);
            campaignGroups = sortGroupsWithLevels(groupsLevels, 0, [], 1);
            setSortedGroups(campaignGroups);
        }
        api.GET_knowledgeAll(10000, undefined, "id", "asc", undefined, 0, 0)
            .then(response => {
            setAllKnowledge(response.data.knowledge);
        })
            .catch(error => {
            console.error(error);
        })
            .finally(() => {
            setIsLoading(false);
        });
    }, []);
    useEffect(() => {
        var _a, _b, _c;
        if (chosenChoice !== "" && sharedChoices.length > 0) {
            let isNotEditable = (_a = sharedChoices.find(choice => choice.id.toString() == chosenChoice)) === null || _a === void 0 ? void 0 : _a.not_editable;
            let isFilterable = (_b = sharedChoices.find(choice => choice.id.toString() == chosenChoice)) === null || _b === void 0 ? void 0 : _b.filterable;
            let metrics = (_c = sharedChoices.find(choice => choice.id.toString() == chosenChoice)) === null || _c === void 0 ? void 0 : _c.has_metrics;
            setSharedNotEditable(isNotEditable);
            setNotEditable(false);
            setSharedFilterable(isFilterable);
            setFilterable(false);
            setSharedHasMetrics(metrics);
            setHasMetrics(false);
        }
    }, [chosenChoice, sharedChoices]);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        if (status === "new") {
            setName("");
            setType("form");
            setCaption("");
            setImage("");
            setAllSelectedKnowledge([]);
            setContent("");
            setChosenChoice("");
            setSelectedGroups([]);
            setRelatedGroups([]);
            setNotEditable(false);
            setFilterable(false);
            setHasMetrics(false);
            setErrors(null);
        }
        else {
            setName((_a = step === null || step === void 0 ? void 0 : step.name) !== null && _a !== void 0 ? _a : "");
            setType((_b = step === null || step === void 0 ? void 0 : step.type) !== null && _b !== void 0 ? _b : "form");
            setCaption((_c = step === null || step === void 0 ? void 0 : step.caption) !== null && _c !== void 0 ? _c : "");
            setImage((_d = step === null || step === void 0 ? void 0 : step.url) !== null && _d !== void 0 ? _d : "");
            setAllSelectedKnowledge((_e = step === null || step === void 0 ? void 0 : step.knowledge) !== null && _e !== void 0 ? _e : []);
            setContent((_f = step === null || step === void 0 ? void 0 : step.content) !== null && _f !== void 0 ? _f : "");
            setChosenChoice((_g = step === null || step === void 0 ? void 0 : step.shared_campaign_step_id) !== null && _g !== void 0 ? _g : "");
            if (chosenChoice !== "" && !(step === null || step === void 0 ? void 0 : step.not_editable) && sharedChoices.length > 0) {
                let isNotEditable = (_h = sharedChoices.find(choice => choice.id.toString() == chosenChoice)) === null || _h === void 0 ? void 0 : _h.not_editable;
                setSharedNotEditable(isNotEditable);
            }
            if (chosenChoice !== "" && !(step === null || step === void 0 ? void 0 : step.filterable) && sharedChoices.length > 0) {
                let isFilterable = (_j = sharedChoices.find(choice => choice.id.toString() == chosenChoice)) === null || _j === void 0 ? void 0 : _j.filterable;
                setSharedFilterable(isFilterable);
            }
            if (!(chosenChoice !== "" && !(step === null || step === void 0 ? void 0 : step.not_editable))) {
                setNotEditable(step === null || step === void 0 ? void 0 : step.not_editable);
            }
            if (!(chosenChoice !== "" && !(step === null || step === void 0 ? void 0 : step.filterable))) {
                setFilterable(step === null || step === void 0 ? void 0 : step.filterable);
            }
            if (chosenChoice !== "" && !(step === null || step === void 0 ? void 0 : step.has_metrics) && sharedChoices.length > 0) {
                let metrics = (_k = sharedChoices.find(choice => choice.id.toString() == chosenChoice)) === null || _k === void 0 ? void 0 : _k.has_metrics;
                setSharedHasMetrics(metrics);
            }
            if (!(chosenChoice !== "" && !(step === null || step === void 0 ? void 0 : step.has_metrics))) {
                setHasMetrics(step === null || step === void 0 ? void 0 : step.has_metrics);
            }
            setSelectedGroups((_l = step === null || step === void 0 ? void 0 : step.groups) !== null && _l !== void 0 ? _l : []);
        }
        setAddNewField(false);
        setCustomField(null);
    }, [status, step]);
    useEffect(() => {
        if (!editCustomField)
            setCustomField(null);
    }, [editCustomField]);
    return (_jsxs(Fragment, { children: [!editCustomField && (roles.isAdmin || roles.isSuperAdmin) && (_jsx(Box, Object.assign({ component: "form", sx: {
                    marginBottom: 4,
                } }, { children: _jsx(Paper, { children: _jsxs(Box, Object.assign({ sx: {
                            padding: 4,
                        } }, { children: [status === "new" && (_jsx(Box, Object.assign({ sx: {
                                    paddingBottom: 4,
                                } }, { children: _jsxs(Typography, Object.assign({ variant: "h6" }, { children: [_jsx(Box, Object.assign({ component: "span", sx: {
                                                paddingRight: 0.5,
                                            } }, { children: step
                                                ? t("campaigns.campaign-steps.campaign-steps-form.new-step-at")
                                                : t("campaigns.campaign-steps.campaign-steps-form.new-step") })), _jsx(Box, Object.assign({ component: "strong" }, { children: step ? step === null || step === void 0 ? void 0 : step.name : "" }))] })) }))), status === "edit" && (_jsx(Box, Object.assign({ sx: {
                                    paddingBottom: 4,
                                } }, { children: _jsx(Typography, Object.assign({ variant: "h6" }, { children: t("campaigns.campaign-steps.campaign-steps-form.edit-step") })) }))), _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputText, { label: t("campaigns.campaign-steps.campaign-step-attributes.name") + " *", required: true, value: name, setValue: setName, info: t("campaigns.campaign-steps.campaign-steps-form.name-message"), pattern: null, error: "name", errors: errors, isError: isError, setIsError: setIsError }) })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: t("campaigns.campaign-steps.campaign-step-attributes.type") + " *" }), _jsxs(Select, Object.assign({ label: t("campaigns.campaign-steps.campaign-step-attributes.type") + " *", required: true, value: type, onChange: event => {
                                                            setType(event.target.value);
                                                            setIsTypeChanged(true);
                                                            if (isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("type")))
                                                                setIsError(false);
                                                        } }, { children: [_jsx(MenuItem, Object.assign({ value: "form" }, { children: t("campaigns.campaign-steps.campaign-step-attributes.types.form") })), _jsx(MenuItem, Object.assign({ value: "content" }, { children: t("campaigns.campaign-steps.campaign-step-attributes.types.content") })), _jsx(MenuItem, Object.assign({ value: "choice" }, { children: t("campaigns.campaign-steps.campaign-step-attributes.types.choice") })), campaign.groups && campaign.groups.length > 0 && (_jsx(MenuItem, Object.assign({ value: "group" }, { children: t("campaigns.campaign-steps.campaign-step-attributes.types.group") })))] }))] })), campaign.type === "survey" && type === "group" && (_jsx(FormHelperText, { children: t("campaigns.campaign-steps.type-helper-text") }))] })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: step && parentStep && parentStep.type === "choice" ? 6 : 12 }, { children: _jsx(InputTextArea, { label: t("campaigns.campaign-steps.campaign-step-attributes.caption"), value: caption ? caption : "", required: false, setValue: setCaption, info: t("campaigns.campaign-steps.campaign-steps-form.caption-message"), pattern: null, rows: 5, error: "caption", errors: errors, isError: isError, setIsError: setIsError }) })), step && parentStep && parentStep.type === "choice" && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(Badge, Object.assign({ sx: {
                                                width: "100%",
                                                color: "rgb(244, 67, 54)",
                                            }, invisible: image === "" &&
                                                (newImage === undefined || newImage === null), badgeContent: _jsx(Box, Object.assign({ sx: {
                                                    paddingBottom: 0.5,
                                                } }, { children: _jsx(Tooltip
                                                // @ts-ignore
                                                , Object.assign({ 
                                                    // @ts-ignore
                                                    title: t("campaigns.campaign-steps.campaign-steps-form.remove-this-image"), placement: "top", arrow: true }, { children: _jsx(IconButton, Object.assign({ style: {
                                                            color: "rgb(244, 67, 54)",
                                                        }, onClick: () => {
                                                            if (image || newImage) {
                                                                setImage("");
                                                                setNewImage(null);
                                                            }
                                                        }, size: "large" }, { children: _jsx(RemoveCircleIcon, {}) })) })) })) }, { children: _jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(Dropzone, Object.assign({ maxFiles: 1, maxSize: 50000000, onDrop: files => {
                                                            setNewImage(files[0]);
                                                        } }, { children: ({ getRootProps, getInputProps }) => (_jsx(Card, Object.assign({ variant: "outlined" }, getRootProps(), { children: _jsx(CardActionArea, { children: _jsxs(Box, Object.assign({ sx: {
                                                                        height: "145px",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        backgroundColor: "#fff",
                                                                    } }, { children: [newImage ? (_jsx("img", { src: URL.createObjectURL(newImage), width: "200", height: "100", alt: "Logo", style: {
                                                                                objectFit: "contain",
                                                                            } })) : image ? (_jsx("img", { src: image, width: "200", height: "100", alt: "Logo", style: {
                                                                                objectFit: "contain",
                                                                            } })) : (_jsx(Skeleton, { width: "200px", height: "150px" })), _jsx("input", Object.assign({}, getInputProps({
                                                                            accept: "image/*,image/svg+xml",
                                                                        })))] })) }) }))) })), _jsx(FormHelperText, { children: t("campaigns.campaign-steps.campaign-steps-form.add-image") })] })) })) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: _jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(Autocomplete, { multiple: true, options: allKnowledge, getOptionLabel: knowledge => knowledge.title, isOptionEqualToValue: (option, value) => option.id === value.id, value: allSelectedKnowledge, loading: isLoading, disableCloseOnSelect: true, onChange: (event, value) => {
                                                    setAllSelectedKnowledge(value);
                                                }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("knowledge.knowledge") }))) }) })) })), (type === "group" || type === "choice") && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, {}) }))), type === "content" && (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, {}) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputEditor, { label: t("campaigns.campaign-steps.campaign-step-attributes.content") + " *", required: true, value: content, setValue: event => setContent(event), error: "content", errors: errors, isError: isError, setIsError: setIsError }) }))] })), type === "choice" && (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputAutocomplete, { disabled: filterable === true, options: choices, value: chosenChoice ? chosenChoice.toString() : "", label: t("campaigns.campaign-steps.campaign-step-attributes.shared-field"), disableClearable: true, setValue: value => {
                                                        setChosenChoice(value !== null ? value.value.toString() : "");
                                                        setNotEditable(false);
                                                        setFilterable(false);
                                                        setHasMetrics(false);
                                                    } }) })), _jsx(Hidden, Object.assign({ lgDown: true }, { children: _jsx(Grid, { item: true }) })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsx(FormControl, { children: _jsx(FormControlLabel, { control: _jsx(Switch, { color: "secondary", disabled: chosenChoice !== "", checked: chosenChoice !== "" &&
                                                                    sharedChoices.length > 0
                                                                    ? sharedFilterable
                                                                    : filterable, onChange: () => {
                                                                    if (chosenChoice !== "") {
                                                                        setSharedFilterable(!sharedFilterable);
                                                                    }
                                                                    else {
                                                                        setFilterable(!filterable);
                                                                    }
                                                                } }), 
                                                            // @ts-ignore
                                                            label: t("campaigns.campaign-steps.campaign-step-attributes.filterable") }) }), _jsx(FormHelperText, { children: chosenChoice === ""
                                                            ? t("campaigns.campaign-steps.campaign-steps-form.filterable-message")
                                                            : t("campaigns.campaign-steps.campaign-steps-form.value-copied") })] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsx(FormControl, { children: _jsx(FormControlLabel, { control: _jsx(Switch, { color: "secondary", disabled: chosenChoice !== "", checked: chosenChoice !== "" &&
                                                                    sharedChoices.length > 0
                                                                    ? sharedHasMetrics
                                                                    : hasMetrics, onChange: () => {
                                                                    if (chosenChoice !== "") {
                                                                        setSharedHasMetrics(!sharedHasMetrics);
                                                                    }
                                                                    else {
                                                                        setHasMetrics(!hasMetrics);
                                                                    }
                                                                } }), 
                                                            // @ts-ignore
                                                            label: t("campaigns.campaign-steps.campaign-step-attributes.reportable") }) }), _jsx(FormHelperText, { children: chosenChoice === ""
                                                            ? t("campaigns.campaign-steps.campaign-steps-form.reportable-message")
                                                            : t("campaigns.campaign-steps.campaign-steps-form.value-copied") })] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsx(FormControl, { children: _jsx(FormControlLabel, { control: _jsx(Switch, { disabled: chosenChoice !== "", color: "secondary", checked: chosenChoice !== "" &&
                                                                    sharedChoices.length > 0
                                                                    ? sharedNotEditable
                                                                    : notEditable, onChange: () => {
                                                                    if (chosenChoice !== "") {
                                                                        setSharedNotEditable(!sharedNotEditable);
                                                                    }
                                                                    else {
                                                                        setNotEditable(!notEditable);
                                                                    }
                                                                } }), 
                                                            // @ts-ignore
                                                            label: t("fields.field-attributes.not-editable") }) }), _jsx(FormHelperText, { children: chosenChoice === ""
                                                            ? t("fields.field-form.not-editable-message")
                                                            : t("campaigns.campaign-steps.campaign-steps-form.value-copied") })] }))] })), type === "group" && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: [_jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(Autocomplete, { multiple: true, options: sortedGroups.map(sort => sort.group), getOptionLabel: group => {
                                                        var _a;
                                                        return sortedGroups.find(sort => sort.group.id === group.id)
                                                            ? ((_a = sortedGroups.find(sort => sort.group.id === group.id)) === null || _a === void 0 ? void 0 : _a.id) +
                                                                ". " +
                                                                group.name
                                                            : group.name;
                                                    }, isOptionEqualToValue: (option, value) => option.id === value.id, getOptionDisabled: option => !!relatedGroups.find(group => group.id === option.id), value: selectedGroups, loading: isLoading, disableCloseOnSelect: true, onChange: (event, value) => {
                                                        let disabledGroups = [];
                                                        value.map(group => {
                                                            disabledGroups = relatedChildGroups(group, disabledGroups);
                                                            disabledGroups = relatedParentGroups(group, groups, disabledGroups);
                                                        });
                                                        setRelatedGroups(disabledGroups);
                                                        setSelectedGroups(value);
                                                    }, onOpen: () => {
                                                        let disabledGroups = [];
                                                        selectedGroups &&
                                                            selectedGroups.map(group => {
                                                                let chosen = groupInGroups(group, groups);
                                                                if (chosen) {
                                                                    disabledGroups = relatedChildGroups(chosen, disabledGroups);
                                                                    disabledGroups =
                                                                        relatedParentGroups(chosen, groups, disabledGroups);
                                                                }
                                                            });
                                                        setRelatedGroups(disabledGroups);
                                                    }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { required: true, label: t("groups.groups") }))) }) })), status === "edit" && (_jsx(FormHelperText, { children: t("campaigns.campaign-steps.campaign-steps-form.groups-message") }))] }))), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Box, Object.assign({ sx: {
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            } }, { children: _jsx(LoadingButton, Object.assign({ type: "submit", variant: "contained", color: "secondary", loading: isSaving, disabled: !name ||
                                                    (type === "group" && selectedGroups.length < 1) ||
                                                    emailError, onClick: () => {
                                                    status === "new" ? createStep() : updateStep();
                                                }, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: status === "new"
                                                    ? t("form-component.create")
                                                    : t("form-component.update") })) })) }))] }))] })) }) }))), type == "form" && status !== "new" && !editCustomField && !isTypeChanged && (_jsx(CustomFieldsTable, { step: step, type: "campaign_steps", setAddNewField: setAddNewField, setCustomField: setCustomField, setEditCustomField: setEditCustomField })), addNewField && editCustomField && !customField && (_jsx(CustomFieldForm, { status: "new", type: "campaign_steps", setAddNewField: setAddNewField, setEditCustomField: setEditCustomField, step: step, campaign: campaign })), customField && editCustomField && (_jsxs(Fragment, { children: [_jsx(CustomFieldForm, { status: "edit", type: "campaign_steps", customField: customField, setCustomField: setCustomField, setEditCustomField: setEditCustomField, step: step, campaign: campaign }), _jsx(CustomFieldDelete, { type: "campaign_steps", step: step, customField: customField, setCustomField: setCustomField, setEditCustomField: setEditCustomField })] })), status === "edit" && step && !editCustomField && (_jsx(Fragment, { children: (roles.isAdmin || roles.isSuperAdmin) && (_jsx(CampaignStepDelete, { step: step, setRefreshSteps: setRefreshSteps, campaign: campaign })) }))] }));
}
