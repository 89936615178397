var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { createSingletonHook } from "../helpers/singleton";
import { useApiAuth } from "../api/api-auth";
import { useApiCentral } from "../api/api-central";
import { usePrefs } from "./prefs";
import analytics from "../helpers/analytics";
export const [useAuth, AuthProvider] = createSingletonHook(() => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const prefs = usePrefs();
    const apiAuth = useApiAuth();
    const apiCentral = useApiCentral();
    const [user, setUser] = useState(null);
    const [isPublic, setIsPublic] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isLogginInOut, setIsLogginInOut] = useState(false);
    const [isSsoLogginInOut, setIsSsoLogginInOut] = useState(false);
    const [isError, setIsError] = useState(false);
    const [is2faNeeded, setIs2faNeeded] = useState(false);
    const [is2faEnforced, setIs2faEnforced] = useState(false);
    useEffect(() => {
        currentUser(false);
    }, []);
    const notificationPermission = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!("Notification" in window))
            return;
        return yield Notification.requestPermission();
    });
    const logIn = (email, password, subTenantId) => {
        setIsLogginInOut(true);
        apiAuth
            .POST_logIn(email, password)
            .then(response => {
            setIsError(false);
            if (response.data.two_factor) {
                setIs2faNeeded(true);
                return;
            }
            apiAuth
                .GET_centralToken()
                .then(response => {
                const centralToken = response.data.token;
                prefs.setCentralToken(centralToken);
                if (process.env.ENFORCE_2FA === "true") {
                    setIs2faEnforced(true);
                    return;
                }
                if (subTenantId) {
                    apiCentral
                        .GET_tenantToken(subTenantId)
                        .then(response => {
                        prefs.setTenantToken(response.data.token);
                        currentUser(true, centralToken);
                    })
                        .catch(error => {
                        console.error(error.response);
                    });
                    return;
                }
                currentUser(true, centralToken);
            })
                .catch(error => {
                console.error(error.response);
            });
        })
            .catch(error => {
            setIsError(true);
            console.error(error);
        })
            .finally(() => {
            setIsLogginInOut(false);
        });
    };
    const logOut = () => {
        setIsLogginInOut(true);
        apiAuth
            .POST_logOut()
            .then(() => {
            analytics.sendEvent({
                category: "User",
                action: "Logout",
            }, user);
            setUser(null);
            setIsPublic(true);
            setIs2faNeeded(false);
            setIs2faEnforced(false);
            prefs.setCentralToken(null);
            prefs.setTenantToken(null);
            Sentry.setUser(null);
            navigate("/login");
        })
            .catch(error => {
            setIsError(true);
            console.error(error);
        })
            .finally(() => {
            setIsLogginInOut(false);
        });
    };
    const ssoRedirect = () => {
        setIsSsoLogginInOut(true);
        apiAuth
            .GET_ssoRedirect("microsoft")
            .then(response => {
            window.location.href = response.data.redirectUrl;
        })
            .catch(error => {
            setIsError(true);
            console.error(error);
        })
            .finally(() => {
            setIsSsoLogginInOut(false);
        });
    };
    const ssoLogin = (code, session_state, subTenantId) => {
        setIsSsoLogginInOut(true);
        apiAuth
            .POST_ssoLogIn("microsoft", code, session_state)
            .then(() => {
            apiAuth
                .GET_centralToken()
                .then(response => {
                const centralToken = response.data.token;
                prefs.setCentralToken(centralToken);
                if (subTenantId) {
                    apiCentral
                        .GET_tenantToken(subTenantId)
                        .then(response => {
                        prefs.setTenantToken(response.data.token);
                        currentUser(true, centralToken);
                    })
                        .catch(error => {
                        setIsError(true);
                        console.error(error);
                    });
                    return;
                }
                currentUser(true, centralToken);
            })
                .catch(error => {
                setIsError(true);
                console.error(error);
            });
        })
            .catch(error => {
            setIsError(true);
            console.error(error);
        })
            .finally(() => {
            setIsSsoLogginInOut(false);
        });
    };
    const currentUser = (login, token) => {
        if (window.location.pathname.startsWith("/survey") ||
            window.location.pathname.startsWith("/anketa") ||
            window.location.pathname.startsWith("/activity") ||
            window.location.pathname.startsWith("/aktivnost")) {
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        apiCentral
            .GET_currentUser(token)
            .then(response => {
            const user = response.data.central_user;
            setUser(user);
            if (process.env.ENFORCE_2FA === "true" && !user["2fa"] && !user.is_sso) {
                logOut();
                return;
            }
            i18n.changeLanguage(user.lang).then(() => {
                prefs.setLang(user.lang);
            });
            prefs.setTheme(user.dark_mode ? "dark" : "light");
            notificationPermission().then(permission => {
                if (permission) {
                    prefs.setNotificationPermission(permission);
                }
            });
            setIsPublic(false);
            if (login) {
                analytics.sendEvent({
                    category: "User",
                    action: "Login",
                }, user);
            }
            Sentry.setUser({ email: user.email, username: user.username });
        })
            .catch(() => {
            setUser(null);
            Sentry.setUser(null);
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
    return {
        user,
        isPublic,
        isLoading,
        isLogginInOut,
        isSsoLogginInOut,
        isError,
        is2faNeeded,
        is2faEnforced,
        logIn,
        logOut,
        ssoRedirect,
        ssoLogin,
        currentUser,
        setIsError,
    };
});
